
import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { 
	Divider,Popconfirm,
	Typography,Space,Button ,message,
	Tooltip,Collapse,Avatar,
	Dropdown
} from 'antd';
import { TextArea} from 'antd-mobile'
import {env_serverAddress} from './lib_env'
import Typewriter from 'typewriter-effect';

import { 
	FileAddOutlined,
	RedoOutlined,
	FileOutlined,
	EditOutlined,
	SendOutlined,
	UserOutlined,
	SmileOutlined,
	DownOutlined,
	LoadingOutlined,
	CodepenOutlined,
	CaretRightOutlined,
	SettingOutlined
} from '@ant-design/icons';

import logo_dplanner_small from "./static/neuroai_logo.png"
import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'
import gpticon from "./static/gpticon.png"
import ReactMarkdown from 'react-markdown';

window.global = {
	// your global variables here
	input_text_chat:"",

}
const { Panel } = Collapse;
const { Title, Paragraph, Text, Link } = Typography;




function ifPressCommandAndEnter(event, callback) {
	const isCommandKey = navigator.userAgent.indexOf('Mac') !== -1 ? event.metaKey : event.ctrlKey;
	const isEnterKey = event.keyCode === 13;
	if (isCommandKey && isEnterKey) {
	  event.preventDefault();
	  callback();
	}
	
}




class Page_talk2AI extends Component {

	constructor(props){
        super(props);
        this.Ref_TextInput = React.createRef();
		this.consoleEndRef = React.createRef();
	}



	//pinState
	state={
		chatHistory : [
			{
				role:"system",
				content:this.props.report.content,
				time:11111111
			},
			{
				role:"assistant",
				content:"他に聞きたいことがございましたら、喜んで回答いたします！",
				time:11111111
			},
		],
		ifUploading : false,
		mode_gpt : "normal",
		resetFlag : false,
		ifShowPromptTips : true,
		ifClearingChatHistory : false
		
	}

	clearChatHistory = async() =>{

		this.setState({ifClearingChatHistory:true})
		var url = env_serverAddress+"neuroai/clearchathistory_report"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					// chatHistory:chatHistory,
					password:this.props.password,
					type:this.props.type,
					filename:this.props.item.filename
					
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			// console.log(result)
			// this.setState({ifUploading:false})
			console.log(result)
			
			if(result != 0){
				this.fetchChatHistory()
				// if(result.returnData != 0){
				// 	this.setState({chatHistory:result.returnData})
				// 	// setTimeout(()=>{this.scrollToBottom()},10)
				// }
				this.setState({
					chatHistory : [
						{
							role:"system",
							content:this.props.report.content,
							// time:11111111
						},
						{
							role:"assistant",
							content:"他に聞きたいことがございましたら、喜んで回答いたします！",
							// time:11111111
						},
					],
				})
				
			}
		this.setState({ifClearingChatHistory:false})


		
	}

	fetchChatHistory = async() =>{

		var url = env_serverAddress+"neuroai/fetchchathistory_report"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					// chatHistory:chatHistory,
					password:this.props.password,
					type:this.props.type,
					filename:this.props.item.filename
					
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			// console.log(result)
			// this.setState({ifUploading:false})
			console.log(result)
			
			if(result != 0){
				if(result.returnData != 0){
					this.setState({chatHistory:result.returnData})
					// setTimeout(()=>{this.scrollToBottom()},10)
				}
				
			}

	}


	handleKeyDown = (event) => {
		ifPressCommandAndEnter(event, () => {
		// console.log('Command + Enter pressed!')
		this.talk2AI()
	
		})
	}

	

	componentDidMount(){
		// console.clear()
		document.addEventListener('keydown', this.handleKeyDown)
		// setTimeout(()=>{this.scrollToBottom()},1)
		console.log("componentDidMount")

		this.fetchChatHistory()
		
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}


	
	onChange_textInput =(input_text)=>{
	
		window.global.input_text_chat = input_text
	}
	scrollToBottom = () => {
		this.consoleEndRef.current.scrollIntoView({ behavior: "smooth" });
	  };

	// pinShortCut
	shortCut_talk2AI_1 = (prompt) =>{
		window.global.input_text_chat = prompt
		this.talk2AI()
	}

	//pinTalk
	talk2AI =async()=>{

		var item = this.props.item
		console.log(item)
		
		// 启动触发重置
		this.setState({ ifShowPromptTips: false });

		var content = window.global.input_text_chat	
		if(content == ""){
			message.error('Please input the conversation content first 😥');
		}
		else{
			

			this.Ref_TextInput.current.clear()

			
			var chatHistory = this.state.chatHistory
			
			chatHistory.push({
				// index:history.length+1,
				role:"user",
				// time:Math.floor(Date.now() / 1000),
				content:content,
				
			})

			// chatHistory.push({
			// 	// index:history.length+1,
			// 	role:"assistant",
			// 	// time:Math.floor(Date.now() / 1000),
			// 	content:"chatGPT is thinking..."
				
			// })

			this.setState({chatHistory:chatHistory})
			setTimeout(()=>{this.scrollToBottom()},10)
			this.setState({ifUploading:true})

			var url = env_serverAddress+"neuroai/requiregpt_report"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					chatHistory:chatHistory,
					password:this.props.password,
					type:this.props.type,
					filename:item.filename
					

				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			// console.log(result)
			this.setState({ifUploading:false})
			if(result != 0){
				this.setState({chatHistory:result.returnData})
				setTimeout(()=>{this.scrollToBottom()},10)
			}
			
		}	
	} 

	

	// pinRender
	
	
	
	render() {

		
		

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		//var height_memberBar = this.myRef_member_div.current.clientHeight

		//console.log(this.state.list_robots)

		var width_here = this.props.width - 405

		// if(width-56 < width_here){
		// 	width_here = width - 56
		// }


		var list_file_here = []
		var list_file_gpt_here = []

		for(var i in this.state.list_file){
			var item = this.state.list_file[i]
			if(item.ifByGpt == 1){
				list_file_gpt_here.push(item)
			}else{
				list_file_here.push(item)
			}
		}
		var isTxt = true

		var ifUploading = this.state.ifUploading
		var ifClearingChatHistory = this.state.ifClearingChatHistory

		var chatHistory = JSON.parse(JSON.stringify(this.state.chatHistory))
		if(ifUploading){
			chatHistory.push({
				role:"assistant",
				content:"D-Assistant is thinking...",
				tag:"WAITTING_FOR_GPT"
			})
		}
		

		// pinReturn
		return(
		
			

			<div style={{
				//backgroundColor:"#ff00f0",
				// height:height*0.8+20,
				width : width_here,
				overflowY:"hidden",
				overflowX:"hidden"
				}}>
				

				<div 
					
					style={{
					//width:width,
					height:"98%",	
					display: "inline-block",
					overflowX:"hidden",
					overflowY:"hidden",
					}}>

					<div 
						style={{
						overflowY:"hidden",
						// backgroundColor:"#d8d8d8",
						height:"100%",}}
						>
						
					
						<div style={{
							overflowX:"hidden",
							width:width_here-50,
							height:"100%",
							// backgroundColor:"#ff00ff",
							position:"relative",
							marginLeft:0
							}}>


							{/* pin1 */}
							<div 
								style={{
								height:"87%",
								overflowY:"scroll",
								// backgroundColor:"#ff0000"
								}}>

								{chatHistory.map((item,index)=>{

									// var ifLastOne = index === chatHistory.length - 1;

									var ifLastOne = (index == chatHistory.length-1)
									// console.log(index)
									var ifIsGpt = false
									var role = "User"
									if(item.role == "assistant"){
										ifIsGpt = true
										role = "D-Assistant"

									}

									var isWaitting = false
									if(item.tag == "WAITTING_FOR_GPT"){
										isWaitting = true
									}
									if(item.role != "system"){

										//过滤掉system级的对话
										return(
											<div >
												
												<div style={{height:1}}/>
												<div >
													<Space>
														<div>
	
															<div style={{float:"left"}}>
																{ifIsGpt ?(
																	<div>
																		<div style={{
																			borderRadius:20,
																			overflow:"hidden",
																			backgroundColor:"#3875F6",
																			width:40,height:40,
																			color:"#ffffff",
																			fontSize:30,

																			// padding:5
																			}}>
																				<CodepenOutlined style={{marginLeft:5,marginTop:5}}/>
																				{/* {ifLastOne && isWaitting ?(
																					<LoadingOutlined style={{marginLeft:5,marginTop:5}}/>
																				):(
																					<CodepenOutlined style={{marginLeft:5,marginTop:5}}/>
																				)} */}
																				
																				{/* {ifUploading ?(<LoadingOutlined style={{marginLeft:5,marginTop:5}}/>):(<CodepenOutlined style={{marginLeft:5,marginTop:5}}/>)} */}
																			{/* <ImageComponent src = {gpticon} width = {40} height = {40} /> */}
																		</div>
																
																	</div>
																):(
																	<div>
																		<Avatar
																			style={{
																			backgroundColor: item.iconColor,
																			width:40,height:40,paddingTop:8,
																			}}
																			icon={<UserOutlined style={{fontSize:23}}/>}
																			/>
																		{/* <div style={{width:40,height:40,borderRadius:20,backgroundColor:item.iconColor}}>
																			
																		</div> */}
																	</div>
																)}
															</div>
	
															
															
															<div style={{
																width:width_here-130,
																borderRadius:"8px",
																// backgroundColor:"#ffff00",
																height:"auto",
																paddingTop:"1px",
																// paddingBottom:"5px",
																paddingLeft:"15px",
																paddingRight:"15px",
																marginBottom:-10,
																//fontWeight:"bold"
																fontSize:"14px",
																float:"left"
																}}>
																<div style={{hegiht:10,marginBottom:-13,fontWeight:"bolder"}}>
																	
																	<Space>
																	<div>{role}</div>
																	{ifIsGpt ?(
																		<div style={{
																			width:25,height:13,borderRadius:"4px",
																			backgroundColor:"#f1f1f1",
																			paddingLeft:2,paddingTop:1,
																			color:"#a1a1a1",fontSize:4,fontWeight:"normal"}}
																			>
																			AI
																		</div>
	
																	):(
																		""
																	)}
																	
																	{/* <div style={{color:"#d8d8d8",fontWeight:"normal"}}>
																		{date}
																	</div> */}
																	</Space>
																</div>
																{/* <ReactMarkdown>
																	{item.content}
																</ReactMarkdown> */}
	

																{isWaitting?(
																	
																	<div style={{
																		fontSize:20,
																		height:50,
																		marginTop:20,
																		color:"#a1a1a1"
																		// color:isWaitting ? ("#d8d8d8"):("#000000")
																		}}>
																		
																		<Typewriter
																			style={{
																			// fontSize:20,
																			}}
																			onInit={(typewriter) => {
																				typewriter
																				.typeString('送信しました')
																				.pauseFor(500)
																				.deleteAll()
																				.typeString('少々お待ちください...')
																				.pauseFor(5000)
																				.deleteAll()
																				.typeString('長い文章を生成しております...')
																				
																				.start();
																			}}/>
																		
																	</div>
																	
																):(
																	<div style={{
																		fontSize:20,
																		// color:isWaitting ? ("#d8d8d8"):("#000000")
																		}}>
																		<ReactMarkdown>
																		{item.content}
																		</ReactMarkdown>
																	</div>
																)}

																

	
																{/* <Typography>
																	<Paragraph style={{fontSize:16,marginTop:10}}>
																		
																		{item.content}
																	</Paragraph>
																</Typography> */}
	
															</div>
															
														</div>
													</Space>
													{/* <div style={{height:7}}/> */}
													{/* <div style={{width:width_middle-60}}><Divider/></div> */}
												</div>
												
											</div>
										)
									}

									
								})}


								{this.state.ifShowPromptTips ?(
									<Space direction="vertical" style={{marginLeft:47}}>

									
										<Button size="small" type="link"
											onClick = {()=>{
												this.shortCut_talk2AI_1("宣伝案を書いてみて")
												this.setState({ifShowPromptTips:false})
											}}
											>質問例 1: 宣伝案を書いてみて
										</Button>
										<Button size="small" type="link"
											onClick = {()=>{
												this.shortCut_talk2AI_1("強みと弱みを分析してみて")
												this.setState({ifShowPromptTips:false})
											}}
											>
											質問例 2: 強みと弱みを分析してみて
										</Button>
										<Button size="small" type="link"
											onClick = {()=>{
												this.shortCut_talk2AI_1("販売へのアドバイス")
												this.setState({ifShowPromptTips:false})
											}}
											>
											質問例 3: 販売へのアドバイス
										</Button>

										

									</Space>	
								):("")}

								
								<div style={{height:20}}/>
								
								<Space style={{marginLeft:47}}>

									<Popconfirm
										title="会話歴史をクリアしますか？"
										description="Are you sure to delete this task?"
										onConfirm={this.clearChatHistory}
										// onCancel={cancel}
										okText="Yes"
										cancelText="No"
										>
										<Button size="small" type="text"
											// icon = {ifClearingChatHistory?(<LoadingOutlined style={{fontSize:12}}/>):(<SettingOutlined style={{fontSize:12}}/>)   }
											icon = {<SettingOutlined />}
											style={{
												// marginLeft:-1,
												// marginTop:-10
												// fontSize:12

											}}
											disabled={ifUploading}
											danger
											>
											記録を削除
										</Button>
									</Popconfirm>

									{/* <Button size="small" type="text"
										// icon = {<SettingOutlined style={{fontSize:12}}/>}
										icon = {<SettingOutlined style={{fontSize:12}}/>}
										style={{
											marginLeft:-6,
											// marginTop:-10
											// fontSize:12

										}}
										onClick = {()=>{
											this.shortCut_talk2AI_1("強みと弱みを分析してみて")
											this.setState({ifShowPromptTips:false})
										}}
										>
										記録を削除
									</Button> */}


								</Space>
								
								
								


								<div style={{height:100}}/>
								
								{/* ------------------------------------ */}
								{/* 结尾定位用标签 */}
								<div
									ref={this.consoleEndRef}
									className="console-end"
									style={{ display: "block" }}
								></div>
								{/* ------------------------------------ */}
							</div>
							



							{/* pinInput
							输入栏--------------------------------------------------
									*/}
							<div
								
								style={{
								overflowX:"hidden",
								width:width_here-50,
								position:"absolute",
								// left:width_here/2-10,
								bottom:"0px",
								backgroundColor:"#f1f1f1",
								left:0,
								}}>
								
								<div style={{height:15}}/>
								<Space>
									<TextArea
										placeholder='Please enter content'
										style={{
										width:width_here-110,
										backgroundColor:"#f1f1f1"
										}}
										onChange={this.onChange_textInput}
									
										
										autoSize={{ minRows: 2, maxRows: 2 }}
										ref={this.Ref_TextInput}
										/>

									
									
										
									
									<Button
										//shape="circle"
										shape="circle"
										type="primary"
										style={{
											height:50,
											width:50,
											// position:"relative",
										}}
										disabled ={this.state.ifUploading}
										onClick={this.talk2AI}
										// icon= {ifUploading?(<LoadingOutlined/>):(<SendOutlined />)}
										>
										{ifUploading?(<LoadingOutlined/>):(<SendOutlined />)}
									</Button>	

									{/* <div
										//shape="circle"
										shape="circle"
										type="primary"
										style={{
											height:50,
											width:50,
											backgroundColor:"#ff0000",
											baorderR
											// position:"relative",

										}}
										disabled ={this.state.ifUploading}
										onClick={this.talk2AI}
										// icon= {ifUploading?(<LoadingOutlined/>):(<SendOutlined />)}
										>
											<LoadingOutlined/>
									</div>	 */}


									
								</Space>
								
								<div style={{height:15}}/>
							</div>
								
							<div
								// 输入栏--------------------------------------------------
								style={{
								
								width:width_here-50,
								height:1,
								// marginLeft:-50,
								position:"absolute",
								// left:-0,
								bottom:"104.5px",
								
								}}>
								
								
								<Divider/>
							</div>
							
							
							
						</div>


						
					</div>
				</div>

				{/* <div 
					style={{
					width:200,height:60,
					borderRadius:15,
					backgroundColor:"#f1f1f1",
					position:"absolute",
					right:50,
					top:50
					}}>
						<div style={{marginLeft:10,fontWeight:"bold",fontSize:20}}>Dev Mode</div>

						<div style={{marginLeft:11}}>
							<Dropdown
								
								menu={{
								items,
								}}
								>
								<a onClick={
									(e) => e.preventDefault()
									}>
								<Space>
									{this.state.mode_gpt}
									<DownOutlined />
								</Space>
								</a>
							</Dropdown>	
						</div>
						

				</div> */}
			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Page_talk2AI);