
import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Space,Button ,message,Slider} from 'antd';
import { TextArea} from 'antd-mobile'
import {env_serverAddress} from './lib_env'

import { 
	SendOutlined,
	LoadingOutlined,
	SettingOutlined,
	UserOutlined,
	UserAddOutlined,
	CloseOutlined,
	CloseCircleOutlined,
	CloudUploadOutlined,
	CheckCircleOutlined
} from '@ant-design/icons';

import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'




class Image2image extends Component {

	constructor(props){
        super(props);
        this.myRef_input_uploadImage=React.createRef();
	}
	
	//pinState
	state={
		currentImg:false,
		currentImg_after:false,
		prompt:""
	}


	onChange_prompt = (e) =>{

		//console.log(e)
		this.setState({prompt:e})
	}

	handleUploadImage = async(e)=> {

		console.log("uploading..")
		try{
			//console.log(command)
			//console.log(this.state.gptid_current)
			e.preventDefault();	
			var url = env_serverAddress+"neuroai/uploadimage"
			let file = e.target.files[0];
			const formdata = new FormData();
			this.setState({ifUploading:true,currentImg:"deleted"})

			// 一般上传--------------------------------------------------------
			
			var info = JSON.stringify({
				password:this.props.password
			})
			formdata.append('file', file);
			formdata.append('info', info);


			let response= await fetch(url,{
				method: 'post',
				body: formdata,
			})
			let result = await response.json()
			
			console.log(result)

			if(result.statusCode == 11){
				message.config({top: 3,duration: 2,});message.success('image uploaded!');
				this.setState({currentImg:result.returnData})
			}

			//pin1

			
		}
		catch(error){
		
			message.config({top: 3,duration: 2,});message.error('image too large');
		}
		// 重置input元素的value属性
		e.target.value = '';
		this.setState({ifUploading:false})
		
	}




	useStableDiffusion = async()=> {

		try{
			
			this.setState({ifUploading_useStableDiffusion:true,currentImg_after:"deleted"})
			var url = env_serverAddress+"neuroai/usestablediffusion"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					password:this.props.password,
					prompt:this.state.prompt
					
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			console.log(result)
			
			if(result == 0){
				message.error("Error!!")
				this.setState({ifUploading_useStableDiffusion:false})
			}else{
				this.setState({currentImg_after:result.returnData})
				this.setState({ifUploading_useStableDiffusion:false})

			}
	
		}
		catch(error){
			
		}

	}



	
	// handleUploadImage = (e)=> {
	// 	//this.uploadDataset()
	// 	if(this.state.ifUploading == false){
	// 		message.config({top: 3,duration: 2,});
	// 		message.info('uploading, please wait');
	// 		this.handleUploadImage_afterSync(e,"banner").then(()=>{
	// 			this.setState({ifUploading:false});this.fetchGptListByOwnerid()
	// 			this.close_ifShowEditGpt()
	// 		})
	// 	}
	// 	else{message.config({top: 3,duration: 2,});message.info('uploading,please wait');}		
	// }
		
	
	componentDidMount(){
		console.clear()
		

	}
	componentDidUpdate(){

	}
	
	
	render() {


		var currentImg = this.state.currentImg
		var currentImg_after = this.state.currentImg_after

		//var height_memberBar = this.myRef_member_div.current.clientHeight
		var dataset = this.props.dataset
		// console.log(dataset)
		if(dataset.length > 0){
			var lastData = dataset[dataset.length-1]

			// console.log(lastData)
			var sourceFile = lastData["sourceFile"]
			var afterFile = lastData["afterFile"]

			if(sourceFile != undefined){
				currentImg = sourceFile
			}
			if(afterFile != undefined){
				// currentImg_after = afterFile
			}
		}

		// 如果State里面不为空,则优先展示
		if(this.state.currentImg){
			currentImg = this.state.currentImg
		}
		if(this.state.currentImg_after){
			currentImg_after = this.state.currentImg_after
		}

		if(this.state.currentImg == "deleted"){
			currentImg = false
		}
		if(this.state.currentImg_after == "deleted"){
			currentImg_after = false
		}


		// pinReturn
		return(
		
			<div 
				style={{
				height:this.props.height,
				width:this.props.width,
				//backgroundColor:"#ffff00",
				overflow:"hidden",
				overflowX:"hidden"
				}}>	
				<div 
					style={{
					height:"auto",
					marginLeft:20,
					//marginTop:20,
					width:this.props.width,
					//backgroundColor:"#ffff00",
					overflowY:"hidden",
					overflowX:"hidden",
					//display: "inline-block",
			
					}}>
					{/* -------------------------------------------------- */}

					


					<div 
						style={{
						//height:this.props.height,
						width:this.props.width,
						//height:"300",
						//backgroundColor:"#ffff00",
						display: "inline-block",
						overflowX:"hidden",
						overflowY:"hidden",
						}}>
						
						{/* pin1 */}


						<div style={{height:17}}/>
						<div style={{marginLeft:0,fontSize:"20px",fontWeight:"bold"}}>
							Test Stable Duffusion -v2.1
						</div>
						<div style={{height:8}}/>
						

						<div style={{overflowY:"scroll",height:this.props.height-50}}>

							<div style={{height:10}}/>
							<div style={{paddingLeft:10}}></div>

							
							

								<Space style={{position:"relative"}}>
									
									
									{currentImg ? (

										<div 
											style={{cursor:"pointer"}}
											onClick = {()=>{
												// console.log("clicked")
												this.myRef_input_uploadImage.current.click();
											}}
											>
											<ImageComponent 
												ifRadius = {true}
												src = {currentImg} 
												width = {this.props.width/2-20}
												height = {(this.props.width/2-100)*0.618}
												
												/>


										</div>
										
									):(
										<div 
											onClick = {()=>{
												// console.log("clicked")
												this.myRef_input_uploadImage.current.click();
											}}
											style={{
												width : this.props.width/2-20,
												height :(this.props.width/2-100)*0.618,
												backgroundColor:"#f1f1f1",
												borderRadius:15,
												fontSize:30,
												color:"#d8d8d8",
												fontWeight:"bold",
												textAlign:"center",
												display: "flex",
												alignItems: "center", /* 垂直居中 */
												justifyContent: "center", /* 垂直居中 */
												cursor:"pointer",
											}}>
											<CloudUploadOutlined />
											

										</div>
									)}
									
									{currentImg_after ? (
										<ImageComponent 
											ifRadius = {true}
											src = {currentImg_after} 
											width = {this.props.width/2-20}
											height = {(this.props.width/2-100)*0.618}
											/>
									):(
										<div style={{
											overflow:"hidden",
											width : this.props.width/2-20,
											height :(this.props.width/2-100)*0.618,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontSize:30,
											color:"#d8d8d8",
											fontWeight:"bold",
											textAlign:"center",
											display: "flex",
											alignItems: "center", /* 垂直居中 */
											justifyContent: "center", /* 垂直居中 */
											// cursor:"pointer",
											}}>
											{this.state.ifUploading_useStableDiffusion ?(
												<div>
													<div style={{height:40}}/>
													<LoadingOutlined style={{fontSize:120}}/><br/>
													<div style={{height:10}}/>
													Stable Diffusion XL<br/>
													<div style={{fontSize:20,fontWeight:"normal"}}>Rendering your image</div>
													
													<br/>
												</div>

											):(	
												// pin1
												this.props.ifGpuIsWorking ?(
													<div>
														<div style={{height:40}}/>
														<CheckCircleOutlined style={{fontSize:120}}/><br/>
														<div style={{height:10}}/>
														Stable Diffusion XL<br/>
														<div style={{fontSize:20,fontWeight:"normal"}}>GPU server standing by</div>
														
														<br/>
													</div>
												):(
													this.props.ifChecking_GpuIsWorking? (
														<div>
															<div style={{height:40}}/>
															<LoadingOutlined style={{fontSize:120}}/><br/>
															<div style={{height:10}}/>
															Stable Diffusion XL<br/>
															<div style={{fontSize:20,fontWeight:"normal"}}>Connecting GPU server</div>
															
															<br/>
														</div>
													):(
														<div>
															<div style={{height:40}}/>
															<CloseCircleOutlined style={{fontSize:120}}/><br/>
															<div style={{height:10}}/>
															Stable Diffusion Offline<br/>
															<div style={{fontSize:20,fontWeight:"normal"}}>Please turn on the GPU server</div>
															
															<br/>
														</div>
													)
													
												)
												
											)}
											
											
											
											

										</div>
									)}
									{this.state.ifUploading ?(
										<LoadingOutlined style={{position:"absolute",top:12,left:20,fontSize:20}}/>
									):("")}

								</Space>
								<p>
									{/* pin1 */}
									<input 
										id = "uploadimg" type="file" accept="image/png, image/jpeg, image/jpg"
										onChange={this.handleUploadImage}
										ref={this.myRef_input_uploadImage}
										style={{display:"none",}}
										/>
										
								</p>
								
							
							
							{/* <div style={{height:10}}/>
							<div style={{marginLeft:0,fontSize:"20px",fontWeight:"bold"}}>
								rate
							</div>
							<div style={{height:8}}/>

							<div style={{marginLeft:0,width:this.props.width/2-20}}><Slider defaultValue={50} /></div>
							<div style={{height:20}}/> */}




							<div style={{marginLeft:0,fontSize:"20px",fontWeight:"bold"}}>
								プロンプトから入力
							</div>
							<div style={{height:8}}/>

							<TextArea
								placeholder='Please enter content'
								style={{
									width:this.props.width/2-40,
									backgroundColor:"#f1f1f1",
									borderRadius:10,
									padding:10,
								}}
								value={this.state.prompt}
								
								onChange={this.onChange_prompt}
								autoSize={{ minRows: 3, maxRows: 5 }}
								//ref={this.myRef}
								/>
							<div style={{height:14}}/>
							
							<Space>
								<Button 
									type="primary"
									onClick = {this.useStableDiffusion}
									icon = {this.state.ifUploading_useStableDiffusion? (<LoadingOutlined />):(<SendOutlined />)}
									disabled = {this.state.ifUploading_useStableDiffusion || !this.props.ifGpuIsWorking}
									>Generate
								</Button>	

								{/* <Button 
									//type="primary"
									onClick = {this.props.logOut}
									>Logout
								</Button>	 */}

							</Space>
							
							
							<div style={{height:20}}/>

							{/* <div style={{width:this.props.width/2-20}}><Divider style={{marginTop:10,marginBottom:30}}/></div> */}

							<div style={{marginLeft:0,fontSize:"20px",fontWeight:"bold"}}>
								GPT-4に生成して貰う
							</div>
							
							<div style={{height:8}}/>
							<TextArea
								placeholder='Please enter content'
								style={{
									width:this.props.width/2-40,
									backgroundColor:"#f1f1f1",
									borderRadius:10,
									padding:10,
								}}
								value={this.state.gptName_current}
								
								onChange={this.onChange_gptName}
								autoSize={{ minRows: 3, maxRows: 5 }}
								//ref={this.myRef}
								/>
							
							<div style={{height:14}}/>
							
							<Space>
								<Button 
									type="primary"
									onClick = {this.props.logOut}
									icon = {<SendOutlined />}
									>Generate prompt
								</Button>

								{/* <Button 
									type="primary"
									onClick = {this.props.logOut}
									>Logout
								</Button>	 */}

							</Space>
							
							
							


						

						
							

							

							<div style={{height:50}}/>
				
						</div>
						
					</div>
					
					
					
					
					



				</div>



			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Image2image);