import cookie from "react-cookies";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Tranquiluxe } from "uvcanvas"
import { Space,Divider,Typography,Skeleton,Button ,message,Avatar,Input,Switch} from 'antd';
import { 
	GlobalOutlined,
	LoadingOutlined,
	TeamOutlined,
	UserOutlined,
	EyeOutlined,
	CloseOutlined,
	AppstoreOutlined,
	CheckOutlined,
	CompassOutlined,
	EyeInvisibleOutlined, 
	EyeTwoTone,
	CloudUploadOutlined
} from '@ant-design/icons';
import CardSmall from "./component/CardSmall";


class App extends Component {
  render() {
    var width = document.documentElement.clientWidth;
    var height = document.documentElement.clientHeight;
    var ifMobile = width < height;
    return (
      <div
        style={{
          height: this.props.height,
          width: this.props.width,
          //backgroundColor:"#ffff00",
          overflow: "hidden",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            height: "auto",
            marginLeft: 5,
            //marginTop:20,
            width: this.props.width,
            //backgroundColor:"#ffff00",
            overflowY: "hidden",
            overflowX: "hidden",
            //display: "inline-block",
          }}
        >
          {/* -------------------------------------------------- */}

          <Space
            style={{
              width: this.props.width,
            }}
          >
            <CardSmall
              icon={"newRobot"}
              title={"New Data"}
              description={"Create a new Data"}
              // callBack = {this.newGpt}
            />
          </Space>
          <div style={{ height: 20 }} />

          <div
            style={{
              width: this.props.width,
              display: "inline-block",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
          >
            <div
              style={{ marginLeft: 20, fontSize: "15px", fontWeight: "bold" }}
            >
              My Agents
            </div>
            <div style={{ height: 5 }} />
            <div
              style={{ overflowY: "scroll", height: this.props.height - 160 }}
            >
              {/* <GPTList
                list_robots={this.state.list_robots}
                loading={this.state.ifLoadingData}
                handleClick={this.callBack}
                requireGptToken={this.requireGptToken}
              /> */}
              <div style={{ height: 100 }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
