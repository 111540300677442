

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Tooltip} from 'antd';

import { Space, Dropdown,Modal} from 'antd';
import { Button,message} from 'antd';
import { 
	SettingOutlined,
	CloseCircleOutlined,
	DownOutlined,
	LeftCircleFilled,
	RightOutlined,
	PlusOutlined,
	AppstoreOutlined,
	CloseOutlined,
	PlusCircleOutlined,
	LeftCircleOutlined,
	LoadingOutlined
} from '@ant-design/icons';
// import icon_crab from "./static/crab.png"
import icon_chatgpt from "./static/gpticon.png"
import ImageComponent from "./Compo_ImageComponent"

//---------------------------------------------
window.global = {
	// your global variables here
	gptid_clicked:0
}

//---------------------------------------------




const onClick = ({ key }) => {
	//message.info(`Click on item ${key}`);
	window.global.gptid_clicked = key
  };


class Compo_card_gpt extends Component {


	
	//pinState
	state={
		//switch_ifSureLeaveroom:false,
		isClicked:false,
		//var_test:"haha3333333444444",
		ifShowEditFLow:false,
		gptList:[],
		
		flow : [
			{"type":"tell","target":"jiangyeye2hao"},
			{"type":"tell","target":"jiangyeye2hao"},
			{"type":"tell","target":"jiangyeye2hao"},
		]

	}





	changeRobot = () =>{

		//this.props.callBack_changeRobot(gpdInfo)
		

		setTimeout(()=>{
		
			var num = window.global.gptid_clicked
			//console.log(num)

			var gptInfo_selected = []

			for (var i in this.props.gptList_forPullDown){
				var item = this.props.gptList_forPullDown[i]
				if(item.gptid == num){
					gptInfo_selected = item
				}
			}
			//console.log(gptInfo_selected)
			this.props.callBack_changeRobot(gptInfo_selected)


		},100); 
		
	}	

	addNode = () =>{
		var temp_flow = this.state.flow
		temp_flow.push({
			role:"blank",
			target:0
		})
		console.log(temp_flow)
		this.setState({flow:temp_flow})
	}
	

	componentDidUpdate(){
		//console.log(this.props.list_gpt)
		//gptList_forPullDown

	
		
	}
	
	
	render() {
		

		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		
		var title = this.props.title
		var description = this.props.description
		var icon = this.props.icon

		if(title === undefined){title = "loading..."}
		if(description === undefined){description = "undefined"}
		if(icon === undefined || icon == 0){icon = false}





		var gptList_forPullDown_new = []
		//console.log(this.props.gptList_forPullDown)

		for (var i in this.props.gptList_forPullDown){
			var item = this.props.gptList_forPullDown[i]
			var gptName_deepCopied = JSON.parse(JSON.stringify(item.gptName))
			//console.log(item)
			gptList_forPullDown_new.push(
				{
					key: item.gptid,
					label: (
						<a onClick = {()=>{this.changeRobot()}}>
							{item.gptName}
						</a>
					)
				}
			)
		}

		return(
			<div>


				
				<Space >
					
					

					<div style={{
                        marginTop:20,
                        width:this.props.width_here/2,
                        padding:9,
                        borderRadius:12,
                        // height:35,
                        backgroundColor:"#f1f1f1",
                        position:"relative"

                        }}>

                        <div style={{fontWeight:"bold"}}>
                            {this.props.title}
                        </div>
						{this.state.isClicked?(
							""
						):(

							<Space style={{position:"absolute",right:10,top:5}}>
								
								
								<Button  
									type="primary"
									shape="round"
									size="small"
									// icon={<RightOutlined/>}
									icon = {<LoadingOutlined/>}
									>
										Run
								</Button>
									
								<Button  
									type="primary"
									shape="round"
									size="small"
                                    danger
									// icon={<RightOutlined/>}
									icon = {<LoadingOutlined/>}
									>
										Run
								</Button>
									
							</Space>

							
						)}
						
					</div>
				</Space>

				
			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Compo_card_gpt);