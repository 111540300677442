

export var test_call = (text) =>{
    //console.log(text)
}
export var fetch_by_json = async(url,args) => {
    args["ARG_TEST_TIME"] = 0
    let response= await fetch(url,{
        method: 'post',
        body: JSON.stringify(args),
        headers: {'Content-Type': 'application/json'}
    })
    let result = await response.json()
    return(result)

}