import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import { Divider,Typography,Space,Button ,message,Slider,Tag,Input} from 'antd';
import { 
	SendOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import video_kyoto from "./static/kyoto.mp4"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import waveData_raw from './static/kyoto.json';
import {anaWave,movingAverageFilter,generateRandomWave,findWaveTrough,convertTroughToArray} from './lib_anaWave';


function transformData(data) {
    // 初始化一个空对象用于存储转换后的数据
    let transformedData = {};

    // 遍历输入的数据对象
    for (let key in data) {
        let currentData = data[key];
        for (let tag in currentData) {
            if (!transformedData[tag]) {
                transformedData[tag] = [];
            }
            transformedData[tag].push(currentData[tag]);
        }
    }

    return transformedData;
}

function restoreData(data) {
    let transformedData = [];
    let tags = Object.keys(data);
    
    for (let i = 0; i < data[tags[0]].length; i++) {
        let obj = {};
        tags.forEach(tag => {
			if(data[tag][i] != 0){
				obj[tag] = data[tag][i];
			}
            
        });
        transformedData.push(obj);
    }

    return transformedData;
}





const colorList = [
	"#FF7700",
	"#05C3FD",
	"#22CC14",
	"#FF0099",
	"#8A8A8A",
]





class App extends Component {

	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
	}
	
	//pinState
	state={
		userName:false,
		ifConnected:false,
		sec:0,
		tagsInUse:[
			"tag"
			// "伝統的な",
			// "静かな・落ち着きのある",
		],
		ifAnaWave:true,
		windowSize:3,
		videoSize:10,
		amplitude:2,
		max:20,
		noise:0,
		waveData:[
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1},
			{tag:1}
		]
	}

	handleGenerate = () =>{

		const length = this.state.videoSize
		const noise = this.state.noise
		const min = 0.5; // 折线最小值
		const max = this.state.max
		const amplitude = this.state.amplitude; // 设定的波形变化幅度
		const waveArray = generateRandomWave(length, min, max, amplitude,noise);
		this.setState({waveData:waveArray})
	}


	componentDidMount() {

	  }

	handleMove = () =>{

	}
	

	render() {


		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var waveData = 0


		//pin1
		if(this.state.ifAnaWave){
			var waveData_t1 = transformData(this.state.waveData) //{tag1:[1,2,3,4],tag2:[....]....}

			var waveData_t2 = {}
			for (var tag in waveData_t1){
				console.log(waveData_t1[tag])

				
				var temp = movingAverageFilter(waveData_t1[tag], this.state.windowSize)
				
				const windowLength = this.state.windowSize
				var temp2 = findWaveTrough(temp, windowLength)
				console.log(temp)
				console.log(temp2)
				const waveLength = temp.length + 1
				var temp3 = convertTroughToArray(waveLength,temp2)
				
				
				waveData_t2[tag] = temp
				waveData_t2[tag+"_low"] = temp3
				// console.log(waveData_t2[tag+"_low"])

				
			}
			waveData = restoreData(waveData_t2)
		}else{
			waveData = this.state.waveData
		}
		// console.log(waveData) //[{tag1:n,tag2:n,...}]
		




		var waveData_current = []
		for (var i in waveData){
			// 每一秒的数据
			var item = waveData[i]
			var newItem = {}

			// 每一秒中有哪些tag
			for (let k in item){
				newItem[k] = item[k]
				// newItem["test1"] = 5
			}

			newItem["name"] = i // 在图表显示中需要
			waveData_current.push(newItem)
		}


		var tagsInUse = []
		for (let i in this.state.tagsInUse){
			var tag = this.state.tagsInUse[i]
			tagsInUse.push({
				id:i,
				tag:tag
			})
			tagsInUse.push({
				id:i+10,
				tag:tag+"_low"
			})
		}

		// tagsInUse.push({id:999,tag:"test1"})
		// console.log(thumbnailURL)

		return(
		
			<div 
				style={{
				height:height,
				position:"absolute",left:width-this.props.width,
				width:this.props.width-0,
				backgroundColor:"#ffffff",
				overflowY:"scroll",
				overflowX:"hidden",
				// marginTop:20,
				// marginLeft:20,
				}}>	
				
				
				<div style={{height:20}}/>
				<div style={{
					float:"left",
					width:width-320,
					height:150,
					backgroundColor:"#f1f1f1",
					borderRadius:15,
					marginLeft:20,
					padding:15,
					overflow:"hidden",
					}}>
					

					
					{/* <div style={{height:5}}/> */}
					<div>秒数</div>
					<Slider
						style={{width:width-320}}
						min={10}
						max={3600}
						onChange={(e)=>{
							this.setState({videoSize:e})
						}}
						value={typeof this.state.videoSize === 'number' ? this.state.videoSize : 0}
					/>
					
					<Space>
						<div>
							<div>amplitude(0.1)</div>
							<Slider
								style={{width:(width-320)/4}}
								min={1}
								max={20}
								onChange={(e)=>{
									this.setState({amplitude:e})
								}}
								value={typeof this.state.amplitude === 'number' ? this.state.amplitude : 0}
							/>
						</div>
						<div>
							<div>max</div>
							<Slider
								style={{width:(width-320)/4}}
								min={1}
								max={20}
								onChange={(e)=>{
									this.setState({max:e})
								}}
								value={typeof this.state.max === 'number' ? this.state.max : 0}
							/>
						</div>
						<div>
							<div>noise</div>
							<Slider
								style={{width:(width-320)/4}}
								min={0}
								max={10}
								onChange={(e)=>{
									this.setState({noise:e})
								}}
								value={typeof this.state.max === 'number' ? this.state.noise : 0}
							/>
						</div>
						

					</Space>
					



					<div style={{height:15}}/>
					<Button
						onClick = {()=>{
							this.handleGenerate()
						}}
						// style={{color:!this.state.ifAnaWave && "#d8d8d8"}}
						// type="generate"
						danger
						>
						generate
					</Button>
					
					

				</div>
				<Space style={{marginLeft:25,marginTop:10}}>
					
					<Button
						onClick = {()=>{
							this.setState({ifAnaWave:!this.state.ifAnaWave})
						}}
						style={{color:!this.state.ifAnaWave && "#d8d8d8"}}
						type={this.state.ifAnaWave ? "primary":"default"}
					>
						{this.state.ifAnaWave ? "波形分析":"波形分析"}
					</Button>

					<Slider
						style={{width:100}}
						min={0}
						max={20}
						onChange={(e)=>{
							this.setState({windowSize:e})
						}}
						value={typeof this.state.windowSize === 'number' ? this.state.windowSize : 0}
					/>


				</Space>
				
				<div style={{height:10}}/>
				<LineChart
					style={{marginLeft:-50}}
					onMouseMove = {this.handleMove}
					width={width-200}
					height={height-350}
					data={waveData_current}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
					>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis 
					// domain={[0, 40]} 
					/>
					<Tooltip />
					{/* <Tooltip label={this.handleTooltip} /> */}
					{/* <Tooltip content={
						<CustomTooltip 
							tagsInUse = {tagsInUse}
							setSec = {this.setSec}
							captureThumbnail = {this.captureThumbnail}
							/>
						} 
						/> */}
					<Legend />
					{tagsInUse.map((item)=>{
						return(
							<Line type="monotone" dataKey={item.tag} stroke={colorList[item.id]} strokeWidth={2} activeDot={{ r: 8 }} />
						)
					})}
					{/* <Line type="monotone" dataKey="伝統的な" stroke={colorList[0]} strokeWidth={2} activeDot={{ r: 8 }} />
					<Line type="monotone" dataKey="静かな・落ち着きのある" stroke={colorList[1]} strokeWidth={2} activeDot={{ r: 8 }} /> */}
				</LineChart>


				
				{/* <div style={{height:200}}/> */}
				





			</div>






			
			
			
			
		)

  	}
}

export default withRouter(App);