import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
import { Divider,Popconfirm,Typography,Space,Button ,message,Tooltip as Tooltip_antd,Tag,Input,Modal,Image,Dropdown,Skeleton,Slider,Segmented} from 'antd';
// import { TextArea,Popup, Loading} from 'antd-mobile'
import {env_serverAddress} from './lib_env'
import {fetch_by_json} from './lib_fetch'
import ReactMarkdown from 'react-markdown';
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";

import { 
	InfoCircleOutlined,
	SendOutlined,
	LoadingOutlined,
	SettingOutlined,
	BulbOutlined,
	AppstoreOutlined,
	CopyOutlined,
	CloseCircleOutlined,
	CloudUploadOutlined,
	CheckCircleOutlined,
	ExperimentOutlined,
	DeleteOutlined,
	RedoOutlined,
	FileTextOutlined,
	DownOutlined,
} from '@ant-design/icons';

import emoji_forbidden from "./static/emoji_forbidden.png";
import emoji_kiui from "./static/emoji_kiui.png";
import emoji_graph_a from "./static/emoji_graph_a.png";
import emoji_graph_b from "./static/emoji_graph_b.png";
import emoji_zoom from "./static/emoji_zoom.png";
import emoji_stethoscope from "./static/emoji_stethoscope.png";
import emoji_pin from "./static/emoji_pin.png";

import bg_neuroai from "./static/bg_neuroai.png"
import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'
import video_csv from "./static/kyoto.mp4"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import csvData from './static/kyoto.json';
// import Compo_Tag from "./Compo_Tag.tsx"
//-------------------------------
import logo_dplanner from "./static/dplanner.png"
import {anaWave,movingAverageFilter,tellWindowSize,findMinNonZero,findWaveTrough,addWeightToTagsOfGaussian,addWeightToTagsOfLine,addWeightToTagsOfCurved,addWeightToTagsOfGaussian2} from './lib_anaWave';

// -----------------------------
// config

// const filter_minAvgOfImpress = 0.5
// -----------------------------
const { Search } = Input;	
const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;



function sortDataByWeightedValue(data, W_avg, W_devi, cutOff) {

	// data {"tag1":{"avg":xxx, "devi":yyyy},.....}

    if (typeof data !== 'object' || data === null) {
        throw new Error('Input should be a non-null object');
    }

    if (typeof W_avg !== 'number' || typeof W_devi !== 'number') {
        throw new Error('Weight factors should be numbers');
    }

    if (typeof cutOff !== 'number' || cutOff < 0) {
        throw new Error('cutOff should be a non-negative number');
    }

    // Convert the object to an array of entries
    const entries = Object.entries(data);

    // Sort the entries based on the weighted value
    entries.sort((a, b) => {
        const valueA = a[1].avg * W_avg + a[1].devi * W_devi;
        const valueB = b[1].avg * W_avg + b[1].devi * W_devi;
        return valueB - valueA; // Descending order
    });

    // Remove the last `cutOff` elements
    const filteredEntries = entries.slice(0, entries.length - cutOff);

    // Convert filtered entries back to an object
    const sortedData = Object.fromEntries(filteredEntries);

    return sortedData;
}


function convertToDateTimeFormat(str) {
    // 将字符串转换为数字
    const timestamp = parseInt(str);
    // 创建一个 Date 对象
    const date = new Date(timestamp * 1000);

    // 获取年月日时分
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月份从0开始，所以要加1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // 返回年月日时分的格式
    return `${year}-${addZeroPadding(month)}-${addZeroPadding(day)} ${addZeroPadding(hours)}:${addZeroPadding(minutes)}`;
}


const calculateStandardDeviation = (values) => {
	// 计算均值
	const mean = values.reduce((acc, val) => acc + val, 0) / values.length;

	// 计算每个值与均值的平方差
	const squaredDifferences = values.map(val => (val - mean) ** 2);

	// 计算方差
	const variance = squaredDifferences.reduce((acc, val) => acc + val, 0) / values.length;

	// 计算标准差
	const standardDeviation = Math.sqrt(variance);

	return standardDeviation;
};


// 線型 
// function addWeightToTags(tags) {
//     // 获取所有avg的值
//     const allAvgs = Object.values(tags).map(tag => tag.avg);

//     // 获取最小和最大值
//     const minAvg = Math.min(...allAvgs);
//     const maxAvg = Math.max(...allAvgs);
//     const range = maxAvg - minAvg;

//     // 如果所有的avg值相同，直接设置所有的权重为1
//     if (range === 0) {
//         for (let tag in tags) {
//             tags[tag].weight = 1;
//         }
//     } else {
//         // 计算每个tag的权重
//         for (let tag in tags) {
//             tags[tag].weight = (tags[tag].avg - minAvg) / range;
//         }
//     }
//     return tags;
// }





// 辅助函数：在数字小于10时在前面添加0
function addZeroPadding(number) {
    return number < 10 ? '0' + number : number;
}


function findTopThree(list) {
    // 按照 value 属性排序
    list.sort(function(a, b) {
        return b.value - a.value;
    });

    // 选择前三个元素
    var topThree = list.slice(0, 3);
    
    return topThree;
}

function findLastThree(list) {
    // 按照 value 属性排序
    list.sort(function(a, b) {
        return b.value - a.value;
    });

    // 选择前三个元素
    var lastThree = list.slice(-3);
    
    return lastThree;
}


export function sortByValue(list) {
	// 使用数组的sort方法进行排序
	list.sort(function (a, b) {
	  return b.value - a.value;
	});
	return list;
  }

function formatTime(seconds) {
	var hours = Math.floor(seconds / 3600);
	var minutes = Math.floor((seconds % 3600) / 60);
	var remainingSeconds = seconds % 60;
  
	var result = '';
	if (hours > 0) {
	  result += `${hours}小时`;
	}
	if (minutes > 0 || hours > 0) {
	  result += `${minutes}分`;
	}
	result += `${remainingSeconds}秒`;
  
	return result;
  }
  
function findDecreasingSeconds(score, num) {
	let decreasingSeconds = [];
  
	for (let i = 2; i <= Object.keys(score).length; i++) {
	  const currentSecond = i.toString();
	  const previousSecond = (i - 1).toString();
	  const decrease = score[previousSecond] - score[currentSecond];
  
	  if (decrease > 0) {
		decreasingSeconds.push({ second: currentSecond, decrease: decrease });
	  }
	}
  
	decreasingSeconds.sort((a, b) => b.decrease - a.decrease);
	return decreasingSeconds.slice(0, num);
}

function findIncreasingSeconds(score, num) {
    let increasingSeconds = [];

    for (let i = 2; i <= Object.keys(score).length; i++) {
        const currentSecond = i.toString();
        const previousSecond = (i - 1).toString();
        const increase = score[currentSecond] - score[previousSecond];

        if (increase > 0) {
            increasingSeconds.push({ second: currentSecond, increase: increase });
        }
    }

    increasingSeconds.sort((a, b) => b.increase - a.increase);
    return increasingSeconds.slice(0, num);
}

// 计算平均值
function mean(arr) {
	return arr.reduce((acc, val) => acc + val, 0) / arr.length;
  }
  
// 计算相关系数
function correlationCoefficient(a, b) {
	if (a.length !== b.length) {
		throw new Error('Lists must have the same length');
	}

	const meanA = mean(a);
	const meanB = mean(b);

	let numerator = 0;
	let sumSquaredDiffA = 0;
	let sumSquaredDiffB = 0;

	for (let i = 0; i < a.length; i++) {
		numerator += (a[i] - meanA) * (b[i] - meanB);
		sumSquaredDiffA += Math.pow(a[i] - meanA, 2);
		sumSquaredDiffB += Math.pow(b[i] - meanB, 2);
}

const denominator = Math.sqrt(sumSquaredDiffA * sumSquaredDiffB);

// 避免除以零错误
if (denominator === 0) {
	return 0;
}

return numerator / denominator;
}


const colorList = [
	"#FF7700",
	"#05C3FD",
	"#22CC14",
	"#FF0099",
	"#8A8A8A",
]


const list_chikaku = [
	"記憶定着度", 
	"試用意向", 
	"愛用意向", 
	"購買意向", 
	"好み／嗜好",
	"美しい",
	"都会的", 
	"可愛い",
	"高級", 
	"男性的", 
	"陽気",
	"現代的", 
	"シンプル", 
	"暖かい",
	"繊細",
	"知的", 
	"穏やか", 
	"人間的", 
	"清潔", 
	"静か",
	"醜い", 
	"田舎っぽい", 
	"気持ち悪い", 
	"安っぽい", 
	"女性的", 
	"陰気", 
	"伝統的",
	"複雑", 
	"涼しい", 
	"大胆", 
	"滑稽",
	"躍動的",
	"機械的", 
	"不潔", 
	"騒がしい", 
	"広告効果", 
	"好感度", 
]


const list_keiyoushi = [
	"美しい",
	"都会的", 
	"可愛い",
	"高級", 
	"男性的", 
	"陽気",
	"現代的", 
	"シンプル", 
	"暖かい",
	"繊細",
	"知的", 
	"穏やか", 
	"人間的", 
	"清潔", 
	"静か",
	"醜い", 
	"田舎っぽい", 
	"気持ち悪い", 
	"安っぽい", 
	"女性的", 
	"陰気", 
	"伝統的",
	"複雑", 
	"涼しい", 
	"大胆", 
	"滑稽",
	"躍動的",
	"機械的", 
	"不潔", 
	"騒がしい", 
]
// const list_chikaku = [
//     "美しい",
//     "醜い",	
//     "都会的な",	
//     "田舎っぽい",	
//     "高級な",	
//     "安っぽい",	
//     "明るい",	
//     "暗い",	
//     "現代的な",	
//     "伝統的な",	
//     "シンプル・わかりやすい",	
//     "複雑・わかりにくい	",
//     "清潔な",	
//     "不潔な",	
//     "静かな・落ち着きのある",	
//     "騒がしい・活気のある",	
//     "暖かい",	
//     "涼しい",	
//     "居心地の良い"	,
//     "居心地の悪い"	,
//     "自然な",	
//     "人工的な",	
//     "個性的な",	
//     "一般的な",	
//     "親しみやすい"	,
//     "親しみにくい"	,
//     "変化のある",	
//     "単調な",	
//     "上品な",	
//     "下品な",	
//     "可愛い",	
//     "興味深い",	
//     "退屈な",	
//     "楽しい",	
//     "つまらない",	
//     "惹かれる",	
//     "嫌な",	
//     "快い",	
//     "不快な",	
//     "安心な",	
//     "不安な",	
//     "信頼できる",	
//     "信頼できない",	
//     "悲しい",	
//     "驚き",	
//     "怖い",	
//     "腹立たしい"
// ]
var currentSec = 0
var thumbnailURL = 0






// 创建自定义的Tooltip组件
const CustomTooltip = ({ active, payload, label,tagsInUse,setSec,captureThumbnail}) => {
	if (active && payload && payload.length) {
	  // 在这里处理payload，例如保存到state或者执行其他逻辑
	//   console.log(tagsInUse)
	//   console.log(payload[0].payload); // 这里是当前点的详细信息
		
	  let currentTime = label
	  let currentData = payload[0].payload
	  
	  setSec(currentTime)
	  thumbnailURL = captureThumbnail()
	//   console.log(thumbnailURL)

	  return (
		<div style={{
			backgroundColor:"#ffffff",
			padding:10,
			borderRadius:15
			}}>
			<img src={thumbnailURL} alt="Thumbnail" />
			<p className="label">{`时间: ${currentTime}`}</p>
			{tagsInUse.map(item=>{
				return(
					<p style={{color:colorList[item.id]}} >{`${item.tag}: ${currentData[item.tag].toFixed(5)}`}</p>
				)
			})}
		</div>
	  );
	}
	return null;
};




var currentSec = 0
var thumbnailURL = 0


// pinImport
//---------------------------------------
const preventDefault = (e) => {
	e.preventDefault();
	// console.log('Clicked! But prevent default.');
  };


class Page_AIAnalysize extends Component {

	constructor(props){
        super(props);
        this.myRef_input_uploadCSV=React.createRef();
		this.topRef = React.createRef();
		this.reportRef = React.createRef();
		this.generateButtonRef = React.createRef();
		this.videoRef = React.createRef();
		this.consoleEndRef = React.createRef();
	}



	
	//pinState
	state={
		tagsInUse:[
			"伝統的な",
			"静かな・落ち着きのある",
		],
		tagsSelected_correlation:[],
		tagsSelected_kadai_m:[],
		sec:0,
		usecase:[
			// {
			// 	time:0,
			// 	title:"xxxxxxxxxx",
			// 	type:"correlation",
			// 	status:"loading",

			// },
			// {
			// 	time:0,
			// 	title:"xxxxxxxxxx",
			// 	type:"weakness",
			// 	status:"done",
			// }
		],
		ifShowCreateNewUsecase:false,
		ifShowCreateNewUsecase_HighAndLow:false,
		ifShowCreateNewUsecase_Vision:false,
		ifShowCreateNewUsecase_Problem:false,
		ifShowCreateNewUsecase_auto:false,
		ifShowGraph:false,
		temp_top3:[],
		temp_bottom3:[],
		temp_time_vision:8,
		segment_kadai:"手動分析",
		prompt:"prompt_a",
		promptLib:{prompt_a:{"version":"loading...","prompt":[]}},
		CSVDetail:[]
	}

	

	setSec = (sec) =>{
		// this.setState({sec:sec})
		currentSec = sec
		// console.log(currentSec)
	}


	captureThumbnail = () => {
		const canvas = document.createElement('canvas');
		canvas.width = 320; // 设置画布宽度为 160
		canvas.height = 180; // 设置画布高度为 90
		canvas.getContext('2d').drawImage(this.videoRef.current, 0, 0, 320, 180); // 绘制视频画面到画布
		
		thumbnailURL = canvas.toDataURL(); // 获取画面的 base64 编码
		// this.setState({ thumbnailURL }); // 更新截图的 base64 编码到状态中
		// console.log(thumbnailURL)
		return thumbnailURL
	};

	handleTag = (tag) =>{
		console.log(tag)
		var tagsInUse = this.state.tagsInUse
		
		if(tagsInUse.includes(tag)){
			// console.log("yes")
			var tagsInUse_ = tagsInUse.filter(item => item !== tag);
			// console.log(tagsInUse_)
			this.setState({tagsInUse:tagsInUse_})
		}else{
			if(tagsInUse.length <= 4){
				tagsInUse.push(tag)
				this.setState({tagsInUse:tagsInUse})
			}else{
				message.error("折れ線は５本まで")
			}
			
		}
		
	}
	handleTag_SelectIncorrelation = (tag) =>{
		console.log(tag)
		var tagsSelected_correlation = this.state.tagsSelected_correlation
		
		if(tagsSelected_correlation.includes(tag)){
			// console.log("yes")
			var tagsSelected_correlation_ = tagsSelected_correlation.filter(item => item !== tag);
			// console.log(tagsInUse_)
			this.setState({tagsSelected_correlation:tagsSelected_correlation_})
		}else{
			if(tagsSelected_correlation.length <= 1){
				tagsSelected_correlation.push(tag)
				this.setState({tagsSelected_correlation:tagsSelected_correlation})
			}else{
				message.error("折れ線は2本まで")
			}	
		}
	}

	handleTag_SelectInKadai = (tag) =>{
		console.log(tag)
		var tagsSelected_kadai_m = this.state.tagsSelected_kadai_m
		
		if(tagsSelected_kadai_m.includes(tag)){
			// console.log("yes")
			var tagsSelected_kadai_m = tagsSelected_kadai_m.filter(item => item !== tag);
			// console.log(tagsInUse_)
			this.setState({tagsSelected_kadai_m:tagsSelected_kadai_m})
		}else{
			if(tagsSelected_kadai_m.length <= 2){
				tagsSelected_kadai_m.push(tag)
				this.setState({tagsSelected_kadai_m:tagsSelected_kadai_m})
			}else{
				message.error("選択した項目は３つまで")
			}	
		}
	}

	


	//pinAddAuto
	addUsecaseAuto = async(source) =>{
		var title = "自動分析"
		const newUse = {
			time:0,
			title:title,
			type:"auto",
			content:"testing",
			source:source
		}
		console.log(newUse)
		await this.addNewUse(newUse,"auto")
		await this.fetchData()
		const timer = setTimeout(() => {
			this.scrollToBottom()
		}, 100);
		
	}



	//pinAddUse
	addUsecaseKadai_requireAdvice = (item) =>{
		console.log(item)
	}


	addUsecaseKadai = async() =>{

		const tagsSelected_kadai_m = this.state.tagsSelected_kadai_m
		
		
		var csvData_sortByTag = {}
		
		//
		


		for (var tag in csvData[0]){
			csvData_sortByTag[tag] = {}
		}
		for (var sec in csvData){
			const data = csvData[sec]
			for (var tag in data){
				const sec_ = parseInt(sec) + 1
				csvData_sortByTag[tag][""+sec_] = data[tag].toFixed(2)
			}
		}


		var tagWithValue = {}
		var title = ""
		for (var i in tagsSelected_kadai_m){
			const tag = tagsSelected_kadai_m[i]
			title = title + "" + tag + "" + " と "
			tagWithValue[tag] = csvData_sortByTag[tag]
		}

		var decreasingSecondsList = []
		var decreasingSecondsListForGPT = []
		for(let index in this.state.tagsSelected_kadai_m){
			const tag = this.state.tagsSelected_kadai_m[index]
			// console.log(tag)
			// console.log(findDecreasingSeconds(tagWithValue[tag],16))

			var data = findDecreasingSeconds(tagWithValue[tag],16)
			var data_ = []
			for (var index2 in data){
				data_.push(parseInt(data[index2]["second"]))
			}

			decreasingSecondsList.push({
				tag:tag,
				data:data_,
			})
			decreasingSecondsListForGPT.push({
				tag:tag,
				data:data
			})
		}
		console.log(decreasingSecondsList)
		console.log(decreasingSecondsListForGPT)

		var title = "課題分析( 手動 - "
		for(i in decreasingSecondsList){
			title = title + decreasingSecondsList[i]["tag"] + " "
		}
		title = title + ")"

		const newUse = {
			time:0,
			title:title,
			type:"kadai-m",
			content:"testing",
			decreasingSecondsList:decreasingSecondsList,
			decreasingSecondsListForGPT:decreasingSecondsListForGPT,
		}
		
		console.log(newUse)
		await this.addNewUse(newUse,"kadai")
		await this.fetchData()
		const timer = setTimeout(() => {
			this.scrollToBottom()
		}, 100);
	}

	addUsecaseVision = async() =>{

		const range_here = [this.state.temp_time_vision-8,this.state.temp_time_vision+8]
		
		var average = {}
		var timeSeries = {}


		// 包含所有tag的指定范围内的csv
		for (var tag in csvData[0]){
			timeSeries[tag] = {}
		}
		for (var sec in csvData){
			// console.log(sec)
			if(sec <= range_here[1] && sec >= range_here[0]){
				const data = csvData[sec]
				for (var tag in data){
					const sec_ = parseInt(sec)
					timeSeries[tag][""+sec_] = data[tag].toFixed(2)
				}
			}		
		}

		//计算平均值
		var csvData_sortByTag = {}
		for (var tag in csvData[0]){
			csvData_sortByTag[tag] = []
		}
		for (var sec in csvData){
			const data = csvData[sec]
			for (var tag in data){
				csvData_sortByTag[tag].push(parseFloat(data[tag].toFixed(2)))
			}
		}
		// console.log(csvData_sortByTag)
		var average = {}
		for (var tag in csvData_sortByTag){
			var item = csvData_sortByTag[tag]
			average[tag] = (item.reduce((acc, val) => acc + val, 0) / item.length).toFixed(2)
		}


		console.log(average)
		console.log(timeSeries)
		console.log(range_here)

		const data = {
			average:average,
			timeSeries:timeSeries,
			range:range_here,
		}

		const newUse = {
			time:0,
			title:"特定画面の分析",
			type:"vision",
			// status:"loading",
			content:"",
			data:data
		}

		await this.addNewUse(newUse,"vision")
		await this.fetchData()
		const timer = setTimeout(() => {
			this.scrollToBottom()
		}, 100);
	}

	addUsecaseHighAndLow = async() =>{

		var csvData_sortByTag = {}
		for (var tag in csvData[0]){
			csvData_sortByTag[tag] = {}
		}
		for (var sec in csvData){
			const data = csvData[sec]
			for (var tag in data){
				const sec_ = parseInt(sec) + 1
				csvData_sortByTag[tag][""+sec_] = data[tag].toFixed(2)
			}
		}

		var average = {}

		var timeSeries = {}
		for (var i in this.state.temp_top3){
			const tag = this.state.temp_top3[i][0]
			timeSeries[tag] = csvData_sortByTag[tag]
			average[tag] = this.state.temp_top3[i][1]
		}
		for (var i in this.state.temp_bottom3){
			const tag = this.state.temp_bottom3[i][0]
			timeSeries[tag] = csvData_sortByTag[tag]
			average[tag] = this.state.temp_bottom3[i][1]
		}
		const data = {
			average:average,
			timeSeries:timeSeries
		}
		console.log(data)


		const newUse = {
			time:0,
			title:"特徴",
			type:"highAndLow",
			// status:"loading",
			content:"",
			data:data
		}
		await this.addNewUse(newUse,"highAndLow")
		await this.fetchData()

		const timer = setTimeout(() => {
			this.scrollToBottom()
		}, 100);
	}


	addUsecasecorrelation = async() =>{

		const tagsSelected_correlation = this.state.tagsSelected_correlation
		
		
		var csvData_sortByTag = {}
		
		//


		for (var tag in csvData[0]){
			csvData_sortByTag[tag] = {}
		}
		for (var sec in csvData){
			const data = csvData[sec]
			for (var tag in data){
				const sec_ = parseInt(sec) + 1
				csvData_sortByTag[tag][""+sec_] = data[tag].toFixed(2)
			}
		}


		var tagWithValue = {}
		var title = ""
		for (var i in tagsSelected_correlation){
			const tag = tagsSelected_correlation[i]
			title = title + "" + tag + "" + " と "
			tagWithValue[tag] = csvData_sortByTag[tag]
		}

		var correlation = 0
		if(this.state.tagsSelected_correlation.length == 2){
			// 
			var csvData_sortByTag = {}
			for (var tag in csvData[0]){
				csvData_sortByTag[tag] = []
			}
			for (var sec in csvData){
				const data = csvData[sec]
				for (var tag in data){
					if(this.state.tagsSelected_correlation.includes(tag)){
						csvData_sortByTag[tag].push(parseFloat(data[tag].toFixed(2)))
					}
				}
			}
			const a = csvData_sortByTag[this.state.tagsSelected_correlation[0]]
			const b = csvData_sortByTag[this.state.tagsSelected_correlation[1]]
			correlation = correlationCoefficient(a,b)
			console.log(correlation)
		}else{
			message.error("項目は２件が必要")
			return
		}

		tagWithValue["correlation"] = correlation.toFixed(2)

		const newUse = {
			time:0,
			title:title+"の相関性分析",
			type:"correlation",
			// status:"loading",
			content:"......Testing....",
			tags:this.state.tagsSelected_correlation,
			tagWithValue:tagWithValue,

		}
		await this.addNewUse(newUse,"correlation")
		await this.fetchData()

		const timer = setTimeout(() => {
			this.scrollToBottom()
		}, 100);
	}
	

	deleteUse = async(item) =>{
		console.log(item.id)
		this.setState({ifDownloading:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/deleteuse",{
			id :item.id,
			filename:this.props.item_selected.name,
			password:this.props.password
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifDownloading:false})
		this.fetchData()
	}

	checkReport = async(item) =>{
		console.log(item.id)
		this.setState({ifChecking:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/checkreport",{
			id :item.id,
			filename:this.props.item_selected.name,
			password:this.props.password,
			content:item.content
			
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifChecking:false})
		this.fetchData()
	}

	addNewUse = async(newUse,type) =>{
		console.log(newUse)
		this.setState({ifDownloading:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/addnewuse",{
			newUse :newUse,
			filename:this.props.item_selected.name,
			password:this.props.password
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifDownloading:false})
	}

	// pinF
	fetchData = async() =>{
		// console.log(this.props.password)
		// console.log(this.props.item_selected.name)
		this.setState({ifDownloading:true})
		
		var result = await fetch_by_json(env_serverAddress+"neuroai/fetchdata",{
			filename:this.props.item_selected.name,
			password:this.props.password
		})
		console.log(result)
		if(result && result.returnData){
			this.setState({usecase:result.returnData.history.reverse()})
			// this.setState({promptLib:result.promptForShowOfAuto})
		}
		this.setState({ifDownloading:false})
	}


	// 计算強み・弱みなど
	// pinCal
	cal_HighAndLow = () =>{
		// CSVByTag
		
		var csvData_sortByTag = {}
		for (var index in list_keiyoushi){
			var tag = list_keiyoushi[index]
			csvData_sortByTag[tag] = []
		}
		for (var sec in csvData){
			const data = csvData[sec]
			for (var index in list_keiyoushi){
				var tag = list_keiyoushi[index]
				csvData_sortByTag[tag].push(parseFloat(data[tag].toFixed(2)))
			}
		}
		console.log(csvData_sortByTag)


		var csvData_sortByTag_ava = {}
		for (var tag in csvData_sortByTag){
			var item = csvData_sortByTag[tag]
			csvData_sortByTag_ava[tag] = (item.reduce((acc, val) => acc + val, 0) / item.length).toFixed(2)
		}
		console.log(csvData_sortByTag_ava)

		const top3 = Object.entries(csvData_sortByTag_ava).sort((a, b) => b[1] - a[1]).slice(0, 3);
		const bottom3 = Object.entries(csvData_sortByTag_ava).sort((a, b) => a[1] - b[1]).slice(0, 3);

		console.log(top3)
		console.log(bottom3)

		this.setState({temp_top3:top3,temp_bottom3:bottom3})
	}


	componentDidMount() {
		this.videoRef.current.currentTime = this.state.sec; // 设置视频到指定秒数
		this.videoRef.current.pause(); // 暂停视频自动播放s
		console.log(this.props.item_selected)

		this.fetchData()

		setInterval(()=>{
			this.fetchData()
		}, 7000)
		console.clear()

	  }

	scrollToBottom = () => {
		this.consoleEndRef.current.scrollIntoView({ behavior: "smooth" });
	}


	updateReport = async() =>{
		console.log(this.state.currentReport)

		this.setState({ifDownloading:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/updatereport",{
			id :this.state.currentId,
			filename:this.props.item_selected.name,
			password:this.props.password,
			content:this.state.currentReport,
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifDownloading:false})
		this.fetchData()

	}

	render() {

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var width_leftBar = 350
		var width_middle = width - width_leftBar 

		// console.log("-----------------------------------")
		// console.log(this.props.img_history_ifGenerating)

		var ifShowImgDetail = this.state.ifShowImgDetail
		var scaleStep = 0.5

		// console.log(ifShowImgDetail)


		var csvData = this.props.item_selected.csvData
		// console.log(csvData)

		var csvData_current = []
		for (var i in csvData){
			var item = csvData[i]

			var newItem = {}
			for (let k in item){
				if(item[k] <= 0){newItem[k] = 0}
				else if(item[k] > 10){newItem[k] = 10}
				else{newItem[k] = item[k]}
				
			}
			var sec_here = 1+parseInt(i)
			newItem["name"] = ""+sec_here
			csvData_current.push(newItem)
		}

		// console.log(csvData_current)

		var tagsInUse = []
		for (let i in this.state.tagsInUse){
			var tag = this.state.tagsInUse[i]
			tagsInUse.push({
				id:i,
				tag:tag
			})
		}

		// CSVByTag
		var csvData_sortByTag = {}
		for (var tag in csvData[0]){
			csvData_sortByTag[tag] = []
		}
		for (var sec in csvData){
			const data = csvData[sec]
			for (var tag in data){
				csvData_sortByTag[tag].push(parseFloat(data[tag].toFixed(2)))
			}
		}
		const avg = arr => arr.reduce((acc, curr) => acc + curr, 0) / arr.length;
		////

		var avgAndWeight_tag = {}
		var avgList = []
		var deviList = []
		for(let tag in csvData_sortByTag){
			if(tag != "好感度" && tag != "好み／嗜好" && tag != "記憶定着度"){
				avgAndWeight_tag[tag] = {
					avg:avg(csvData_sortByTag[tag]).toFixed(2),
					devi:calculateStandardDeviation(csvData_sortByTag[tag])
				}
				avgList.push(avg(csvData_sortByTag[tag]))
				deviList.push(calculateStandardDeviation(csvData_sortByTag[tag]))
			}
			

			
		}
		// 足切り
		const W_avg = 1.0
		const W_devi = 0.5
		const cutOff = 5
		const cutOffByAvgAndDevi = sortDataByWeightedValue(avgAndWeight_tag, W_avg, W_devi,cutOff);
		console.log(cutOffByAvgAndDevi)
		
		const avgListAverage = avg(avgList)
		const deviListAverage = avg(deviList)
		// avgAndWeight_tag = addWeightToTagsOfGaussian(avgAndWeight_tag)
		// avgAndWeight_tag = addWeightToTagsOfLine(avgAndWeight_tag)
		// avgAndWeight_tag = addWeightToTagsOfCurved(avgAndWeight_tag)
		// avgAndWeight_tag = addWeightToTagsOfGaussian2(avgAndWeight_tag)

		// console.log(avgAndWeight_tag)
		// console.log(avgList)
		// console.log(avgListAverage)

		// pinAuto
		// ----------------------------------------------------------------
		var list_soukann_koukanndo = []
		var list_soukann_koukanndo_heighest3 = []
		var list_soukann_koukanndo_lowest3 = []
		for(let tag in cutOffByAvgAndDevi){
			if(tag != "記憶定着度" && tag != "試用意向" && tag != "愛用意向" && tag != "購買意向" && tag != "広告効果" && tag != "好感度" && tag != "好み／嗜好"){
				var correlation = ""
				var tag1 = csvData_sortByTag["好感度"]
				var tag2 = csvData_sortByTag[tag]

				//true
				if(true){ 
					correlation = (correlationCoefficient(tag1,tag2)).toFixed(2) 
					list_soukann_koukanndo.push({tag:tag,value:correlation})
					list_soukann_koukanndo_heighest3 = findTopThree(sortByValue(list_soukann_koukanndo)) 
					list_soukann_koukanndo_lowest3 = findLastThree(sortByValue(list_soukann_koukanndo)) 
				}
			}
		}
		// console.log(list_soukann_koukanndo)

		var list_soukann_kioku = []
		var list_soukann_kioku_heighest3 = []
		var list_soukann_kioku_lowest3 = []
		for(let tag in cutOffByAvgAndDevi){
			if(tag != "記憶定着度" && tag != "試用意向" && tag != "愛用意向" && tag != "購買意向" && tag != "広告効果" && tag != "好感度" && tag != "好み／嗜好"){
				var correlation = ""
				var tag1 = csvData_sortByTag["記憶定着度"]
				var tag2 = csvData_sortByTag[tag]
				if(true){
					correlation = (correlationCoefficient(tag1,tag2)).toFixed(2)
					list_soukann_kioku.push({tag:tag,value:correlation})
					list_soukann_kioku_heighest3 = findTopThree(sortByValue(list_soukann_kioku))
					list_soukann_kioku_lowest3 = findLastThree(sortByValue(list_soukann_kioku))
				}
				}
				
		}
		// console.log(list_soukann_kioku)


		var list_soukann_konomi = []
		var list_soukann_konomi_heighest3 = []
		var list_soukann_konomi_lowest3 = []
		for(let tag in cutOffByAvgAndDevi){
			if(tag != "記憶定着度" && tag != "試用意向" && tag != "愛用意向" && tag != "購買意向" && tag != "広告効果" && tag != "好感度" && tag != "好み／嗜好"){
				var correlation = ""
				var tag1 = csvData_sortByTag["好み／嗜好"]
				var tag2 = csvData_sortByTag[tag]
				if(true){
					correlation = (correlationCoefficient(tag1,tag2)).toFixed(2)
					list_soukann_konomi.push({tag:tag,value:correlation})
					list_soukann_konomi_heighest3 = findTopThree(sortByValue(list_soukann_konomi))
					list_soukann_konomi_lowest3 = findLastThree(sortByValue(list_soukann_konomi))
				}
			}
		}
		// console.log(list_soukann_konomi)
		const tagList = ["好感度","記憶定着度","好み／嗜好"]
		var tagWithValue = {}
		for (var i in tagList){
			const tag = tagList[i]
			tagWithValue[tag] = csvData_sortByTag[tag]
		}	

		console.log(tagWithValue)

		//pin1
		var decreasingSecondsList = []
		var decreasingSecondsListForGPT = []
		for(let index in tagList){
			const tag = tagList[index]
			// console.log(tagWithValue[tag])
			// ------------------------------
			// 自动设置windowSize
			const windowSize = tellWindowSize(tagWithValue[tag])
			// console.log(windowSize)
			// 使用移动平均
			var temp = movingAverageFilter(tagWithValue[tag], windowSize)
			console.log(temp)
			// 选取第一个波谷(暂定)
			var temp2 = findWaveTrough(temp, windowSize)
			console.log(temp2)

			decreasingSecondsList.push({
				tag:tag,
				data:temp2, 
			})
			decreasingSecondsListForGPT.push({
				tag:tag,
				data:temp2
			})

	
		}
		console.log(decreasingSecondsList)
		



		var csvData_sortByTag_ava = {}
		for (var tag in csvData_sortByTag){
			var item = csvData_sortByTag[tag]
			csvData_sortByTag_ava[tag] = (item.reduce((acc, val) => acc + val, 0) / item.length).toFixed(2)
		}
		// console.log(csvData_sortByTag_ava)

		const overallScore_koukando = 9
		const overallScore_kioku = 1
		const overallScore_konomi = 4

		var csvData_sortByTagWithSecond = {
			"好感度":[],
			"記憶定着度":[],
			"好み／嗜好":[],
		}
		for (i in csvData_sortByTag["好感度"]){
			csvData_sortByTagWithSecond["好感度"].push({second:i,value:csvData_sortByTag["好感度"][i]})
		}
		for (i in csvData_sortByTag["記憶定着度"]){
			csvData_sortByTagWithSecond["記憶定着度"].push({second:i,value:csvData_sortByTag["記憶定着度"][i]})
		}
		for (i in csvData_sortByTag["好み／嗜好"]){
			csvData_sortByTagWithSecond["好み／嗜好"].push({second:i,value:csvData_sortByTag["好み／嗜好"][i]})
		}


		var content = ""
		content = content + "■現状分析\n"

		content = content + "\n"
		content = content + "- 好感度に対する相関が高い印象項目、相関が低い印象項目\n"
		for (var i in list_soukann_koukanndo_heighest3){
			const item = list_soukann_koukanndo_heighest3[i]
			content = content + "   - 好感度 <-> "+item["tag"]+" ("+item["value"]+")\n"
		}
		content = content + "\n"
		for (var i in list_soukann_koukanndo_lowest3){
			const item = list_soukann_koukanndo_lowest3.reverse()[i]
			content = content + "   - 好感度 <-> "+item["tag"]+" ("+item["value"]+")\n"
		}

		content = content + "\n"
		content = content + "- 記憶定着度に対する相関が高い印象項目、相関が低い印象項目\n"
		for (var i in list_soukann_kioku_heighest3){
			const item = list_soukann_kioku_heighest3[i]
			content = content + "   - 記憶定着度 <-> "+item["tag"]+" ("+item["value"]+")\n"
		}
		content = content + "\n"
		for (var i in list_soukann_kioku_lowest3){
			const item = list_soukann_kioku_lowest3.reverse()[i]
			content = content + "   - 記憶定着度 <-> "+item["tag"]+" ("+item["value"]+")\n"
		}

		content = content + "\n"
		content = content + "- 好み／嗜好に対する相関が高い印象項目、相関が低い印象項目\n"
		for (var i in list_soukann_konomi_heighest3){
			const item = list_soukann_konomi_heighest3[i]
			content = content + "   - 好み／嗜好 <-> "+item["tag"]+" ("+item["value"]+")\n"
		}
		content = content + "\n"
		for (var i in list_soukann_konomi_lowest3){
			const item = list_soukann_konomi_lowest3.reverse()[i]
			content = content + "   - 好み／嗜好 <-> "+item["tag"]+" ("+item["value"]+")\n"
		}



		// - 好感度に対する相関が高い印象項目、相関が低い印象項目
		//   高い：
		//   1. 好感度 <-> 滑稽 (0.88)
		//   2. 好感度 <-> 騒がしい (0.86)
		//   3. 好感度 <-> 安っぽい (0.82)"

		var source = {
			"content":content,
			"data":{
				"decreasedPoint":decreasingSecondsList,
				// "increasedPoint":increasingSecondsList,
			},
			"infoForGPT":{
				"average":{
					"thisVideo":{
						"好感度":csvData_sortByTag_ava["好感度"],
						"記憶定着度":csvData_sortByTag_ava["記憶定着度"],
						"好み／嗜好":csvData_sortByTag_ava["好み／嗜好"],
					},

					// 从数据库中抓取到的平均值,用来做对比
					"overall":{
						"好感度":overallScore_koukando,
						"記憶定着度":overallScore_kioku,
						"好み／嗜好":overallScore_konomi,
					}
				},
				"correlation" : {
					"好感度": {
						"highest":list_soukann_koukanndo_heighest3,
						"lowest":list_soukann_koukanndo_lowest3
					},
					"記憶定着度": {
						"highest":list_soukann_kioku_heighest3,
						"lowest":list_soukann_kioku_lowest3
					},
					"好み／嗜好": {
						"highest":list_soukann_konomi_heighest3,
						"lowest":list_soukann_konomi_lowest3
					},
				},
				"decreasedPoint":decreasingSecondsListForGPT,
				// "timeSeries" : {
				// 	"好感度": csvData_sortByTagWithSecond["好感度"],
				// 	"記憶定着度": csvData_sortByTagWithSecond["記憶定着度"],
				// 	"好み／嗜好": csvData_sortByTagWithSecond["好み／嗜好"],
				// },
			}
		}
		console.log(source)
		// ----------------------------------------------------------------



		// 相関度係数の計算
		var correlation = 0
		if(this.state.tagsSelected_correlation.length == 2){
			// 
			var csvData_sortByTag_correlation = {}
			for (var tag in csvData[0]){
				csvData_sortByTag_correlation[tag] = []
			}
			for (var sec in csvData){
				const data = csvData[sec]
				for (var tag in data){
					if(this.state.tagsSelected_correlation.includes(tag)){
						csvData_sortByTag_correlation[tag].push(parseFloat(data[tag].toFixed(2)))
					}
				}
			}
			const a = csvData_sortByTag_correlation[this.state.tagsSelected_correlation[0]]
			const b = csvData_sortByTag_correlation[this.state.tagsSelected_correlation[1]]
			correlation = correlationCoefficient(a,b)
			// console.log(correlation)
		}

		// pinReturn
		return(

			<div style={{width:width,height:height,position:"relative",left:0,top:0}}
				>

				{/* <div style={{
					width:width,height:50,
					position:"fixed",
					left:0,top:0,
					backgroundColor:"#f1f1f1"
					}}>
					<div style={{paddingLeft:0,fontSize:"20px",fontWeight:"bold",height:50,backgroundColor:"#ffffff"}}>
						<img src = {logo_dplanner} style={{width:210,marginLeft:-0,paddingTop:5}}/>
					</div>
				</div> */}
				
				{/* ---------------------------------------------------- */}

				
				<div style={{width:width,height:height,position:"absolute",
					left:0,top:0}}
					>
					{/* pinLeft */}
					<div 
						style={{
						width:width_leftBar,			
						backgroundColor:"#f1f1f1",
						height:height,
						float:"left",
						//padding:20,
						
						}}>
						<div style={{
							width:width_leftBar-40,
							height:200,
							// backgroundColor:"#d8d8d8",
							marginLeft:20,
							marginTop:30,
							borderRadius:15,
							textAlign:"center",
							display: "grid",
							placeItems: "center",
							fontSize:30,fontWeight:"bold",
							color:"#f1f1f1",
							// overflow:"hidden",
							// borderRight:15
							}}>
							{/* コンテンツ閲覧 */}
							<video width={width_leftBar-40} height={200} controls>
								<source src={this.props.item_selected.video} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>


						
					</div>
					
					{/* ---------------------------------------------------- */}

					{/* pinRight */}
					<div 
						style={{
						width:width_middle,	
						// borderRadius:15,		
						// backgroundColor:"#ffffff",
						height:height,
						float:"left",
						//padding:20,
						overflowY:"scroll"
						}}>
						<div 
							style={{
							width:width_middle,	
							// borderRadius:15,		
							// backgroundColor:"#ffffff",
							height:"auto",
							// float:"left",
							//padding:20,
							}}>
							
							{/* <div style={{height:10}}/>
							<div style={{height:2,width:width_middle-40,marginLeft:20}}><Divider/></div> */}
							{/* <div style={{marginLeft:20,height:40,width:width_middle-40,position:"relative"}}>
								<h2 style={{marginTop:5,position:"absolute",left:0,top:0}}>まとめ</h2>
								<Button 
									style={{position:"absolute",right:0,top:5}} size="small" type="link"
									onClick = {()=>{
										this.setState({ifShowGraph:!this.state.ifShowGraph})
									}}
									>
									
									{this.state.ifShowGraph? ("隠す"):("展示する")}
								</Button>
							</div> */}

							<div style={{
								backgroundColor:"#ffffff",//"#f5f5f5",
								marginLeft:20,
								width:width_middle-40,
								// height:this.state.ifShowGraph? height/2+140:50,	
								borderRadius:15,
								overflow:"hidden",
								transition: 'width 1s ease, height 1s ease',
								boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)" /* 添加阴影 */
								}}>
								{/* <div style={{height:20}}/> */}


								{/* <Space style={{marginTop:15,marginBottom:15 ,marginLeft:10,height:20}}>
									<Button size="small" type="text" 
										style={{color:"#1EE486",fontWeight:"bold"}}
										onClick = {()=>{
											
										}}
										icon={<CheckCircleOutlined />}
										// icon={<ImageComponent src={emoji_graph_a} width={20} height={20}/>}
										>
										XXX分析済み
									</Button>

									<Button size="small" type="text" 
										style={{color:"#1EE486",fontWeight:"bold"}}
										onClick = {()=>{
											
										}}
										icon={<CheckCircleOutlined />}
										// icon={<ImageComponent src={emoji_graph_a} width={20} height={20}/>}
										>
										XXX分析済み
									</Button>
									
									
								</Space> */}

								{/* <div style={{height:2,width:width_middle-80,marginLeft:20,marginTop:-20}}><Divider/></div>
								<div style={{height:20}}/>
								<div style={{height:2,width:width_middle-80,marginLeft:20}}>
									<Segmented style={{}} options={["ポイント", '課題と解決策']} block />
								</div> */}
								

								{/* <div style={{overflowX:"scroll",height:40,marginLeft:20,width:width_middle-80}}>
									<Space size={[0, 8]} >
										{list_chikaku.map((tag)=>{
											var color=false
											if(this.state.tagsInUse.includes(tag)){
												color="#a5a5a5"
											}
											return(
												<Tag
													onClick={()=>{this.handleTag(tag)}} 
													color={color &&(color)}>{tag}</Tag>
											)
										})}	
									</Space>
								</div> */}

								{/* <LineChart
									style={{
										marginLeft:-50,
										width:width_middle+20,
										height:height/2,
										transition: 'width 1s ease, height 1s ease',
									}}
									onMouseMove = {this.handleMove}
									onClick={()=>{this.setState({ifShowBigGraph:!this.state.ifShowBigGraph})}}
									width={width_middle+20}
									height={height/2}
									data={csvData_current}
									margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
									>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis domain={[0, 10]} />
									
									<Tooltip content={
										<CustomTooltip 
											tagsInUse = {tagsInUse}
											setSec = {this.setSec}
											captureThumbnail = {this.captureThumbnail}
											/>
										} 
										/>
									<Legend />
									{tagsInUse.map((item)=>{
										return(
											<Line type="monotone" dataKey={item.tag} stroke={colorList[item.id]} strokeWidth={2} activeDot={{ r: 8 }} />
										)
									})}
									
								</LineChart> */}
								{/* <div style={{height:10}}/> */}
							</div>


							<div style={{height:10}}/>
							<div style={{height:2,width:width_middle-40,marginLeft:20}}><Divider/></div>
							<div style={{height:40,width:width_middle-40,marginLeft:20,position:"relative"}}>
								<h2 style={{marginTop:5,position:"absolute",left:0,top:0}}>新規分析</h2>
								<Button style={{position:"absolute",right:100,top:5}} size="small" type="link" onClick={()=>{this.setState({ifShowCSVDetail:true})}}>$dev:CSV</Button>
								<Button style={{position:"absolute",right:0,top:5}} size="small" type="link">すべての機能</Button>
							</div>
							
							{/* <div style={{height:10}}/> */}
							<div style={{
								overflowX:"auto",
								height:168,
								padding:10,
								marginLeft:10,
								width:width_middle-50,
								// marginTop:10
								}}>
								<Space size={[0, 8]} >
									<Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowCreateNewUsecase_auto:true})}}
										>
											<Space>
												<ImageComponent src={emoji_stethoscope} width={72} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>自動分析</div>
													<div style={{
														// backgroundColor:"#ff0000",
														height:40,fontSize:13,width:170,
														fontWeight:"normal",textAlign:"left",
														wordWrap:"break-word",
														whiteSpace:"pre-wrap"
													}}>コンテンツの課題を抽出し、改善策を提供する.</div>
												</div>
											</Space>
									</Button>
									<div style={{width:10}}/>
									{/* <Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											fontWeight:"bold",
											borderRadius:15,
											}}
											onClick = {()=>{

												this.setState({ifShowCreateNewUsecase:true})
												
												// this.addUsecasecorrelation()
											}}
											>
											<Space>
												<ImageComponent src={emoji_graph_a} width={70} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>相関性分析</div>
													<div style={{fontSize:13,width:170,fontWeight:"normal",textAlign:"left"}}>選択した項目の相関性を分析する</div>
												</div>
											</Space>
									</Button>
									
									<div style={{width:10}}/> */}
									{/* <Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{
												this.setState({ifShowCreateNewUsecase_HighAndLow:true})
												// 计算強み・弱みなど
												this.cal_HighAndLow()
											
											}}
										>
											<Space>
												<ImageComponent src={emoji_graph_b} width={70} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>特徴分析</div>
													<div style={{fontSize:13,width:170,fontWeight:"normal",textAlign:"left"}}>動画の特徴を分析する</div>
												</div>
											</Space>
									</Button>
									
									<div style={{width:10}}/>
									<Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowCreateNewUsecase_Problem:true})}}
										>
											<Space>
												<ImageComponent src={emoji_stethoscope} width={70} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>課題分析</div>
													<div style={{fontSize:13,width:170,fontWeight:"normal",textAlign:"left"}}>不足や改善点を明らかにする</div>
												</div>
											</Space>
									</Button>
									<div style={{width:10}}/>
									<Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowCreateNewUsecase_Vision:true})}}
										>
											<Space>
												<ImageComponent src={emoji_zoom} width={70} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>特定の画面を深掘</div>
													<div style={{fontSize:13,width:170,fontWeight:"normal",textAlign:"left"}}>定性的に画面の内容を分析する</div>
												</div>
											</Space>
									</Button>

									<div style={{width:10}}/>
									<Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowCreateNewUsecase_Vision:true})}}
										>
											<Space>
												<ImageComponent src={emoji_zoom} width={70} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>特定の項目を分析</div>
													<div style={{fontSize:13,width:170,fontWeight:"normal",textAlign:"left"}}>スコアが上下する要因と理由を、、</div>
												</div>
											</Space>
									</Button> */}
									
								</Space>
							</div>
							


							
							{this.state.ifDownloading && this.state.usecase.length == 0 && (
								<div style={{height:100,width:100,marginLeft:20,fontSize:30,}}>
									<LoadingOutlined/>
								</div>
							)}
							
							{this.state.usecase.length != 0 ? (
								<div style={{marginLeft:20}}>
									<div style={{height:10}}/>
									<div style={{height:2,width:width_middle-40,}}><Divider/></div>
									<div style={{height:40,width:width_middle-40,position:"relative"}}>
										<h2 style={{marginTop:5,position:"absolute",left:0,top:0}}>分析履歴</h2>
										<Button style={{position:"absolute",right:0,top:5}} size="small" type="link">すべての機能</Button>
									</div>
									{/* ------------------------------------ */}
									{/* 结尾定位用标签 */}
									<div
										ref={this.consoleEndRef}
										className="console-end"
										style={{ display: "block" }}
									></div>
									{/* ------------------------------------ */}
									{this.state.usecase.map((item)=>{

										const timeHere = convertToDateTimeFormat(item.id)
										// console.log(timeHere)
										if(item.type==="correlation"){

											// console.log(item)
											const tagHere = item.tags
											// console.log(tagHere)


											// 准备Advie
											//pin
											var c_dict1 = {}
											var c_dict2 = {}
											var tagList_advice = []
											const tag1 = tagHere[0]
											const tag2 = tagHere[1]
											if(tag1){
												for (var index in list_chikaku){
													const tag = list_chikaku[index]
													const correlation_here = correlationCoefficient(csvData_sortByTag[tag1],csvData_sortByTag[tag])
													c_dict1[tag] = correlation_here.toFixed(2)
												}
												// console.log(c_dict1)
												var topThreeKeys_1 = Object.keys(c_dict1).sort((a, b) => c_dict1[b] - c_dict1[a]).slice(0, 4);
												// console.log(topThreeKeys_1)
												for (var index in topThreeKeys_1)
													if(index!=0){
														tagList_advice.push({
															title:""+tag1+"と"+topThreeKeys_1[index]+"を分析 ("+c_dict1[topThreeKeys_1[index]]+")"
															
														})
													}
													
											}
											if(tag2){
												for (var index in list_chikaku){
													const tag = list_chikaku[index]
													const correlation_here = correlationCoefficient(csvData_sortByTag[tag2],csvData_sortByTag[tag])
													c_dict1[tag] = correlation_here.toFixed(2)
												}
												// console.log(c_dict1)
												var topThreeKeys_1 = Object.keys(c_dict1).sort((a, b) => c_dict1[b] - c_dict1[a]).slice(0, 4);
												// console.log(topThreeKeys_1)
												for (var index in topThreeKeys_1)
													if(index!=0){
														tagList_advice.push({
															title:""+tag2+"と"+topThreeKeys_1[index]+"を分析 ("+c_dict1[topThreeKeys_1[index]]+")"
															
														})
													}
													
											}
											// console.log(tagList_advice)
											




											var csvData_here = []

											var index = 0
											var tagList = []
											for (let tag in csvData[0]){
												if(tagHere.includes(tag)){
													tagList.push({id:index,tag:tag})
													index = index + 1
												}

											}


											for (var i in csvData){
												var tags = csvData[i]

												var newItem = {}
												for (let tag in tags){
													if(tagHere.includes(tag)){
														// console.log(k)
														if(tags[tag] <= 0){newItem[tag] = 0}
														else if(tags[tag] > 10){
															newItem[tag] = 10
														}
														else{
															newItem[tag] = tags[tag]
														}
													}
													
													
												}
												var sec_here = 1+parseInt(i)
												newItem["name"] = ""+sec_here
												csvData_here.push(newItem)
											}
											// console.log(csvData_here)	

											return(
												<div style={{marginBottom:30,}}>
													<div style={{
														backgroundColor:"#ffffff",//"#f1f1f1",//"#f5f5f5",
														// marginLeft:20,
														width:width_middle-40,	
														height:"auto",
														borderRadius:15,
														position:"relative",
														boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
														}}>
														{/* <div style={{height:20}}/> */}
														
														<Space style={{marginTop:0,marginLeft:20}}>
															<ImageComponent src={emoji_graph_a} width={20} height={20}/>
															<h3 >相関性分析</h3>
															<div style={{width:2}}/>
															{item.status === "loading" && (
																<Button 
																	type="link"
																	icon={<LoadingOutlined/>}
																	// style={{fontSize:15,color:"#d8d8d8"}}
																	>
																	{item.title}
																</Button>
													
																

															)}
															{item.status === "checking" && (
																<div>
																	<Button size="small" type="link" 
																		onClick = {()=>{
																			
																		}}
																		icon={<LoadingOutlined/>}
																		>
																		チェック中
																	</Button>
																</div>
															)}

															{item.status === "done" && (
																item.score &&(
																	<div>
																		<Button size="small" type="link" 
																			onClick = {()=>{
																				
																			}}
																			// icon={<RedoOutlined />}
																			>
																			$dev-score：{item.score}
																		</Button>
																	</div>
																)
															)}

															{item.status === "done" && (
																item.score < 60 &&(
																	<div>
																		<Button size="small" type="link" 
																			onClick = {()=>{
																				
																			}}
																			icon={<RedoOutlined />}
																			>
																			分析をやり直す
																		</Button>
																	</div>
																)
															)}
															
															{(item.status === "done" || item.status === "checking" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<FileTextOutlined/>}
																		>
																		PDFに出力
																	</Button>
																</div>
															)}
														
														</Space>
														<Popconfirm
															title="Delete the task"
															description="Are you sure to delete this task?"
															onConfirm={()=>{this.deleteUse(item)}}
															// onCancel={cancel}
															okText="Yes"
															cancelText="No"
															>
															<Button
																style={{position:"absolute",right:10,top:15}} 
																size="small" type="link" danger
																onClick = {()=>{
																	
																}}
																icon={<DeleteOutlined />}
																>
																削除
															</Button>
																
														</Popconfirm>
														{(item.status === "done" || item.status === "checking" )&&(
															<div>
																
																<div style={{
																	width:width_middle-80,
																	height:310,
																	// backgroundColor:"#ffffff",
																	marginTop:30,
																	marginLeft:20,
																	borderRadius:15,
																	textAlign:"center",
																	display: "grid",
																	placeItems: "center",
																	// fontSize:40,fontWeight:"bold",
																	color:"#f1f1f1",
																	}}>
																	<LineChart
																		style={{
																			marginTop:10,
																			marginLeft:-50,
																			// width:width_middle-80,
																			// height:290,
																			// transition: 'width 1s ease, height 1s ease',
																		}}
																		// onMouseMove = {this.handleMove}
																		width={width_middle-80}
																		height={290}
																		data={csvData_here}
																		margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
																		>
																		<CartesianGrid strokeDasharray="3 3" />
																		<XAxis dataKey="name" />
																		<YAxis domain={[0, 10]} />
																		
																		{/* <Tooltip content={
																			<CustomTooltip 
																				tagsInUse = {tagsInUse}
																				setSec = {this.setSec}
																				captureThumbnail = {this.captureThumbnail}
																				/>
																			} 
																			/> */}
																		<Legend />
																		{tagList.map((tag)=>{
																			return(
																				<Line type="monotone" dataKey={tag.tag} stroke={colorList[tag.id]} strokeWidth={2} activeDot={{ r: 8 }} />
																			)
																		})}
																		
																	</LineChart>
																	{/* グラフ */}
																</div>
																<div style={{marginLeft:60,marginRight:60}}><Divider/></div>
																<div style={{marginLeft:65,marginRight:65,position:"relative"}}>
																	<div style={{marginRight:300}}>
																		<div style={{position:"absolute",right:0,top:60,
																			width:290,
																			// height:200,
																			// padding:10,
																			backgroundColor:"#f1f1f1",
																			borderRadius:15
																			}}>
																			
																			<Space 
																				direction="vertical" 
																				style={{
																				marginLeft:20,
																				marginTop:10
																				}}>
																				<h3 style={{marginLeft:5}}>アドバイス</h3>
																				<Space direction="vertical" >
																					{tagList_advice.map((advice)=>{
																						return(
																							<Tooltip_antd title={advice.title}>
																								<Button 
																									style={{width:260,overflow:"hidden",textAlign:"left"}}
																									size="small" type="link"
																									onClick = {()=>{
																										
																									}}
																									icon={<BulbOutlined/>}
																									>
																									{advice.title}
																								</Button>
																							</Tooltip_antd>
																							
																						)
																						
																					})}
																					<div style={{height:5}}/>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						m分s秒の画面を深掘
																					</Button>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						m分s秒の画面を深掘
																					</Button>
																					
																					<div style={{height:5}}/>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						XXXXのスコアを向上させたい
																					</Button>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						XXXXのスコアを向上させたい
																					</Button>
																				</Space>

																				
																			</Space>
																			<div style={{height:30}}/>
																		</div>
																		<h1 style={{fontSize:30}}>{item.title}</h1>
																		<pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																			{item.content}
																		</pre>
																	</div>
																	
																</div>
																<div style={{marginLeft:60,marginRight:60}}><Divider/></div>
																<div style={{marginLeft:60,marginRight:60,marginTop:-20,color:"#d8d8d8"}}>{timeHere}</div>
																
																<div style={{height:80}}/>
															</div>
														)}
													</div>
													
												</div>
											)	
										}


										if(item.type==="highAndLow"){

											

											return(
												<div style={{marginBottom:30,}}>
													<div style={{
														backgroundColor:"#ffffff",//"#f1f1f1",//"#f5f5f5",
														// marginLeft:20,
														width:width_middle-40,	
														height:"auto",
														borderRadius:15,
														position:"relative",
														boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
														}}>
														{/* <div style={{height:20}}/> */}
														
														<Space style={{marginTop:0,marginLeft:20}}>
															<ImageComponent src={emoji_graph_b} width={20} height={20}/>
															<h3 >特徴分析</h3>
															<div style={{width:2}}/>
															{item.status === "loading" && (
																<Button 
																	type="link"
																	icon={<LoadingOutlined/>}
																	// style={{fontSize:15,color:"#d8d8d8"}}
																	>
																	{item.title}
																</Button>
													
															)}
															

															
															{(item.status === "done" || item.status === "checking" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<FileTextOutlined/>}
																		>
																		PDFに出力
																	</Button>
																</div>
															)}
														
														</Space>
														<Popconfirm
															title="Delete the task"
															description="Are you sure to delete this task?"
															onConfirm={()=>{this.deleteUse(item)}}
															// onCancel={cancel}
															okText="Yes"
															cancelText="No"
															>
															<Button
																style={{position:"absolute",right:10,top:15}} 
																size="small" type="link" danger
																onClick = {()=>{
																	
																}}
																icon={<DeleteOutlined />}
																>
																削除
															</Button>
																
														</Popconfirm>
														{(item.status === "done" || item.status === "checking" )&&(
															<div>
																
																
																
																<div style={{marginLeft:65,marginRight:65,position:"relative"}}>
																	<div style={{marginRight:300}}>
																		<div style={{position:"absolute",right:0,top:60,
																			width:290,
																			// height:200,
																			// padding:10,
																			backgroundColor:"#f1f1f1",
																			borderRadius:15
																			}}>
																			
																			<Space 
																				direction="vertical" 
																				style={{
																				marginLeft:20,
																				marginTop:10
																				}}>
																				<h3 style={{marginLeft:5}}>アドバイス</h3>
																				<Space direction="vertical" >
																					
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OO分OO秒の画面を深掘
																					</Button>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OO分OO秒の画面を深掘
																					</Button>
																				</Space>

																				
																			</Space>
																			<div style={{height:30}}/>
																		</div>
																		<h1 style={{fontSize:30}}>{item.title}</h1>
																		<pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																			{item.content}
																		</pre>
																	</div>
																	
																</div>
																<div style={{marginLeft:60,marginRight:60}}><Divider/></div>
																<div style={{marginLeft:60,marginRight:60,marginTop:-20,color:"#d8d8d8"}}>{timeHere}</div>
																
																<div style={{height:80}}/>
															</div>
														)}
													</div>
													
												</div>
											)	
										}

										if(item.type==="vision"){

											

											return(
												<div style={{marginBottom:30,}}>
													<div style={{
														backgroundColor:"#ffffff",//"#f1f1f1",//"#f5f5f5",
														// marginLeft:20,
														width:width_middle-40,	
														height:"auto",
														borderRadius:15,
														position:"relative",
														boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
														}}>
														{/* <div style={{height:20}}/> */}
														
														<Space style={{marginTop:0,marginLeft:20}}>
															<ImageComponent src={emoji_zoom} width={20} height={20}/>
															<h3 >特定画面の分析</h3>
															<div style={{width:2}}/>
															{item.status === "loading" && (
																<Button 
																	type="link"
																	icon={<LoadingOutlined/>}
																	// style={{fontSize:15,color:"#d8d8d8"}}
																	>
																	{item.title}
																</Button>
													
															)}
															

															
															{(item.status === "done2" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<FileTextOutlined/>}
																		>
																		PDFに出力
																	</Button>
																</div>
															)}
															{(item.status === "done1" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<LoadingOutlined/>}
																		>
																		分析中
																	</Button>
																</div>
															)}
														
														</Space>
														
														<Popconfirm
															title="Delete the task"
															description="Are you sure to delete this task?"
															onConfirm={()=>{this.deleteUse(item)}}
															// onCancel={cancel}
															okText="Yes"
															cancelText="No"
															>
															<Button
																style={{position:"absolute",right:10,top:15}} 
																size="small" type="link" danger
																onClick = {()=>{
																	
																}}
																icon={<DeleteOutlined />}
																>
																削除
															</Button>
																
														</Popconfirm>
														{(item.status === "done1" || item.status === "done2" )&&(
															<div>
																<div style={{marginLeft:65,marginRight:65,position:"relative"}}>
																	<div style={{marginRight:300}}>
																		<div style={{position:"absolute",right:0,top:60,
																			width:290,
																			// height:200,
																			// padding:10,
																			backgroundColor:"#f1f1f1",
																			borderRadius:15
																			}}>
																			
																			<Space 
																				direction="vertical" 
																				style={{
																				marginLeft:20,
																				marginTop:10
																				}}>
																				<h3 style={{marginLeft:5}}>アドバイス</h3>
																				<Space direction="vertical" >
																					
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OOOOOOO
																					</Button>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OOOOOOO
																					</Button>
																				</Space>

																				
																			</Space>
																			<div style={{height:30}}/>
																		</div>
																		<h1 style={{fontSize:30}}>{item.title}</h1>
																		<div style={{color:"#ff0000"}}>dev用画像です：</div>
																		<Image src={item.img} style={{width:(width-550)/3}}/>
																		{item.status === "done2"? (
																			<pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																				{item.content}
																			</pre>
																		):(
																			<pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																				{item.img}
																			</pre>
																		)}
																		
																	</div>
																	
																</div>
																<div style={{marginLeft:60,marginRight:60}}><Divider/></div>
																<div style={{marginLeft:60,marginRight:60,marginTop:-20,color:"#d8d8d8"}}>{timeHere}</div>
																
																<div style={{height:80}}/>
															</div>
														)}
													</div>
													
												</div>
											)	
										}
										if(item.type==="kadai-m"){

											var ifImgList = false
											try{
												
												if(item.img.length <= 3){ifImgList = true}
												
											}catch(error){
												ifImgList = false
											}
											// console.log("-----ifImgList-----")
											// console.log(ifImgList)
											// console.log(item.img)

											return(
												<div style={{marginBottom:30,}}>
													<div style={{
														backgroundColor:"#ffffff",//"#f1f1f1",//"#f5f5f5",
														// marginLeft:20,
														width:width_middle-40,	
														height:"auto",
														borderRadius:15,
														position:"relative",
														boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
														}}>
														{/* <div style={{height:20}}/> */}
														
														<Space style={{marginTop:0,marginLeft:20}}>
															<ImageComponent src={emoji_stethoscope} width={21} height={20}/>
															<h3 >{item.title}</h3>
															<div style={{width:2}}/>
															{item.status === "loading" && (
																<Button 
																	type="link"
																	icon={<LoadingOutlined/>}
																	// style={{fontSize:15,color:"#d8d8d8"}}
																	>
																	{item.title}
																</Button>
													
															)}
															

															
															{(item.status === "done2" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<FileTextOutlined/>}
																		>
																		PDFに出力
																	</Button>
																</div>
															)}
															{(item.status === "done1" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<LoadingOutlined/>}
																		>
																		分析中
																	</Button>
																</div>
															)}
														
														</Space>
														
														<Popconfirm
															title="Delete the task"
															description="Are you sure to delete this task?"
															onConfirm={()=>{this.deleteUse(item)}}
															// onCancel={cancel}
															okText="Yes"
															cancelText="No"
															>
															<Button
																style={{position:"absolute",right:10,top:15}} 
																size="small" type="link" danger
																onClick = {()=>{
																	
																}}
																icon={<DeleteOutlined />}
																>
																削除
															</Button>
																
														</Popconfirm>
														{(item.status === "done1" || item.status === "done2" )&&(
															<div>
																<div style={{marginLeft:65,marginRight:65,position:"relative"}}>
																	{/* <div style={{marginRight:300}}> */}
																	<div style={{marginRight:0}}>
																		{/* <div style={{position:"absolute",right:0,top:60,
																			width:290,
																			// height:200,
																			// padding:10,
																			backgroundColor:"#f1f1f1",
																			borderRadius:15
																			}}>
																			
																			<Space 
																				direction="vertical" 
																				style={{
																				marginLeft:20,
																				marginTop:10
																				}}>
																				<h3 style={{marginLeft:5}}>アドバイス</h3>
																				<Space direction="vertical" >
																					
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OOOOOOO
																					</Button>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OOOOOOO
																					</Button>
																				</Space>

																				
																			</Space>
																			<div style={{height:30}}/>
																		</div> */}
																		<h1 style={{fontSize:30}}>{item.title}</h1>
																		{/* {item.img.map((img)=>{
																			return(<img src={img} style={{width:width-520}}/>)
																		})} */}
																		<div style={{color:"#ff0000"}}>dev用画像です：</div>
																		<Space>
																			{ifImgList ? (
																				item.img.map((urlHere)=>{
																					return(
																						
																						<div>
																							<Image 
																								src={urlHere} style={{width:(width-520)/3,marginBottom:20}}/>
																						</div>
																					)
																				})
																			): (
																				<img src={item.img} style={{width:width-520}}/>
																			)}
																		</Space>
																		
																		{item.status === "done2"? (
																			<div>
																				<pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																					{item.content}
																				</pre>
																				{/* <Button size="large" type="primary"
																					onClick = {()=>{
																						this.addUsecaseKadai_requireAdvice(item)
																					}}
																					icon={<BulbOutlined/>}
																					>
																					解決案を出す(dev:Simple)
																				</Button> */}
																			</div>
																		):(
																			""
																			// <pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																			// 	{item.img}
																			// </pre>
																		)}
																		
																		
																	</div>
																	
																</div>
																<div style={{marginLeft:60,marginRight:60}}><Divider/></div>
																<div style={{marginLeft:60,marginRight:60,marginTop:-20,color:"#d8d8d8"}}>{timeHere}</div>
																
																<div style={{height:80}}/>
															</div>
														)}
													</div>
													
												</div>
											)	
										}


										if(item.type==="auto"){

											var ifImgList = false
											try{
												
												if(item.img.length <= 3){ifImgList = true}
												
											}catch(error){
												ifImgList = false
											}
											var score = false
											if(item.score){

												score = 0
												try{
													score = JSON.parse(item.score)["score"]
												}catch(error){}
												try{
													score = JSON.parse(item.score.split("```")[1].split("json")[1])["score"]
												}catch(error){}
												
											}
											// console.log(score)
											
						
											return(
												<div style={{marginBottom:30,}}>
													<div style={{
														backgroundColor:"#ffffff",//"#f1f1f1",//"#f5f5f5",
														// marginLeft:20,
														width:width_middle-40,	
														height:"auto",
														borderRadius:15,
														position:"relative",
														boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
														}}>
														{/* <div style={{height:20}}/> */}
														
														<Space style={{marginTop:0,marginLeft:20}}>
															
															<ImageComponent src={ item.status == "error" ? emoji_forbidden:emoji_stethoscope} width={20} height={20}/>
															<h3 >{item.status == "error" ?  item.error_message: item.title}</h3>
															<div style={{width:2}}/>
															{item.status === "loading" && (
																<Button 
																	type="link"
																	icon={<LoadingOutlined/>}
																	// style={{fontSize:15,color:"#d8d8d8"}}
																	>
																	{item.title}
																</Button>
													
															)}
															

															
															{(item.status === "done4" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<FileTextOutlined/>}
																		>
																		PDFに出力
																	</Button>
																</div>
															)}
															{(item.status === "done4" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			copy(JSON.stringify(item))
																		}}
																		// icon={<FileTextOutlined/>}
																		>
																		$dev:copy json
																	</Button>
																</div>
															)}
															{(item.status === "done1" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<LoadingOutlined/>}
																		>
																		分析中
																	</Button>
																</div>
															)}
															
															
														
														</Space>
														<Popconfirm
															title="Delete the task"
															description="Are you sure to delete this task?"
															onConfirm={()=>{this.deleteUse(item)}}
															// onCancel={cancel}
															okText="Yes"
															cancelText="No"
															>
															<Button
																style={{position:"absolute",right:10,top:15}} 
																size="small" type="link" danger
																onClick = {()=>{
																	
																}}
																icon={<DeleteOutlined />}
																>
																削除
															</Button>
																
														</Popconfirm>
														<Button
															style={{position:"absolute",right:80,top:15}} 
															size="small" type="link" 
															// danger
															onClick = {()=>{
																this.setState({
																	ifShowEidtReport:true,
																	currentReport:item.content,
																	currentId:item.id
																})

															}}
															// icon={<DeleteOutlined />}
															>
															$dev編集
														</Button>
														<Button
															style={{position:"absolute",right:160,top:15}} 
															size="small" type="link" 
															// danger
															onClick = {()=>{this.checkReport(item)}}
															icon={this.state.ifChecking && <LoadingOutlined/>}
															>
															{score ? ("$devScore:" + score):("$devチェック")}
														</Button>
														{(item.status === "done1" || item.status === "done2"  || item.status === "done3" || item.status === "done4" )&&(
															<div>
																<div style={{marginLeft:65,marginRight:65,position:"relative"}}>
																	<div style={{marginRight:0}}>
																	
																		<h1 style={{fontSize:30}}>{item.title}</h1>
																		<div style={{color:"#ff0000"}}>dev用画像です：</div>
																		<Space>
																			{item.img?.map((urlHere)=>{
																				return(
																					
																					<div>
																						<Image 
																							src={urlHere} style={{width:(width-520)/6,height:30,marginBottom:20}}/>
																					</div>
																				)
																			})}
																		</Space>
																		
																		<div>
																			<pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																				{item.content}
																			</pre>
																			{item.status !== "done4" && (
																				<Skeleton active />
																			)}
																			<div style={{height:50}}/>

																			{/* {item.status == "done4" && (
																				<Space style={{marginTop:0,backgroundColor:"#f1f1f1f1",paddingLeft:20,borderRadius:10,width:"100%"}}>
																					<ImageComponent src={ emoji_pin} width={20} height={20}/>
																					<h3 style={{fontWeight:"normal"}}>上記の生成結果は、、、、、より高度な分析機能は、、、OOOチームまでお問い合わせください</h3>
																					<Button type="link">詳細について</Button>
																				</Space>
																			)} */}
																			{(item.ifEdited === 1 )&&(
																				<div>
																					<Button size="small" 
																						type="text"
																						style={{color:"#b8b8b8"}}
																						
																						// icon={<FileTextOutlined/>}
																						>
																						($dev編集済み)
																					</Button>
																				</div>
																			)}
																			{/* {(score)&&(
																				<div>
																					<div 
																						// size="small" 
																						// type="text"
																						style={{color:"#b8b8b8"}}
																						
																						// icon={<FileTextOutlined/>}
																						>
																							{score}
																					</div>
																				</div>
																			)} */}
																		</div>
																		
																		
																	</div>
																	
																</div>
																
																<div style={{marginLeft:60,marginRight:60}}><Divider/></div>
																<div style={{marginLeft:60,marginRight:60,marginTop:-20,color:"#d8d8d8"}}>{timeHere}</div>
																<div style={{marginLeft:60,marginRight:60,marginTop:0,color:"#d8d8d8"}}>{"Input:"}{item.prompt_tokens}{", Output:"}{item.total_tokens}{", timeCost:"}{item.time_cost}{", totalCost:¥"}{item.money_cost}{"円"}</div>
																<div style={{marginLeft:60,marginRight:60,marginTop:0,color:"#d8d8d8"}}>{"prompt version:"}{item.version}</div>
																<div style={{marginLeft:60,marginRight:60,marginTop:0,color:"#d8d8d8"}}>{"update message:"}{item.updateMessage}</div>
																
																
																<div style={{height:80}}/>
															</div>
														)}
														
													</div>
													
												</div>
											)	
										}
										
									})}
									
								</div>
								
							):("")}

							<div style={{height:10}}/>
							<div style={{height:2,width:width_middle-40,marginLeft:20}}><Divider/></div>
							<div style={{height:10}}/>
							<Space 
								direction="vertical" 
								style={{
								marginLeft:20,
								marginTop:10
								}}>
								<Button size="small" type="link"
									onClick = {()=>{
										
									}}
									icon={<AppstoreOutlined/>}
									>
									生成AIポリシーについて
								</Button>
								<Button size="small" type="link"
									onClick = {()=>{
										
									}}
									icon={<InfoCircleOutlined/>}
									>
									OOOOOOOについて
								</Button>
							</Space>

							<div style={{height:100}}/>

							
							
							





								
							{/* ---------------------------------------------------- */}
						</div>

						
					</div>




					

					
				{/* <p>
				
					<input 
						id = "uploadimg" type="file" accept=".csv"
						onChange={this.uploadCSV}
						ref={this.myRef_input_uploadCSV}
						style={{display:"none",}}
						/>
						
				</p>

				*/}
				{/* <FloatButton 
					icon={<SiOnlyoffice/>} type="primary" 
					onClick={this.scrollToReport} 
					/> */}
				
				
				{/* <Tour
					open={this.state.ifShowTour_generateButton}
					onClose={() => this.setState({ifShowTour_generateButton:false})}
					steps={steps}
					mask = {true}
					type="primary"
					placement="left"
					// style={{width:100}}
					indicatorsRender={(current, total) => (
					<span>
						{current + 1} / {total}
					</span>
					)}
				/> */}
	{/* 
				<Modal
					title="D-Assistant"
					centered
					open={this.state.ifShowModal_talk2AI}
					// onOk={this.open_ifShowFileExplorer}
					onCancel={()=>{
						this.setState({
							ifShowModal_talk2AI:false,
							refreshKey_talk2AI: this.state.refreshKey_talk2AI + 1
						})}}
					width={1065}
					// height={height*0.8}
					// okText = {"done"}
					footer = {null}
					>
						
					<Page_talk2AI
						report = {this.state.report}
						password = {this.props.password}
						// refreshKey = {this.state.refreshKey_talk2AI}
						/>

				</Modal> */}



				{/* <Compo_footer
					ifHasGPU={this.state.ifGpuIsWorking}

					status_API_openai = {this.props.status_API_openai}
					/> */}


					{/* <Image
						width={200}
						style={{
							position:'fixed',
							left:0,top:0,
							display: 'none',
						}}
						src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
						preview={
							{
								ifShowImgDetail,
								scaleStep,
								src: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
								onVisibleChange: () => {
									this.setState({ifShowImgDetail:false});
								},
							}
						}
					/> */}
					<video 
						style={{display:"none"}}
						ref={this.videoRef} controls={false} onSeeked={this.captureThumbnail}>
						<source src={video_csv} type="video/mp4" />
						Your browser does not support the video tag.
					</video>

					{/* pin2 */}
					<Modal
						title="相関度分析"
						centered
						open={this.state.ifShowCreateNewUsecase}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowCreateNewUsecase:false})}}
						width={600}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"scroll"}}
						>	
						
						
						<div style={{height:2}}><Divider/></div>
						<Space style={{marginTop:-17}}>
							<h2 >項目</h2>

							<Space style={{
								// width:this.state.tagsSelected_correlation.length == 2?(300):(0),
								height:30,overflow:"hidden",
								opacity:this.state.tagsSelected_correlation.length == 2?(1):(0),
								transition: 'width 0.5s ease, height 0.5s ease, opacity 0.5s ease',
								whiteSpace:"nowrap"
								}}>
								<InfoCircleOutlined />
								<Space >
									<div>選んだ項目の相関係数は</div>
									<div style={{fontWeight:"bold",color:correlation>=0?("#00ffff"):("#ff0000")}}>
										{correlation.toFixed(2)}
									</div>
								</Space>
							</Space>
						</Space>
						<div style={{
							width:600-50,
							height:300,
							// height:200,
							// backgroundColor:"#d8d8d8",
							// marginLeft:20,
							marginTop:20,
							borderRadius:15,
							// textAlign:"center",
							// display:"flex",
							// placeItems: "center",
							fontSize:30,fontWeight:"bold",
							// color:"#f1f1f1",
							// marginBottom:20,
							// flexDirection: "column" /* 设置主轴为垂直方向 */
							
							}}>
							
							{list_chikaku.map((tag)=>{
								var color=false
								if(this.state.tagsSelected_correlation.includes(tag)){
									color="#a5a5a5"
								}
								// console.log(csvData_sortByTag[tag])
								
								var correlation_here = ""
								if(this.state.tagsSelected_correlation.length == 1){
									var tag1 = csvData_sortByTag[this.state.tagsSelected_correlation[0]] 
									var tag2 = csvData_sortByTag[tag]
									console.log()
									correlation_here = correlationCoefficient(tag1,tag2).toFixed(2)
								}
								return(
									<div 
										style={{marginTop:-15,
										float:"left"
										}}
										// onClick={()=>{this.handleTag(tag)}} 
										>
										<Tag
										
											onClick={()=>{this.handleTag_SelectIncorrelation(tag)}} 
											color={color &&(color)}>
												<Space style={{fontSize:12}}>
													<div>{tag}</div>
													<div style={{color:correlation_here>+0?("#00ffff"):("#ff0000")}}>{correlation_here}</div>
												</Space>
												
										</Tag>
									</div>
									
								)
							})}	
						</div>
						
						{/* <div style={{height:20}}></div>
						<div style={{height:2}}><Divider/></div>
						<h2 style={{marginTop:-2}}>項目</h2>
						<Tag
							// onClick={()=>{this.handleTag(tag)}} 
							// color={color &&(color)}
							>
							好感度:0.xx
						</Tag>
						<Tag
							// onClick={()=>{this.handleTag(tag)}} 
							// color={color &&(color)}
							>
							記憶度:0.xx
						</Tag> */}
						

						<div style={{height:50}}></div>
						

						<Button 
							type="primary"
							onClick={()=>{
								if(this.state.tagsSelected_correlation.length <= 1){
									message.error("２項目以上を選んでください。")
								}else{
									this.addUsecasecorrelation()
									this.setState({ifShowCreateNewUsecase:false})
								}

								
							}}>
							分析を実行する

						</Button>

						
						




					</Modal>

					{/* pin2 pinHightAndLow */}
					<Modal
						title="特徴分析"
						centered
						open={this.state.ifShowCreateNewUsecase_HighAndLow}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowCreateNewUsecase_HighAndLow:false})}}
						width={600}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"scroll"}}
						>	
						
						
						<div style={{height:2}}><Divider/></div>
						<Space style={{marginTop:-17}} direction="vertical" size="small">
							<h2 >最も高い・低い得点(平均)</h2>
			
							{/* {this.state.temp_top3.map((item)=>{
								return(
									<div style={{color:"#00ffff"}}>
										{item[0]} : {item[1]}
									</div>
								)
							})}
							{this.state.temp_bottom3.map((item)=>{
								return(
									<div style={{color:"#ff0000"}}>
										{item[0]} : {item[1]}
									</div>
								)
							})} */}
							{/* <div style={{height:200,width:600}}></div> */}
						</Space>
						<div style={{height:20}}/>
						{/* <div style={{width:540,height:300,backgroundColor:"#f1f1f1"}}>
							Note:<br/>
							- こうなった理由、、を定性的に、、、、<br/>
							- 最も、、、、高い点　+ 平均のランダム抽出？　→ 画像分析<br/>
							- 物体、、、が存在するのため　→ XXXXが高かったです、、<br/>
						</div> */}
						<div style={{
							width:540,height:150,
							// backgroundColor:"#f1f1f1"
							}}>
						動画の特徴を定性的に分析しする
							
						</div>
						<div style={{height:20}}/>

						<Button 
							type="primary"
							onClick={()=>{
								this.addUsecaseHighAndLow()
								this.setState({ifShowCreateNewUsecase_HighAndLow:false})
							}}>
							分析を実行する
						</Button>
					</Modal>

					{/* pin3 pinVision */}
					<Modal
						title="画面の深掘(dev)"
						centered
						open={this.state.ifShowCreateNewUsecase_Vision}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowCreateNewUsecase_Vision:false})}}
						width={600}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"scroll"}}
						>	
						
						
						<div style={{height:2}}><Divider/></div>
					
						<div style={{
							marginTop:10,
							width:600-50,height:200,
							backgroundColor:"#f1f1f1",
							borderRadius:15,
							textAlign:"center"
							}}>
							<div style={{color:"#d8d8d8",fontWeight:"bold"}}>（画面閲覧）</div>
						</div>

						<div style={{height:10}}/>
						<div>{formatTime(this.state.temp_time_vision-8) +" ~ "+formatTime(this.state.temp_time_vision+8)}</div>
						<Slider
							min={8}
							max={237-8}
							value={this.state.temp_time_vision}
							onChange={(e)=>{
								// console.log(e)
								this.setState({temp_time_vision:e})
							}}
							// open={false}
							tooltip={{ formatter: null }} 
							// range={{
							// draggableTrack: true,
							// }}
							// defaultValue={[20, 50]}
						/>

						{/* <h3>エクストラ選択肢(DEV)</h3>
						<Input/> */}
						
						<div style={{height:50}}/>

						<Button 
							type="primary"
							onClick={()=>{
								this.addUsecaseVision()
								this.setState({ifShowCreateNewUsecase_Vision:false})
							}}>
							分析を実行する
						</Button>
					</Modal>

					{/* pin4 pinKadai */}
					<Modal
						title="課題分析(dev)"
						centered
						open={this.state.ifShowCreateNewUsecase_Problem}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowCreateNewUsecase_Problem:false})}}
						width={600}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"scroll"}}
						>	
						
						<div>
							<div style={{height:2}}><Divider/></div>
							
							<div style={{height:10}}/>
							<Segmented options={["手動分析","自動分析"]} block 
								onChange={(e)=>{
									this.setState({segment_kadai:e})
									console.log(e)
								}}
								/>
							<div style={{height:10}}/>
							{this.state.segment_kadai == "自動分析" && (
								<div style={{
									width:532,height:200,backgroundColor:"#f1f1f1",
									borderRadius:12,padding:10,
									}}>
									
								</div>
							)}
							
							{this.state.segment_kadai == "手動分析" && (
								
								<div style={{
									width:532,height:300,
									// backgroundColor:"#f1f1f1",
									borderRadius:12,padding:10,
									}}>
									<h1>手動分析</h1>
									<div style={{marginTop:-20}}>最も重視する項目を三つ選んでください</div>

									<div style={{height:5}}/>
									<div style={{height:2}}><Divider/></div>
									<div style={{height:5}}/>
									<div>
										{list_chikaku.map((tag)=>{
											var color=false
											if(this.state.tagsSelected_kadai_m.includes(tag)){
												color="#a5a5a5"
											}
											// console.log(csvData_sortByTag[tag])
											
											var correlation_here = ""
											if(this.state.tagsSelected_kadai_m.length == 1){
												var tag1 = csvData_sortByTag[this.state.tagsSelected_kadai_m[0]] 
												var tag2 = csvData_sortByTag[tag]
												console.log()
												correlation_here = correlationCoefficient(tag1,tag2).toFixed(2)
											}
											return(
												<div 
													style={{marginTop:5,
													float:"left"
													}}>
													<Tag
													
														onClick={()=>{this.handleTag_SelectInKadai(tag)}} 
														color={color &&(color)}>
															<Space style={{fontSize:12}}>
																<div>{tag}</div>
																
															</Space>
															
													</Tag>
												</div>
												
											)
										})}	
									</div>
									
								</div>
							)}
							


							<div style={{height:10}}/>
							
							<Button 
								type="primary"
								onClick={()=>{
									this.addUsecaseKadai()
									this.setState({ifShowCreateNewUsecase_Problem:false})
								}}>
								分析を実行する
							</Button>
						</div>
					</Modal>

					{/* pin4 pinAuto */}
					<Modal
						title="自動分析"
						centered
						open={this.state.ifShowCreateNewUsecase_auto}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowCreateNewUsecase_auto:false})}}
						width={600}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"auto"}}
						>	
						
						<div style={{height:"auto"}}>
							<div style={{height:2}}><Divider/></div>
							
							
							<div style={{height:10}}/>
							<div style={{height:30,fontWeight:"bold"}}>好感度との相関</div>
							{list_soukann_koukanndo_heighest3.map((item)=>{
								return(
									<div>
										{item.tag}{" : "}{item.value}
									</div>
								)
							})}
							<div style={{height:10}}/>
							<div style={{color:"#b8b8b8"}}>
								{list_soukann_koukanndo_lowest3.map((item)=>{
									return(
										<div>
											{item.tag}{" : "}{item.value}
										</div>
									)
								})}
							</div>
							
							<div style={{height:10}}/>
							<div style={{height:30,fontWeight:"bold"}}>記憶定着度との相関</div>
							{list_soukann_kioku_heighest3.map((item)=>{
								return(
									<div>
										{item.tag}{" : "}{item.value}
									</div>
								)
							})}
							<div style={{height:10}}/>
							<div style={{color:"#b8b8b8"}}>
								{list_soukann_kioku_lowest3.map((item)=>{
									return(
										<div>
											{item.tag}{" : "}{item.value}
										</div>
									)
								})}
							</div>
							<div style={{height:10}}/>
							<div style={{height:30,fontWeight:"bold"}}>好み／嗜好との相関</div>
							{list_soukann_konomi_heighest3.map((item)=>{
								return(
									<div>
										{item.tag}{" : "}{item.value}
									</div>
								)
							})}
							<div style={{height:10}}/>
							<div style={{color:"#b8b8b8"}}>
								{list_soukann_konomi_lowest3.map((item)=>{
									return(
										<div>
											{item.tag}{" : "}{item.value}
										</div>
									)
								})}
							</div>
							<div style={{height:10}}/>
							<div style={{height:2}}><Divider/></div>
							<div style={{height:10}}/>
							<div style={{height:30,fontWeight:"bold"}}>平均スコア</div>
							<div style={{height:10}}/>
							<div>
								{"好感度"}{" : "}{csvData_sortByTag_ava["好感度"]}
							</div>
							<div>
								{"記憶定着度"}{" : "}{csvData_sortByTag_ava["記憶定着度"]}
							</div>
							<div>
								{"好み／嗜好"}{" : "}{csvData_sortByTag_ava["好み／嗜好"]}
							</div>
							<div style={{height:20}}/>
							<div style={{color:"#b8b8b8"}}>
								<div style={{height:30,fontWeight:"bold"}}>基準値(fake)</div>
								<div style={{height:10}}/>
								<div>
									{"好感度"}{" : "}{overallScore_koukando}
								</div>
								<div>
									{"記憶定着度"}{" : "}{overallScore_kioku}
								</div>
								<div>
									{"好み／嗜好"}{" : "}{overallScore_konomi}
								</div>

							</div>

							{/* <div style={{height:10}}/>
							<div style={{height:2}}><Divider/></div>
							<div style={{height:10}}/>
							<div style={{height:30,fontWeight:"bold"}}>⤵️Decreased Point</div>
							<div style={{height:10}}/>
							<div style={{height:20}}/>
							<div style={{height:30,fontWeight:"bold"}}>⤴️Increased Point</div>
							<div style={{height:10}}/>
							<div style={{height:2}}><Divider/></div>
							<div style={{height:20}}/>
							<Space style={{height:20,marginBottom:10}}>
								<ImageComponent src={emoji_kiui} width={20} height={20}/>
								<h3 >Prompt Lab</h3>
							</Space>
							
							<Segmented options={["prompt_a","prompt_b","prompt_c"]} block 
								onChange={(e)=>{
									this.setState({prompt:e})
									console.log(e)
								}}
								/>
							
							
							
							
							

							<div style={{height:20}}/>
							<div style={{height:5,color:"#ff0000",marginLeft:5}}>
								version:{" "}{this.state.promptLib[this.state.prompt]["version"]}
							</div>
							<div style={{height:10}}/>
							<pre style={{fontSize:12,color:"#ffffff",whiteSpace: 'pre-wrap'}}>
								{this.state.promptLib[this.state.prompt]["prompt"].map((prompt)=>{
									return(
										<div
											style={{
											position: "relative",
											borderRadius: 10,
											overflow: "hidden",
											backgroundColor: "#23241F",
											paddingLeft: 10,
											paddingRight: 10,
											marginTop: 20,
											}}
										>
											<Button
											type="ghost"
											style={{
												position: "absolute",
												right: 10,
												top: 10,
												color: "#ffffff",
											}}
											icon={<CopyOutlined />}
											onClick={() => {
												copy(prompt);
												message.success(
												"copied to clipboard~😌"
												);
											}}
											/>
											<SyntaxHighlighter
											language="json"
											style={monokaiSublime}
											showLineNumbers={true} //这个是显示不显示左侧的行数
											//lineNumberStyle={{color: '#ddd', fontSize: 10}}     //这个是行数的样式
											wrapLines={true} //确定每行代码是否应该包装在父元素中
											>
											{prompt.trim()}
											</SyntaxHighlighter>
										</div>

										// <div style={{
										// 	backgroundColor:"#000000",
										// 	borderRadius:15,
										// 	padding:15,paddingTop:0,
										// 	marginBottom:15,
										// 	}}>
										// 	{prompt}
											
										// </div>
									)
								})}
							</pre>
							
							<div style={{height:5}}/>


							<div style={{height:30}}>dev - if review:</div>
							<Segmented options={["yes","no"]} block 
								onChange={(e)=>{
									this.setState({segment_kadai:e})
									console.log(e)
								}}
								/>
							<div style={{height:20}}/> */}

							<Button 
								type="primary"
								onClick={()=>{
									this.addUsecaseAuto(source)
									this.setState({ifShowCreateNewUsecase_auto:false})
									
								}}>
								分析を実行する
							</Button>
						</div>
					</Modal>



					{/* pin4 pinEditReport */}
					<Modal
						title="＄Dev - Report編集"
						centered
						open={this.state.ifShowEidtReport}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowEidtReport:false})}}
						width={800}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"auto"}}
						>	
						
						<div style={{height:"auto"}}>
							<div style={{height:2}}><Divider/></div>

							<TextArea
								value={this.state.currentReport}
								onChange={(e) => {
									this.setState({currentReport:e.target.value})
								}}
								placeholder="Controlled autosize"
								autoSize={{
								minRows: 20,
								maxRows: 20,
								}}
							/>
							<div style={{height:10}}/>
							<Button 
								type="primary"
								onClick={()=>{
									this.updateReport()
									this.setState({ifShowEidtReport:false})
								}}>
								更新
							</Button>
						</div>
					</Modal>
					
					{/* pinCSVDetail */}
					<Modal
						title="＄Dev - CSV Detail"
						centered
						open={this.state.ifShowCSVDetail}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowCSVDetail:false})}}
						width={800}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"auto"}}
						>	
						
						<div style={{height:"auto"}}>
							<div style={{height:2}}><Divider/></div>

							<div>{"平均avg:"}{avgListAverage}</div>
							<div>{"平均devi:"}{deviListAverage}</div>
							<div style={{height:20}}/>
							{Object.entries(cutOffByAvgAndDevi).map(([key, value], index) => (

							
									
								<div key={index}>
									<Space style={{
										
									}}>
										<div>{key}</div>
										<div style={{color:value.avg<avgListAverage/4 && "#ff0000"}}>Ava: {value.avg}</div>
										<div style={{color:value.devi<deviListAverage/4 && "#ff0000"}}>devi: {value.devi}</div>
									</Space>
									
								</div>
							
							))}
						</div>
					</Modal>

					
					
				</div>


			
			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Page_AIAnalysize);