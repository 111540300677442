

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Input,Tooltip} from 'antd';
import { TextArea,Popup} from 'antd-mobile'


import { Space, Upload ,Popover,Menu,Collapse, theme } from 'antd';
import { Button} from 'antd';
import { message,Drawer} from 'antd';
import * as CryptoJS from 'crypto-js'
import cookie from 'react-cookies'
import { MailOutlined, SettingOutlined,MenuOutlined, InfoCircleFilled, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {env_serverAddress} from './lib_env'

//-------------------------------
import Compo_card_small from "./Compo_card_small"
import Compo_footer from './Compo_footer'
import logo_dplanner from "./static/dplanner.png"
import logo_dplanner_svg from "./static/logo.svg"
import bg_neuroai from "./static/bg_neuroai.png"

import VectorDatabase from "./page/VectorDatabase"

import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'




import { 
	CaretRightOutlined,
	LoadingOutlined,
	AppstoreOutlined,
	LogoutOutlined,
	ExperimentOutlined,
	GlobalOutlined,
	MessageOutlined
} from '@ant-design/icons';
import Page_Image2image from "./Page_Image2image";
import Page_prompt2image from "./Page_prompt2image";
import Page_contentList from "./Page_contentList";
import Page_VectorDatabase from "./Page_vectorDatabase";
import Page_chatGPT4Azure from "./Page_chatGPT4Azure";
import Page_DAssistant from "./Page_DAssistant";
import Page_Kyoto from "./Page_temp_kyoto";
import Page_Harusugi from "./Page_temp_harusugi";
import Page_Demo_Video from "./Page_demo_video";
// import Page_AnaWave from "./Page_anaWave";
import Page_anaWave from "./Page_anaWave";
import Page_anaWeightFunction from "./Page_anaWeightFunction";





const { Panel } = Collapse;
const { Search } = Input;












class App extends Component {

	//pinState
	state={
		ifServerUnavailable:false,
		currentPage:"contentList",
		ifLogined :false,  
		ifFetching_Login : false,
		ifGpuIsWorking:false,
		ifChecking_GpuIsWorking:false,
		ifShowWelcome:true, //true
		// ifShowAILab:false,
		password:false,
		dataset:[],
		dataset_contentList:[],
		pageTitle:"コンテンツ一覧 - 見本",
		predicateWindowTitle:"",

		status_API_openai:1,   //  0 offLine    1onLine    2running
		status_API_stability:1,
		status_API_replicate:1,

		

	}
	// 用户登录，保存cookie
	storeToken = (token) => {
		console.log(token)
		cookie.save("neuroai_demo_token", token, { path: '/' })
	}
	getToken = async() => {
		var token_here = cookie.load("neuroai_demo_token")	
		console.log(token_here)	
		return(token_here)
	}


	heartBeats = async() => {

		if(this.state.ifLogined){
			try{
				var url = env_serverAddress+"neuroai/heartbeats"
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						password:this.state.password
						
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let result = await response.json()
				// console.log(result)
				
				if(result == 0){
				}else{
					var returnData = result.returnData

					var openai = returnData.openai
					var replicate = returnData.replicate
					var stability = returnData.stability

					var num_startEnd_openai = [0,0]
					for(var i in openai){
						var item = openai[i]
						if(item.action == "start"){
							num_startEnd_openai[0] += 1 
						}
						if(item.action == "end"){
							num_startEnd_openai[1] += 1 
						}
						
					}
					if(num_startEnd_openai[0] > num_startEnd_openai[1]){
						if(this.state.status_API_openai != 2){
							this.setState({status_API_openai:2})
						}
					}else{
						if(this.state.status_API_openai != 1){
							this.setState({status_API_openai:1})
						}
					}
					// console.log(this.state.status_API_openai)
					
					
				}
				
			}
			catch(error){
	
			}
		}

	}

	handle_changePage = (pageName)=>{
		this.setState({currentPage:pageName})
	}
	

	createPassword_1 =(value)=>{
		// console.log(value.target.value)
		this.setState({password:value.target.value})
	}

	resize=()=>{
        // console.log("resize")
        //console.log(this.myRef_member_div.current.clientHeight)
        var height = document.documentElement.clientHeight
        var width = document.documentElement.clientWidth
        this.setState({
            height:height,
            width:width,
        })
        // this.scrollToBottom();
    }
	handle_logout = ()=> {
		cookie.remove("neuroai_demo_token", { path: '/' })
		this.setState({
			ifLogined:false,
			ifShowWelcome:true,
			password:false,
		})
	}



	// pinLogin
	handle_login = async()=> {

		console.log(this.state.password)
		if(this.state.password){
			this.setState({ifFetching_Login:true})
			try{
				var url = env_serverAddress+"neuroai/login"
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						password:this.state.password	
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let result = await response.json()
				console.log(result)
				
				if(result == 0){
					message.error("wrong pass!!")
				}else{
					
					this.storeToken(this.state.password)
					this.setState({ifShowWelcome:false})
					this.setState({ifLogined:true})
					this.setState({dataset:result.returnData})
					this.setState({dataset_contentList:result.returnData_contentList})
					
				}
		
	
				// message.config({top: 3,duration: 2,});message.success('image uploaded!');
			}
			catch(error){
				message.error("サーバはメンテナンス中です...")
				this.setState({ifServerUnavailable:true})
			}
			this.setState({ifFetching_Login:false})
			this.handle_checkIfGpuIsOn()

		}
		



	}
	handle_checkIfGpuIsOn = async()=> {

		this.setState({ifChecking_GpuIsWorking:true})
		try{
			
			var url = env_serverAddress+"neuroai/ifserverisworking"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					password:this.state.password
					
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			// console.log(result)
			
			if(result == 1){
				this.setState({ifGpuIsWorking:true})
				
			}else{
				
				// console.log(result.returnData)
				// message.error("GPU server is not working !!")
				
			}

	

			// message.config({top: 3,duration: 2,});message.success('image uploaded!');
		}
		catch(error){
			
		}
		this.setState({ifChecking_GpuIsWorking:false})



	}

	componentDidMount(){
		// this.handle_login()
		window.addEventListener('resize', this.resize)
		
		// setInterval(()=>{this.heartBeats()}, 4000)

		var password_here = false
		var token = this.getToken()
		token.then(function(value) {
			if (typeof value != "undefined") {
				console.log("cookie存在");
				console.log(value);
				password_here = value
			}
		})
		setTimeout(()=>{this.setState({password:password_here})},10); 
		setTimeout(()=>{this.handle_login()},30); 

		
		
		
	}

	render() {

		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
	
		var width_leftBar = 250
		var width_middle = width - width_leftBar 

		// pinReturn
		return(
		
			<div style={{width:width,height:height,position:"fixed",left:0,top:0}}
				
				>
				<div 
					style={{
					width:width_leftBar-1,			
					backgroundColor:"#e8e8e8",
					height:height,
					float:"left",
					borderRight: "1px solid #d1d1d1",
					//padding:20,
					}}>
					<div style={{height:0}}/>

					{/* <div style={{
						paddingLeft:0,
						fontSize:"20px",
						fontWeight:"bold",
						height:50,
						marginLeft:10,
						// marginTop:20,
						marginRight:10,
						backgroundColor:"#e8e8e8"}}>
						
						<img src = {logo_dplanner_svg} style={{
							width:40,marginLeft:-0,
							paddingTop:15
							}}/>
					</div> */}

					<div style={{height:10}}/>

					<Button onClick = {()=>{
							this.handle_changePage("contentList")
							this.setState({pageTitle:"コンテンツ一覧 - 見本"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "contentList" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<GlobalOutlined style={{color:"#3875F6"}}/>}>
						コンテンツ一覧 - 見本
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("chatGPT4Azure")
							this.setState({pageTitle:"chatGPT4 - Azure"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "chatGPT4Azure" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<MessageOutlined style={{color:"#3875F6"}}/>}>
						chatGPT4 - test
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("D-Assistant")
							this.setState({pageTitle:"D-Assistant"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "D-Assistant" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<ExperimentOutlined style={{color:"#3875F6"}}/>}>
						D-Assistant
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("kyoto")
							this.setState({pageTitle:"kyoto"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "kyoto" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<ExperimentOutlined style={{color:"#3875F6"}}/>}>
						京都サンプル動画
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("harusugi")
							this.setState({pageTitle:"harusugi"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "harusugi" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<ExperimentOutlined style={{color:"#3875F6"}}/>}>
						春過ぎサンプル動画
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("anaWave")
							this.setState({pageTitle:"anaWave"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "anaWave" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<ExperimentOutlined style={{color:"#3875F6"}}/>}>
						ランダム波形生成
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("anaWeightFunction")
							this.setState({pageTitle:"anaWeightFunction"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "anaWeightFunction" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<ExperimentOutlined style={{color:"#3875F6"}}/>}>
						Weight関数テスト
					</Button>


					<Button onClick = {()=>{
							this.handle_changePage("VectorDatabase")
							this.setState({pageTitle:"VectorDatabase"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "VectorDatabase" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<ExperimentOutlined style={{color:"#3875F6"}}/>}>
						RAG
					</Button>

					{/* <Button onClick = {()=>{
							this.handle_changePage("Gpttogether")
							this.setState({pageTitle:"Gpttogether"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "Gpttogether" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<ExperimentOutlined style={{color:"#3875F6"}}/>}>
						GPTTogether
					</Button> */}



					{/* //pin1 */}
					{/* <div style={{width:200,height:5,marginLeft:25}}><Divider style={{marginTop:10,marginBottom:0}}/></div>

					<Button onClick = {()=>{
							this.handle_changePage("image2image")
							this.setState({pageTitle:"image2image"})
						}} size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "image2image" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<SettingOutlined style={{color:"#000000"}}/>}>
						$dev image2image
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("prompt2image")
							this.setState({pageTitle:"prompt2image"})
						}} 
						size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "prompt2image" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<SettingOutlined style={{color:"#000000"}}/>}>
						$dev prompt2image
					</Button>

					<Button onClick = {()=>{
							this.handle_changePage("vectorDatabase")
							this.setState({pageTitle:"vectorDatabase"})
						}} 
						size="middle" type="ghost" style={{width:230,
						color:"#000000",
						marginLeft:9,
						backgroundColor: this.state.currentPage === "vectorDatabase" ? "#d8d8d8" : "transparent",
						marginTop:5,textAlign:"left"}} 
						icon={<SettingOutlined style={{color:"#000000"}}/>}>
						$dev vectorDatabase
					</Button> */}

					


					<div style={{height:5}}/>

				</div>




				<div 
					style={{
					width:width_middle,			
					backgroundColor:"#e8e8e8",
					height:height,
					float:"left",
					//padding:20,
					
					}}>
					<div style={{
						paddingLeft:0,fontSize:"20px",
						fontWeight:"bold",height:50,backgroundColor:"#e8e8e8",
						borderBottom: "1px solid #d1d1d1"
						}}>
						{/* <img src={logo_uol} style={{width:180,paddingLeft:30,paddingTop:12}}/> */}
						<Space style={{marginLeft:12,marginTop:10}}>
							<Button type="text" icon={<MenuOutlined />}/>
							<div style={{
								marginLeft:0,fontSize:"13px",
								// fontWeight:"bold"
								}}>
								{this.state.pageTitle}
							</div>
							
							<Tooltip placement="left" title="Logout">
								<Button 
									// type="primary" 
									icon={<LogoutOutlined />} 
									style={{position:"absolute",right:10,top:9}} 
									danger
									onClick={this.handle_logout}
									/>
							</Tooltip>
							

						</Space>
					</div>

					{/* <div style={{width:this.props.width}}><Divider style={{marginTop:0,marginBottom:0}}/></div> */}
					
					{this.state.currentPage === "contentList" ? (
						<Page_contentList
							width={width-250}
							height={height-50}
							password={this.state.password}
							dataset={this.state.dataset}
							dataset_contentList={this.state.dataset_contentList}
							ifGpuIsWorking={this.state.ifGpuIsWorking}
							ifChecking_GpuIsWorking={this.state.ifChecking_GpuIsWorking}
							callBack_reloadData = {this.handle_login}

							status_API_openai = {this.state.status_API_openai}

						/>):("")}

					{this.state.currentPage === "chatGPT4Azure" ? (
						<Page_chatGPT4Azure
							password={this.state.password}
							width={width-250}
							
						/>
						):("")}

					{this.state.currentPage === "kyoto" ? (
						
						<Page_Kyoto
							password={this.state.password}
							width={width-250}
							
						/>
						
						):("")}

					{this.state.currentPage === "harusugi" ? (
						
						<Page_Harusugi
							password={this.state.password}
							width={width-250}
							
						/>
						
						):("")}

					{this.state.currentPage === "VectorDatabase" ? (
						
						<div style={{marginTop:20,marginLeft:20}}>
							<Button onClick={()=>{
								this.setState({ifShowVectorDatabase:true})
							}}>
								VectorDatabase
							</Button>
						</div>
						
						):("")}

					{this.state.currentPage === "anaWave" && (
						<Page_anaWave
						password={this.state.password}
						width={width-250}
						
					/>
					)}

					{this.state.currentPage === "anaWeightFunction" && (
						<Page_anaWeightFunction
						password={this.state.password}
						width={width-250}
						
					/>
					)}


					{this.state.currentPage === "D-Assistant" ? (
						<div style={{
							height:height,
							color:"#d8d8d8",
							textAlign:"center",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop:-50
							}}>

							<Page_DAssistant
								password={this.state.password}
								width={width-250}
								/>

								
								{/* <div>
									<div style={{fontSize:"15vw",}}>
										<SettingOutlined/>
									</div>
									<div style={{fontSize:"4vw",fontWeight:"bold",}}>
										Developing
									</div>
									

								</div> */}


						</div>
						
						):("")}


					{this.state.currentPage === "image2image" ? (
						<Page_Image2image
							width={width-250}
							height={height-50}
							password={this.state.password}
							dataset={this.state.dataset}
							ifGpuIsWorking={this.state.ifGpuIsWorking}
							ifChecking_GpuIsWorking={this.state.ifChecking_GpuIsWorking}
							
						/>):("")}

					
					{this.state.currentPage === "prompt2image" ? (
						<Page_prompt2image
							width={width-250}
							height={height-50}
							password={this.state.password}
							dataset={this.state.dataset}
							ifGpuIsWorking={this.state.ifGpuIsWorking}
							ifChecking_GpuIsWorking={this.state.ifChecking_GpuIsWorking}
							

						/>):("")}


					

					{this.state.currentPage === "vectorDatabase" ? (
						<div style={{
							height:height,
							color:"#d8d8d8",
							textAlign:"center",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop:-100
							}}>
								<div>
									<div style={{fontSize:"15vw",}}>
										<SettingOutlined/>
									</div>
									<div style={{fontSize:"4vw",fontWeight:"bold",}}>
										Developing
									</div>
								</div>
						</div>):("")}

					{this.state.currentPage === "Gpttogether" ? (
						<div style={{
							height:height,
							color:"#d8d8d8",
							textAlign:"center",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop:-100
							}}>
								<div>
									<div style={{fontSize:"15vw",}}>
										<SettingOutlined/>
									</div>
									<div style={{fontSize:"4vw",fontWeight:"bold",}}>
										Gpttogether
									</div>
								</div>
						</div>):("")}

							

				</div>




				{/* pinWel Login 欢迎界面 */}
				<Popup
					// showCloseButton
					position='bottom'
					onClose={() => {
						this.setState({ifShowWelcome:false})
					}}
					//showCloseButton
					visible={this.state.ifShowWelcome}
					bodyStyle={{
					height: height,
					width:width,
					}}
					>
					<div>
						<div style={{
							height:height,
							overflowX:"hidden",
							overflowY:"auto",
							width:width/2,
							float:"left",
							overflow:"hidden",
							//backgroundColor:"#E25624"
							}}>
							<ImageComponent src = {bg_neuroai} width = {width/2} height = {height} ifShowPreview={false}/>
							
						
							{/* <img src={banner_here} style={{width:width/2}}/> */}
							
						</div>


						{/* 右边登陆窗口 */}
						<div style={{
							height:height,
							overflowX:"hidden",
							overflowY:"auto",
							width:width/2-30,
							paddingLeft:30,
							paddingTop:10,
							float:"left",
							//backgroundColor:"#f1f1f1",
							position:"fixed",
							right:0,
							top:0,
							}}>

							<div style = {{position:"absolute",left:30,top:30}}>
								<div style={{color:"#d8d8d8"}}>
									{/* <div style={{height:0}}/> */}
									<div style={{paddingLeft:0,paddingRight:20,fontSize:"20px",fontWeight:"bold"}}>
										Powered by chatGPT
									</div>
									<div style={{height:10}}/>
									<div style={{paddingLeft:0,paddingRight:20,fontSize:"10px",}}>
										Free Research Preview: ChatGPT is optimized for dialogue.
									</div>
									<div style={{paddingLeft:0,paddingRight:20,fontSize:"10px",}}>
										Our goal is to make Al systems more natural to interact with, and your feedback will help us improve our systems and make them safer.
									</div>
									<div style={{width:width/2-60}}><Divider/></div>
									<div style={{height:5}}/>


									<div>
										<div style={{color:"#000000",paddingLeft:0,paddingRight:20,fontSize:"21px"}}>
											⛵️AIGC testinh page v0606
										</div>

										<div style={{height:5}}/>

										<div style={{color:"#000000",paddingLeft:0,paddingRight:20,fontSize:"40px",fontWeight:"bold"}}>
											D-Planner DEMO
										</div>
										
									</div>
										

									
								</div>
							</div>

							



							


							<div style = {{position:"absolute",left:30,bottom:30,backgroundColor:"#ffffff"}}>


								{this.state.ifFetching_Login? (
									""
								):(
									<Input 
										style={{
										height:"35px",
										width:290,
										backgroundColor:"#f1f1f1"
										}}
										disabled={this.state.ifServerUnavailable}
										onChange={this.createPassword_1}
										ref={this.myRef_password_login}
										type='password'
										placeholder="password"
										/>	
								)}

										


								<div>
									<div style={{height:20}}/>
									
									<Button  type="primary"  
										onClick={this.handle_login} 
										style={{width:290,height:50,}}
										icon = {this.state.ifFetching_Login? (<LoadingOutlined/>):("")}
										disabled = {this.state.ifFetching_Login || this.state.ifServerUnavailable}
										>
										{this.state.ifServerUnavailable? ("サーバーはメンテナンス中です"):("Login")}
									</Button>
									
								</div>
								

								
								
								<div style={{height:5}}/>




								<div style={{width:width/2-60}}><Divider/></div>
								{/* <img src={openAI} style={{width:120}}/> */}
								<div style={{color:"#d8d8d8"}}>
									{/* <div style={{height:0}}/> */}
									<div style={{paddingLeft:0,paddingRight:20,fontSize:"20px",fontWeight:"bold"}}>
										NeuroAI / Nin
									</div>
									<div style={{height:10}}/>
									<div style={{paddingLeft:0,paddingRight:20,fontSize:"13px",}}>
										NTT-DATA Information Technology 2023, all rights reserved
									</div>
									<div style={{height:5}}/>
								</div>
							</div>
							

						</div>



					</div>


					
				</Popup>



				{/* pinVector RAG */}
				<Popup
					showCloseButton
					position='bottom'
					onClose={() => {
						this.setState({ifShowVectorDatabase:false})
					}}
					//showCloseButton
					visible={this.state.ifShowVectorDatabase}
					bodyStyle={{
					height: height,
					width:width,
					}}
					>
					<div>
						<VectorDatabase/>
					</div>


					
				</Popup>





				







				<Compo_footer
					ifHasGPU={this.state.ifGpuIsWorking}
					status_API_openai = {this.state.status_API_openai}
					/>

			</div>






			
			
			
			
		)

  	}
}

export default withRouter(App);