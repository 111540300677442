
import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { 
	Divider,Popconfirm,
	Typography,Space,Button ,message,
	Tooltip,Collapse,Avatar,
	Dropdown
} from 'antd';
import { TextArea} from 'antd-mobile'
import {env_serverAddress} from './lib_env'
import {fetch_by_json} from './lib_fetch'
import Typewriter from 'typewriter-effect';

import { 
	FileAddOutlined,
	RedoOutlined,
	FileOutlined,
	EditOutlined,
	SendOutlined,
	UserOutlined,
	SmileOutlined,
	DownOutlined,
	LoadingOutlined,
	CodepenOutlined,
	CaretRightOutlined,
	SettingOutlined,
	AppstoreAddOutlined,
	InfoCircleTwoTone
} from '@ant-design/icons';

import logo_dplanner_small from "./static/neuroai_logo.png"
import logo_neuroai from "./static/neuroai_logo.png"

import icon_dassistant from "./static/icon_dassistant.png"
import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'
import gpticon from "./static/gpticon.png"
import ReactMarkdown from 'react-markdown';
import Compo_card_info from "./Compo_card_info";



// ---------------------------
// PRESET HERE
var gptName = "D-Assistant"




// ---------------------------






window.global = {input_text_chat:"",}
const { Panel } = Collapse;
const { Title, Paragraph, Text, Link } = Typography;

function ifPressCommandAndEnter(event, callback) {
	const isCommandKey = navigator.userAgent.indexOf('Mac') !== -1 ? event.metaKey : event.ctrlKey;
	const isEnterKey = event.keyCode === 13;
	if (isCommandKey && isEnterKey) {
	  event.preventDefault();
	  callback();
	}
}

class Page_chatGPT4Azure extends Component {

	constructor(props){
        super(props);
        this.Ref_TextInput = React.createRef();
		this.consoleEndRef = React.createRef();
	}



	//pinState
	state={
		chatHistory : [
			{
				role:"system",
				content:"",
			},
			{
				role:"assistant",
				content:"fetching...",
			},
		],
		ifUploading : false,
		mode_gpt : "normal",
		resetFlag : false,
		ifShowPromptTips : true,
		// ifClearingChatHistory : false,
		mood:"neutral"
		
	}



	

	


	handleKeyDown = (event) => {
		ifPressCommandAndEnter(event, () => {
			// console.log('Command + Enter pressed!')
			var content = window.global.input_text_chat
			if(content == ""){
				message.error('Please input the conversation content first 😥');
			}
			else{
				var chatHistory = this.props.chatGPT_history
				this.props.AILab_talk2AI(chatHistory,content)
				this.Ref_TextInput.current.clear()
				
			}
	
		})
	}

	

	componentDidMount(){
		// console.clear()
		document.addEventListener('keydown', this.handleKeyDown)
		// this.fetchChatHistory()

	}


	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}


	
	onChange_textInput =(input_text)=>{
		window.global.input_text_chat = input_text
		// console.log(window.global.input_text_chat)
	}

	scrollToBottom = () => {
		this.consoleEndRef.current.scrollIntoView({ behavior: "smooth" });
	}

	// pinShortCut
	shortCut_talk2AI_1 = (prompt) =>{
		window.global.input_text_chat = prompt
		var chatHistory = this.props.chatGPT_history
		this.props.AILab_talk2AI(chatHistory,prompt)
	}
	
	componentDidUpdate(){

		setTimeout(()=>{this.scrollToBottom()},100)
	}

	

	

	// pinRender
	
	
	
	render() {

		
		
		
		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight 

        

		// height = height * 0.5
		var width_here = this.props.width

        // console.log("width_here:"+width_here)

		var list_file_here = []
		var list_file_gpt_here = []

		for(var i in this.state.list_file){
			var item = this.state.list_file[i]
			if(item.ifByGpt == 1){
				list_file_gpt_here.push(item)
			}else{
				list_file_here.push(item)
			}
		}
		var isTxt = true

		var ifUploading = this.state.ifUploading
		var AILab_ifClearingChatHistory = this.props.AILab_ifClearingChatHistory

		var chatHistory = JSON.parse(JSON.stringify(this.state.chatHistory))
		
		if(ifUploading){
			chatHistory.push({
				role:"assistant",
				content:"D-Assistant is thinking...",
				tag:"WAITTING_FOR_GPT"
			})
		}
		


		// pinReturn
		return(
		
			

			<div style={{
				//backgroundColor:"#ff00f0",
				height:height - 35,
				width : width_here,
				overflowY:"hidden",
				overflowX:"hidden",
				margin:20,
				// marginTop:10,
				}}>
				
				<div 
					
					style={{
					//width:width,
					height:"98%",	
					display: "inline-block",
					overflowX:"hidden",
					overflowY:"hidden",
					}}>


					<div 
						style={{
						overflowY:"hidden",
						// backgroundColor:"#d8d8d8",
						height:"100%",}}
						>
						
					
						<div style={{
							overflowX:"hidden",
							width:width_here,
							height:"100%",
							// backgroundColor:"#ff00ff",
							position:"relative",
							marginLeft:0
							}}>


							{/* pin1 */}
							<div 
								style={{
								height:height-170,
								overflowY:"scroll",
								// backgroundColor:"#ff0000"
								}}>

								{this.props.chatGPT_history.map((item,index)=>{

									// var ifLastOne = index === chatHistory.length - 1;

									var ifLastOne = (index == chatHistory.length-1)
									// console.log(index)
									var ifIsGpt = false
									var role = "User"
									if(item.role == "assistant"){
										ifIsGpt = true
										role = gptName

									}
									if(item.role == "info"){
										ifIsGpt = true
										role = "info"

									}

									

									var isWaitting = false
									if(item.tag == "WAITTING_FOR_GPT"){
										isWaitting = true
									}
									if(item.content != "null"){

										//过滤掉system级的对话
										return(
											<div >
												
												
												<div >

													{role == "info"?(
														<Space>
															<div>
		
																<div style={{float:"left"}}>
																	{ifIsGpt ?(
																		<div>
																			<div style={{
																				borderRadius:20,
																				// border: "1px solid #f1f1f1",
																				overflow:"hidden",
																				backgroundColor:"#ffffff",
																				width:40,height:40,
																				color:"#ffffff",
																				fontSize:30,

																				// padding:5
																				}}>
																					<CodepenOutlined style={{marginLeft:5,marginTop:5}}/>
																					{/* <ImageComponent src={logo_neuroai} width={40} height={40}/> */}
																			</div>
																	
																		</div>
																	):(
																		<div>
																			<Avatar
																				style={{
																				backgroundColor: item.iconColor,
																				width:40,height:40,paddingTop:8,
																				}}
																				icon={<UserOutlined style={{fontSize:23}}/>}
																				/>
																			{/* <div style={{width:40,height:40,borderRadius:20,backgroundColor:item.iconColor}}>
																				
																			</div> */}
																		</div>
																	)}
																</div>
		
																
																
																<div style={{
																	width:width_here-120,
																	borderRadius:"8px",
																	backgroundColor:"#f1f1f1",
																	height:"auto",
																	paddingTop:"10px",
																	paddingBottom:"10px",
																	paddingLeft:"15px",
																	paddingRight:"15px",
																	marginBottom:-10,
																	//fontWeight:"bold"
																	fontSize:"14px",
																	float:"left"
																	}}>
																	<div style={{hegiht:10}}>
																		
																		<Space>
																			<InfoCircleTwoTone/>
																			<div>{"" + item.content}</div>
																
																		</Space>
																	</div>
		

																	

																	

		
		
																</div>
																
															</div>
														</Space>
													):(
														<Space>
															<div>
		
																<div style={{float:"left"}}>
																	{ifIsGpt ?(
																		<div>
																			<div style={{
																				borderRadius:20,
																				// border: "1px solid #f1f1f1",
																				overflow:"hidden",
																				backgroundColor:"#ff0000",
																				width:40,height:40,
																				color:"#ffffff",
																				fontSize:30,

																				// padding:5
																				}}>
																					<CodepenOutlined style={{marginLeft:5,marginTop:5}}/>
																					{/* <ImageComponent src={logo_neuroai} width={40} height={40}/> */}
																			</div>
																	
																		</div>
																	):(
																		<div>
																			<Avatar
																				style={{
																				backgroundColor: item.iconColor,
																				width:40,height:40,paddingTop:8,
																				}}
																				icon={<UserOutlined style={{fontSize:23}}/>}
																				/>
																			{/* <div style={{width:40,height:40,borderRadius:20,backgroundColor:item.iconColor}}>
																				
																			</div> */}
																		</div>
																	)}
																</div>
		
																
																
																<div style={{
																	width:width_here-90,
																	borderRadius:"8px",
																	// backgroundColor:"#ffff00",
																	height:"auto",
																	paddingTop:"1px",
																	// paddingBottom:"5px",
																	paddingLeft:"15px",
																	paddingRight:"15px",
																	marginBottom:-10,
																	//fontWeight:"bold"
																	fontSize:"14px",
																	float:"left"
																	}}>
																	<div style={{hegiht:10,marginBottom:-13,fontWeight:"bolder"}}>
																		
																		<Space>
																			<div>{role}</div>
																
																		</Space>
																	</div>
		

																	{isWaitting?(
																		
																		<div style={{
																			fontSize:20,
																			height:50,
																			marginTop:20,
																			color:"#a1a1a1"
																			// color:isWaitting ? ("#d8d8d8"):("#000000")
																			}}>
																			
																			<Typewriter
																				style={{
																				// fontSize:20,
																				}}
																				onInit={(typewriter) => {
																					typewriter
																					.typeString('送信しました')
																					.pauseFor(500)
																					.deleteAll()
																					.typeString('少々お待ちください...')
																					.pauseFor(5000)
																					.deleteAll()
																					.typeString('長い文章を生成しております...')
																					
																					.start();
																				}}/>
																			
																		</div>
																		
																	):(
																		<div style={{
																			fontSize:20,
																			// color:isWaitting ? ("#d8d8d8"):("#000000")
																			}}>
																			<ReactMarkdown>
																			{item.content}
																			</ReactMarkdown>
																		</div>
																	)}

																	

		
		
																</div>
																
															</div>
														</Space>

													)}
													
													
												</div>
												<div style={{height:10}}/>
												
											</div>
										)
									}

									
								})}


								{this.state.ifShowPromptTips ?(
									<Space direction="vertical" style={{marginLeft:47,marginTop:10}}>

									
										<Button size="small" type="link"
											onClick = {()=>{
												this.shortCut_talk2AI_1("画像全体の解像度を高めてください。")
												this.setState({ifShowPromptTips:false})
											}}
											>質問例 1: 画像全体の解像度を高めてください。
										</Button>
										<Button size="small" type="link"
											onClick = {()=>{
												this.shortCut_talk2AI_1("画像に存在する「物体A」を「物体B」描き換えてください。")
												this.setState({ifShowPromptTips:false})
											}}
											>
											質問例 2: 画像に存在する「物体A」を「物体B」描き換えてください。
										</Button>
										{/* <Button size="small" type="link"
											onClick = {()=>{
												this.shortCut_talk2AI_1("xxxxx")
												this.setState({ifShowPromptTips:false})
											}}
											>
											質問例 3: xxxxx
										</Button> */}

										

									</Space>	
								):("")}


                                <div style={{height:20}}/>
                                
								
								<Space style={{marginLeft:47}}>
                                    {/* <Button 
                                        size="small" type="link"
                                        // icon = {ifClearingChatHistory?(<LoadingOutlined style={{fontSize:12}}/>):(<SettingOutlined style={{fontSize:12}}/>)   }
                                        icon = {<RedoOutlined />}
                                        style={{
                                            // marginLeft:-1,
                                            // marginTop:-10
                                            // fontSize:12

                                        }}
                                        disabled={true}
                                        // danger
                                        >
                                        繰り返す
                                    </Button> */}

									<Popconfirm
										title="会話歴史をクリアしますか？"
										description="Are you sure to delete this task?"
										onConfirm={()=>{this.props.AILab_clearChatHistory()}}
										// onCancel={cancel}
										okText="Yes"
										cancelText="No"
										>
											
										<Button size="small" type="text"
											icon = {AILab_ifClearingChatHistory?(<LoadingOutlined style={{fontSize:12}}/>):(<SettingOutlined style={{fontSize:12}}/>)   }
											// icon = {<SettingOutlined />}
											style={{}}
											disabled={ifUploading}
											danger
											>
											記録を削除
										</Button>

									</Popconfirm>


								</Space>
								
								
								{/* <Compo_card_info
									width_here = {width_here}
                                    title = {"Do you want to ..."}
									
								/> */}


								<div style={{height:100}}/>
								
								{/* ------------------------------------ */}
								{/* 结尾定位用标签 */}
								<div
									ref={this.consoleEndRef}
									className="console-end"
									style={{ display: "block" }}
								></div>
								{/* ------------------------------------ */}
							</div>
							



							{/* pinInput
							输入栏--------------------------------------------------
									*/}
							<div
								
								style={{
								overflowX:"hidden",
								width:width_here,
								position:"fixed",
								bottom:"20px",
								// backgroundColor:"#f1f1f1",
								left:width - width_here,
                                
								}}>
								
								<div style={{height:15}}/>
								<Space>
									<TextArea
										placeholder='Please enter content'
										style={{
										width:width_here-95,
										// backgroundColor:"#f1f1f1",
										marginLeft:20,
										}}
										onChange={this.onChange_textInput}
									
										
										autoSize={{ minRows: 2, maxRows: 2 }}
										ref={this.Ref_TextInput}
										/>
									<Button
										//shape="circle"
										shape="circle"
										type="primary"
										style={{
											height:50,
											width:50,
											// position:"relative",
										}}
										disabled ={this.props.ifUploading}
										onClick={()=>{



											var content = window.global.input_text_chat
											if(content == ""){
												message.error('Please input the conversation content first 😥');
											}
											else{
												var chatHistory = this.props.chatGPT_history
												this.props.AILab_talk2AI(chatHistory,content)
												this.Ref_TextInput.current.clear()
												
											}
											



										}}


										// icon= {ifUploading?(<LoadingOutlined/>):(<SendOutlined />)}
										>
										{this.props.ifUploading?(<LoadingOutlined/>):(<SendOutlined />)}
									</Button>	
								</Space>
								
								<div style={{height:15}}/>
							</div>
								
							<div
								// 输入栏--------------------------------------------------
								style={{
								
								width:width_here-40,
								height:1,
								// marginLeft:-50,
								position:"absolute",
								left:0,
								bottom:"144.5px",
								}}>
								<Divider/>
								{/* <div style={{marginLeft:-10}}>
                                    
                                </div> */}
								
							</div>
							
							
							
						</div>


						
					</div>
				</div>

				{/* <div 
					style={{
					width:200,height:60,
					borderRadius:15,
					backgroundColor:"#f1f1f1",
					position:"absolute",
					right:50,
					top:50
					}}>
						<div style={{marginLeft:10,fontWeight:"bold",fontSize:20}}>Dev Mode</div>

						<div style={{marginLeft:11}}>
							<Dropdown
								
								menu={{
								items,
								}}
								>
								<a onClick={
									(e) => e.preventDefault()
									}>
								<Space>
									{this.state.mode_gpt}
									<DownOutlined />
								</Space>
								</a>
							</Dropdown>	
						</div>
						

				</div> */}
			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Page_chatGPT4Azure);