


// Logistic
export function addWeightToTagsOfCurved(tags) {
	// 提取所有 avg 值
	const avgValues = Object.values(tags).map(tag => tag.avg);
	const maxValue = Math.max(...avgValues);
    // console.log(maxValue)
	// 计算 avg 的平均值
	const overallAvg = avgValues.reduce((sum, avg) => sum + avg, 0) / avgValues.length;


    // x是每个印象度的平均值, a : x的最大值 ,b是曲率
    // x/a/b : number
    // 返回的是比重w(0~1)
    // w * 相关度 ,修正相关度的
	function curvedFunction(x, a, b=5) {
        if (x > a) {
            throw new Error("x cannot be greater than a");
        }
        return 1 - Math.exp(-b * (x / a));
    }

	const max = maxValue;

	// 为每个标签计算 weight 并添加到标签上
	for (const tagName in tags) {
		if (tags.hasOwnProperty(tagName)) {
			const tag = tags[tagName];
			tag.weight = curvedFunction(tag.avg,max);
		}
	}

	// 最后输出修改过的 tags
	// console.log(tags);
	return(tags)
}


// 正規分布
export function addWeightToTagsOfGaussian(tags) {
	// 提取所有 avg 值
	const avgValues = Object.values(tags).map(tag => tag.avg);
	const maxValue = Math.max(...avgValues);
	// 计算 avg 的平均值
	const overallAvg = avgValues.reduce((sum, avg) => sum + avg, 0) / avgValues.length;

	// 定义高斯分布函数
	function tellGaussian(x, avg, max) {
		const sigma = max / 4; // 选择一个合理的标准差
		const exponent = -Math.pow(x - avg, 2) / (2 * Math.pow(sigma, 2));
		const y = Math.exp(exponent);
		return y;
	}

	// 假设 max = 1, 因为 avg 值是 0 到 1 之间
	const max = maxValue;

	// 为每个标签计算 weight 并添加到标签上
	for (const tagName in tags) {
		if (tags.hasOwnProperty(tagName)) {
			const tag = tags[tagName];
			tag.weight = tellGaussian(tag.avg, overallAvg, max);
		}
	}

	// 最后输出修改过的 tags
	// console.log(tags);
	return(tags)
}



// 正規分布
export function addWeightToTagsOfGaussian2(tags) {
	// 提取所有 avg 值
	const avgValues = Object.values(tags).map(tag => tag.avg);
	const maxValue = Math.max(...avgValues);
	// 计算 avg 的平均值
	const overallAvg = avgValues.reduce((sum, avg) => sum + avg, 0) / avgValues.length;

	// 定义高斯分布函数
	function tellGaussian(x, avg, max) {
		const sigma = max / 4; // 选择一个合理的标准差
		const exponent = -Math.pow(x - avg, 2) / (2 * Math.pow(sigma, 2));
		const y = Math.exp(exponent);
		return y;
	}

	// 假设 max = 1, 因为 avg 值是 0 到 1 之间
	const max = maxValue;
    const overallAvg2 = (maxValue + overallAvg)/2

	// 为每个标签计算 weight 并添加到标签上
	for (const tagName in tags) {
		if (tags.hasOwnProperty(tagName)) {
			const tag = tags[tagName];
			tag.weight = tellGaussian(tag.avg, overallAvg2, max);
		}
	}

	// 最后输出修改过的 tags
	// console.log(tags);
	return(tags)
}


export function addWeightToTagsOfLine(tags) {
    // 获取所有avg的值
    const allAvgs = Object.values(tags).map(tag => tag.avg);

    // 获取最小和最大值
    const minAvg = Math.min(...allAvgs);
    const maxAvg = Math.max(...allAvgs);
    const range = maxAvg - minAvg;

    // 如果所有的avg值相同，直接设置所有的权重为1
    if (range === 0) {
        for (let tag in tags) {
            tags[tag].weight = 1;
        }
    } else {
        // 计算每个tag的权重
        for (let tag in tags) {
            tags[tag].weight = (tags[tag].avg - minAvg) / range;
        }
    }
    return tags;
}



// 将findWaveTrough中返回的数据格式重新生成为类似[0,0,0,4,3,2,2,3,4,0,0...]的波形
// 用于前端展示筛选出的波谷位置
// waveformLength 为原始波形的长度
export function convertTroughToArray(waveformLength, trough) {
    // 创建一个长度为 waveformLength 的数组，初始值全部为 0
    let resultArray = Array(waveformLength).fill(0);

    // 将波谷原始数值填入对应的位置
    const [start, end] = trough.range;
    for (let i = start; i <= end; i++) {
        resultArray[i] = trough.raw[i - start];
    }
    return resultArray;
}


// 根据windowLength找出波形中最低谷的位置
export function findWaveTrough(waveform, windowLength) {

    var newWaveform = []
    for(var index in waveform){
        var newValue = waveform[index]
        if(newValue < 0){newValue=0.01}
        newWaveform.push(newValue)
    }
    waveform = newWaveform

    
    if (waveform.length < windowLength) {
        throw new Error("波形长度必须大于窗口长度");
    }

    const n = waveform.length;
    let windowSegments = [];

    // 1. 整个波形的长度和计算备用的平均值
    const overallAverage = waveform.reduce((sum, x) => sum + x, 0) / n;
    // 2. 窗口内计算平均值
    for (let i = 0; i <= n - windowLength; i++) {
        const window = waveform.slice(i, i + windowLength);
        const windowAverage = window.reduce((sum, x) => sum + x, 0) / windowLength;
        windowSegments.push({ 
            start: i, 
            end: i + windowLength - 1, 
            average: windowAverage,
            raw: window
        });
    }
    // 3. 排序
    windowSegments.sort((a, b) => a.average - b.average);
    // 4. 返回排序后的第一位
    const lowestWindow = windowSegments[0];
    return {
        range: [lowestWindow.start, lowestWindow.end],
        average: lowestWindow.average,
        raw: lowestWindow.raw
    };
}


// 根据波形长度确定windowSize
export const tellWindowSize = (list) =>  {
    // 移动平均中windowSize的simple设定
    var windowSize = 1
    if(list.length >= 30){
        windowSize = 3
    }
    if(list.length >= 60){
        windowSize = 5
    }
    if(list.length >= 120){
        windowSize = 10
    }
    return(windowSize)
}


// 移动平均
export const movingAverageFilter = (data, windowSize) =>  {

	if(windowSize != 0){
		let smoothedData = [];

		for (let i = 0; i < data.length; i++) {
			let startIndex = Math.max(0, i - windowSize + 1);
			let windowData = data.slice(startIndex, i + 1);
			let smoothedValue = windowData.reduce((acc, val) => acc + val, 0) / windowData.length;
			smoothedData.push(smoothedValue);
		}

		return smoothedData;
	}else{
		return data
	}
    
}


// 备用函数
// ------------------------------------------------------------------------------


export const  generateRandomWave = (length, min, max, amplitude, noise = 0) => {
    // function generateRandomWave(length, min, max, amplitude, noise = 0) {
        if (length <= 0 || amplitude <= 0 || min >= max) {
            throw new Error('Invalid input parameters');
        }
    
        let result = [];
        let previousValue = Math.random() * (max - min) + min;
    
        for (let i = 0; i < length; i++) {
            let change = (Math.random() - 0.5) * 2 * amplitude; // Change can be between -amplitude and +amplitude
            let newValue = previousValue + change;
            
            // Ensure the value stays within the min and max range
            if (newValue < min) {
                newValue = min;
            } else if (newValue > max) {
                newValue = max;
            }
    
            // Add noise if noise is not zero
            if (noise !== 0) {
                let noiseValue = (Math.random() - 0.5) * 2 * noise; // Noise can be between -noise and +noise
                newValue += noiseValue;
                
                // Ensure the value stays within the min and max range after adding noise
                if (newValue < min) {
                    newValue = min;
                } else if (newValue > max) {
                    newValue = max;
                }
            }
    
            result.push({ tag: newValue });
            previousValue = newValue;
        }
    
        return result;
    }


export const findMinNonZero = (arr) => {
    let min = Infinity;
    let index = -1;

    for(let i = 0; i < arr.length; i++) {
        if(arr[i] !== 0 && arr[i] < min) {
            min = arr[i];
            index = i;
        }
    }
    
    return {index: index, value: min};
}



export const anaWave = (data) => {
    const depthThresholdPercent = 0.1;
    const widthThreshold = 2;
    const peakWidth = 2; // 根据需要设置这个值
    return(findValleys(data, depthThresholdPercent, widthThreshold,peakWidth))
}


function findValleys(arr, depthThresholdPercent, widthThreshold, peakWidth) {
    const n = arr.length;
    const valleys = new Array(n).fill(0);

    const R = Math.max(...arr) - Math.min(...arr);
    const depthThreshold = depthThresholdPercent * R;

    // 计算波形数据的平均值
    const avg = arr.reduce((sum, val) => sum + val, 0) / n;

    const valleyInfo = [];

    for (let i = 1; i < n - 1; i++) {
        // 检查是否是波谷点
        if (arr[i] >= arr[i - 1] || arr[i] >= arr[i + 1]) {
            continue;
        }

        // 找左边的山峰
        let leftPeak = arr[0];
        for (let l = i - 1; l >= 0; l--) {
            if (arr[l] > leftPeak) {
                leftPeak = arr[l];
            }
        }

        // 找右边的山峰
        let rightPeak = arr[i + 1];
        for (let r = i + 1; r < n; r++) {
            if (arr[r] > rightPeak) {
                rightPeak = arr[r];
            }
        }

        // 计算深度D
        const D = ((leftPeak + rightPeak) / 2) - arr[i];
        if (D < depthThreshold) {
            continue;
        }

        // 计算宽度W
        let leftWidth = 0;
        for (let l = i - 1; l >= 0 && arr[l] > arr[i]; l--) {
            leftWidth++;
        }

        let rightWidth = 0;
        for (let r = i + 1; r < n && arr[r] > arr[i]; r++) {
            rightWidth++;
        }

        const W = leftWidth + rightWidth;
        if (W >= widthThreshold && arr[i] <= avg) {
            // 计算Delta
            let delta = 0;

            for (let w = 1; w <= widthThreshold; w++) {
                if (i - w >= 0) {
                    delta += arr[i] - arr[i - w];
                }
                if (i + w < n) {
                    delta += arr[i] - arr[i + w];
                }
            }
            
            valleyInfo.push({ index: i, value: arr[i], delta: Math.abs(delta), width: W });
        }
    }

    if (valleyInfo.length === 0) {
        return valleys;
    }

    // 计算山谷高度评分和Delta评分
    const maxDelta = Math.max(...valleyInfo.map(v => v.delta));
    const minDelta = Math.min(...valleyInfo.map(v => v.delta));
    const maxHeight = Math.max(...valleyInfo.map(v => v.value));
    const minHeight = Math.min(...valleyInfo.map(v => v.value));

    for (let i = 0; i < valleyInfo.length; i++) {
        const heightScore = 1 - (valleyInfo[i].value - minHeight) / (maxHeight - minHeight);
        const deltaScore = (valleyInfo[i].delta - minDelta) / (maxDelta - minDelta);
        const widthAdjustment = peakWidth / (valleyInfo[i].width + peakWidth);
        valleyInfo[i].score = 0.5 * heightScore + 0.5 * deltaScore * widthAdjustment;
    }

    // 按评分排序，取前25%
    valleyInfo.sort((a, b) => b.score - a.score);

    const topPercentCount = Math.ceil(valleyInfo.length * 0.5);

    for (let i = 0; i < topPercentCount; i++) {
        valleys[valleyInfo[i].index] = valleyInfo[i].value;
    }

    return valleys;
}





        