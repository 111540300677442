
import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Space,Button ,message,Slider,Radio,Checkbox,Modal,Popconfirm } from 'antd';
import { TextArea} from 'antd-mobile'
import {env_serverAddress} from './lib_env'
import {fetch_by_json} from './lib_fetch'
import {Popup} from 'antd-mobile'
import { 
	SendOutlined,
	LoadingOutlined,
	SettingOutlined,
	UserOutlined,
	UserAddOutlined,
	CloseOutlined,
	CloseCircleOutlined,
	CloudUploadOutlined,
	CheckCircleOutlined,
	MenuOutlined,
	DeleteOutlined,
	RedoOutlined,
	EditOutlined,
	CloudDownloadOutlined,
	PlusCircleOutlined,
	FormatPainterOutlined
} from '@ant-design/icons';

import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'
import Page_AIGC_Image2image from "./Page_AIGC_Image2image";
import Page_predicateWindow from "./Page_predicateWindow";
import { PieChart, Pie } from 'recharts'
import Page_AILab from './Page_AILab';
import Page_AIAnalysize from './Page_AIAnalysize';
import Page_AIAnalysize_img from './Page_AIAnalysize_img';
import csvData_softbank from './static/softbank.json';
import csvData_kyoto from './static/kyoto.json';

import video_softBank from './static/softbank.mp4';
import video_kyoto from './static/kyoto.mp4';

// import Page_AIAnalysize_harusugi from './Page_AIAnalysize_harusugi';


// -------------------------







class Image2image extends Component {

	constructor(props){
        super(props);
        this.myRef_input_uploadImage=React.createRef();
	}
	
	//pinState
	state={
		ifShowAIGC:false,
		ifShowAIAnalysize:false,
		temp_Page_AIGC_image2image_ifGenerated:false,
		temp_Page_AIGC_image2image_ifChangedPage:false,
		temp_Page_AIGC_image2image_ifSavedImage:false,
		ifShowPredicateWindow:false,
		ifShowAILab:false,
		ifDownloading:false,
		
		predicateWindowTitle:"",

		item_selected_list_check:[],
		AILab_ifClearingChatHistory:false,
		AILab_ifClearingImgHistory:false,
		AILab_ifClearingImgHistory_byIndex:false,
		AILab_chatGPT_history:[],
		AILab_img_history:"NONE",
		AILab_img_ifGenerating:0,
		item_selected:false,


	}


	// ------------------------------------------
	// pinAILab

	//pinTalk
	AILab_talk2AI =async(chatHistory,content)=>{


		var filename = this.state.item_selected.filename.split(".")[0].split("/")[1]
		var sourceFile = this.state.item_selected.sourceFile

		// console.log(sourceFile)

		// console.log(filename)
		// this.setState({ ifShowPromptTips: false });
		var content = window.global.input_text_chat	
		if(content == ""){
			message.error('Please input the conversation content first 😥');
		}
		else{
			// this.Ref_TextInput.current.clear()
			// var chatHistory = this.state.AILab_chatGPT_history
			chatHistory.push({role:"user",content:content})
			var temp_chatHistory = JSON.parse(JSON.stringify(chatHistory))
			temp_chatHistory.push({role:"assistant",tag:"WAITTING_FOR_GPT"})
			this.setState({AILab_chatGPT_history:temp_chatHistory})

			// chatHistory.pop()
			

			this.setState({ifUploading:true});
			// setTimeout(()=>{this.scrollToBottom()},100)
			var result = await fetch_by_json(
				env_serverAddress+"neuroai/ailab_talk2AI",
				{
					message:content,
					chatHistory:chatHistory,
					filename:filename,
					sourceFile:sourceFile,
				})
			
			this.setState({ifUploading:false})
			console.log(result)

			if(result != 0){  
				// this.AILab_fetchChatHistory(filename)
				this.setState({AILab_chatGPT_history:result.returnData})
				// setTimeout(()=>{this.scrollToBottom()},10)
			}else{
				this.setState({AILab_chatGPT_history:chatHistory})
			}
		}	
	} 


	AILab_fetchChatHistory = async(filename) =>{
		
		this.setState({ifDownloading:true})
		console.log(filename)
		var result = await fetch_by_json(env_serverAddress+"neuroai/ailab_fetchchathistory",{filename:filename})
		console.log(result)
		if(result != 0){
			if(result.returnData != 0){
				this.setState({AILab_chatGPT_history:result.returnData["messageHis"]})
				// setTimeout(()=>{this.scrollToBottom()},10)
			}
			this.setState({ifShowAILab:true})
		}
		this.setState({ifDownloading:false})
	}

	AILab_fetchChatHistory_img = async() =>{

		if(this.state.item_selected){

			try{
				var filename = this.state.item_selected.filename.split(".")[0].split("/")[1]
				// console.log(filename)
				var result = await fetch_by_json(env_serverAddress+"neuroai/ailab_fetchchathistory_img",{filename:filename})
				// console.log(result)
				
				
				if(result.returnData != 0){
					console.log(result.returnData)
					var temp = result.returnData
					var AILab_img_history = []

					if(temp.history.length != 0){
						var temp_AILab_img_history = temp.history
						temp_AILab_img_history.reverse()
						

						var index = temp_AILab_img_history.length - 1
						for (var i in temp_AILab_img_history){
							AILab_img_history.push({
								index:index,
								sourceFile:temp.history[i]["sourceFile"]
							})
							index -= 1
						}
						this.setState({AILab_img_history:AILab_img_history})	
					}else{
						this.setState({AILab_img_history:"NONE"})
					}
					// console.log("11")
					var img_ifGenerating = temp.ifGenerating
					var time_lastUpdated = temp.time_lastUpdated
					this.setState({AILab_img_ifGenerating:img_ifGenerating})
					console.log(img_ifGenerating)				
				}

				// if(result.returnData == 0){
				// 	// console.log("0")
				// 	this.setState({AILab_img_history:"NONE"})
				// 	this.setState({AILab_img_ifGenerating:false})
				// }


			}catch(error){}
			
		}
	}


	AILab_clearChatHistory = async() =>{
		var filename = this.state.item_selected.filename.split(".")[0].split("/")[1]
		this.setState({AILab_ifClearingChatHistory:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/ailab_clearchathistory",{filename:filename})
		console.log(result)
		if(result != 0){this.AILab_fetchChatHistory(filename)}
		this.setState({AILab_ifClearingChatHistory:false})
	}

	AILab_clearImgHistory = async() =>{
		var filename = this.state.item_selected.filename.split(".")[0].split("/")[1]
		this.setState({AILab_ifClearingImgHistory:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/ailab_clearimghistory",{filename:filename})
		console.log(result)
		if(result != 0){this.AILab_fetchChatHistory_img()}
		this.setState({AILab_ifClearingImgHistory:false})
	}

	AILab_clearImgHistory_byIndex = async(index) =>{
		var filename = this.state.item_selected.filename.split(".")[0].split("/")[1]
		this.setState({AILab_ifClearingImgHistory_byIndex:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/ailab_clearimghistory_byIndex",{filename:filename,index:index})
		console.log(result)
		// if(result != 0){this.AILab_fetchChatHistory_img()}
		this.setState({AILab_ifClearingImgHistory_byIndex:false})
		this.AILab_fetchChatHistory_img()
	}




	onChange_prompt = (e) =>{

		//console.log(e)
		this.setState({prompt:e})
	}


	
	handleUploadImage_contentList = async(e)=> {

		console.log("uploading..")
		try{
			//console.log(command)
			//console.log(this.state.gptid_current)
			e.preventDefault();	
			var url = env_serverAddress+"neuroai/uploadimage_contentList"
			let file = e.target.files[0];
			const formdata = new FormData();
			this.setState({ifUploading:true,currentImg:"deleted"})

			// 一般上传--------------------------------------------------------
			
			var info = JSON.stringify({
				password:this.props.password,
				size:file.size
			})
			formdata.append('file', file);
			formdata.append('info', info);


			let response= await fetch(url,{
				method: 'post',
				body: formdata,
			})
			let result = await response.json()
			
			//console.log(result)

			if(result.statusCode == 11){
				message.config({top: 3,duration: 2,});message.success('image uploaded!');
				this.setState({currentImg:result.returnData})
				this.props.callBack_reloadData()

				// reset item_selected_list_check
				this.setState({item_selected_list_check:[]})
			}

			//pin1

			
		}
		catch(error){
		
			message.config({top: 3,duration: 2,});message.error('image too large');
		}
		// 重置input元素的value属性
		e.target.value = '';
		this.setState({ifUploading:false})
		
	}

	handleUploadImage_contentList_byUrl = async(url)=> {

		this.setState({AILab_ifClearingImgHistory_byIndex:true})
		// this.setState({AILab_ifClearingImgHistory:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/ailab_uploadimage_contentList_byurl",{url:url})
		console.log(result)
		// if(result != 0){this.AILab_fetchChatHistory_img()}
		// this.setState({AILab_ifClearingImgHistory:false})

		if(result.statusCode == 11){
			message.config({top: 3,duration: 2,});message.success('image uploaded!');
			this.setState({currentImg:result.returnData})
			this.props.callBack_reloadData()

			// reset item_selected_list_check
			this.setState({item_selected_list_check:[]})
			this.setState({ifShowAILab:false})
		}
		this.setState({AILab_ifClearingImgHistory_byIndex:false})
		
	}



	deleteImage = async(item)=> {

		//console.log(item)
		try{
			
			this.setState({ifUploading_useStableDiffusion:true,currentImg_after:"deleted"})
			var url = env_serverAddress+"neuroai/deleteimage_contentList"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					password:this.props.password,
					item:item
					
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			// console.log(result)
			
			if(result == 0){
				message.error("Error!!")
			}else{
				this.props.callBack_reloadData()

			}
	
		}
		catch(error){
			
		}

	}


	

	onChange_checkBox=(e)=>{



		var item_selected_list_check = this.state.item_selected_list_check
		
		
		var ifExists = false

		var new_item_selected_list_check = []

		
		for(var i=0;i<item_selected_list_check.length;i++){
			var item = item_selected_list_check[i]

			if(e.index == item.index){ifExists = true}
			else{
				new_item_selected_list_check.push(item)
			}
		}
		if(!ifExists){
			new_item_selected_list_check.push(e)
		}

		console.log(new_item_selected_list_check)

		this.setState({item_selected_list_check:new_item_selected_list_check})



	}	

	downLoadImage = (item) =>{

		var name = item.name
		var url = item.sourceFile
		// 创建一个新的临时<a>标签
		const link = document.createElement("a");
		link.href = url;
		link.download = name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	
	
	componentDidMount(){
		setInterval(()=>{
			var ifShowAILab = this.state.ifShowAILab
			if(ifShowAILab){
				this.AILab_fetchChatHistory_img()
			}
		}, 4000)
		
		

	}
	componentDidUpdate(){

	}
	
	
	render() {

		// console.clear()
		// console.log(this.props.dataset_contentList)
		var contentList_here = []
		


		var dataset_contentList = this.props.dataset_contentList
		for(var i=0;i<dataset_contentList.length;i++){
			var item = dataset_contentList[i]

			var update_time = item.time_created

			
			
			// console.log(item)
			if(update_time == undefined)
			{
				update_time = "unknown"
			}else{
				update_time = new Date(update_time * 1000).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(/\//g, '-').replace(/\//g, '月').replace(/,/g, '日 ').replace(/\s/g, ' ');
			}
			var filename = item.filename.split("_")[3]
			var file_name = item.file_name

			if(file_name != undefined){
				filename = file_name
			}
			var size = item.size
			if(size == undefined){
				size = 0
			}else{
				// size = size / 1024
				size = (size / 1024).toFixed(0);
			}

			contentList_here.unshift({
				filename:item.filename,
				size:size,
				index:i+1,
				ifAnalysizing:false,
				name:filename,
				update_time:update_time,
				status:1,segment:1,
				detail:"dddddddd",
				sourceFile:item.sourceFile,
				type:"picture",
				ifAllowAIEditing:true,
			})	

			// console.log(item)
			
		}
		// contentList_here.unshift( {
		// 	index:"s",
		// 	ifAnalysizing:false,
		// 	name: "harusugi",
		// 	update_time:"2077-7-7 17:00",
		// 	status:1,
		// 	segment:1,
		// 	detail:"dddddddd",
		// 	sourceFile:"0",
		// 	type:"video",
		// 	ifAllowAIEditing:false,
		// })
		contentList_here.unshift( {
			index:"s",
			ifAnalysizing:false,
			name: "kyoto",//"京都サンプル動画",
			update_time:"2077-7-7 17:00",
			status:1,
			segment:1,
			detail:"dddddddd",
			sourceFile:"0",
			type:"video",
			ifAllowAIEditing:false,
			csvData:csvData_kyoto,
			video:video_kyoto,
		})
		contentList_here.unshift( {
			index:"s",
			ifAnalysizing:false,
			name: "softbank",
			update_time:"2077-7-7 17:00",
			status:1,
			segment:1,
			detail:"dddddddd",
			sourceFile:"0",
			type:"video",
			ifAllowAIEditing:false,
			csvData:csvData_softbank,
			video:video_softBank,
		})

		contentList_here.push( {index:"s",ifAnalysizing:true,name:"これはサンプル画像になります、これはサンプル画像になります、これはサンプル画像になります",update_time:"2077-7-7 17:00",status:1,segment:1,detail:"dddddddd",sourceFile:"0"})









		var currentImg = this.state.currentImg
		var currentImg_after = this.state.currentImg_after

		//var height_memberBar = this.myRef_member_div.current.clientHeight
		var dataset = this.props.dataset
		// console.log(dataset)
		if(dataset.length > 0){
			var lastData = dataset[dataset.length-1]

			// console.log(lastData)
			var sourceFile = lastData["sourceFile"]
			var afterFile = lastData["afterFile"]

			if(sourceFile != undefined){
				currentImg = sourceFile
			}
			if(afterFile != undefined){
				// currentImg_after = afterFile
			}
		}

		// 如果State里面不为空,则优先展示
		if(this.state.currentImg){
			currentImg = this.state.currentImg
		}
		if(this.state.currentImg_after){
			currentImg_after = this.state.currentImg_after
		}

		if(this.state.currentImg == "deleted"){
			currentImg = false
		}
		if(this.state.currentImg_after == "deleted"){
			currentImg_after = false
		}

		var width = this.props.width
		var width_full = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight


		var ifSelectedAnyItem = false
		if(this.state.item_selected_list_check.length == 0){ifSelectedAnyItem = true}

		var sourceFile_imgEdit_history_temp = 0
		try{
			sourceFile_imgEdit_history_temp = this.state.item_selected.sourceFile
		}
		catch(error){
			sourceFile_imgEdit_history_temp = 0
		}

		
		// pinReturn
		return(
		
			<div 
				style={{
				height:this.props.height,
				width:this.props.width,
				//backgroundColor:"#ffff00",
				overflowY:"hidden",
				overflowX:"hidden"
				}}>	

				
				<div 
					style={{
					height:"auto",
					marginLeft:20,
					//marginTop:20,
					width:this.props.width,
					//backgroundColor:"#ffff00",
					overflowY:"hidden",
					overflowX:"hidden",
					//display: "inline-block",
			
					}}>
					{/* -------------------------------------------------- */}
					<div style={{height:15}}/>


					<div style={{
						width:this.props.width-70,
						backgroundColor:"#ffffff",
						boxShadow: "0 8px 10px -6px #d1d1d1",
						borderRadius:15,
						padding:20

						}}>
						<Space>
							<div style={{marginLeft:0,fontSize:"14px",fontWeight:"bold"}}>
								コンテンツタイプ： 
							</div>

							<Radio.Group
								defaultValue="a"
								size="small"
								style={{
									marginTop: -10,
								}}
								>
								<Radio.Button value="a">全て</Radio.Button>
								<Radio.Button value="b">写真</Radio.Button>
								<Radio.Button value="c">動画</Radio.Button>
								<Radio.Button value="d">音声</Radio.Button>
								<Radio.Button value="e">テキスト</Radio.Button>
							</Radio.Group>
						</Space>

						<div style={{height:15}}/>
						<Space>
							<div style={{marginLeft:0,fontSize:"14px",fontWeight:"bold"}}>
								予測タイプ： 
							</div>


							
							<Button value="a"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"知覚"})
								}}>
								知覚
							</Button>

							<Button value="b" 
								disabled={ifSelectedAnyItem}
								type="primary"
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"GAP"})
								}}>
								GAP
							</Button>

							<Button value="c"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"広告効果"})
								}}>
								広告効果
							</Button>

							<Button value="d"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"好感度"})
								}}>
								好感度
							</Button>

							<Button value="e"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"行動意向"})
								}}>
								行動意向
							</Button>

							<Button value="f"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"印象度"})
								}}
								>
								印象度
							</Button>

							<Button value="g"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"好み・嗜好"})
								}}>
								好み・嗜好
							</Button>

							<Button value="h"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"記憶定着度"})
								}}>
								記憶定着度
							</Button>

							<Button value="i"
								type="primary"
								disabled={ifSelectedAnyItem}
								onClick={()=>{
									this.setState({ifShowPredicateWindow:true})
									this.setState({predicateWindowTitle:"アテンション"})
								}}>
								アテンション
							</Button>
							
							
						</Space>


						<div style={{height:15}}/>
						<Space>
							<div style={{marginLeft:0,fontSize:"14px",fontWeight:"bold"}}>
								フォルダー管理： 
							</div>

							<Radio.Group
								defaultValue="a"
								size="small"
								style={{
									marginTop: -10,
								}}
								>
								<Radio.Button value="a">Contents</Radio.Button>
							
							</Radio.Group>
						</Space>

						<div style={{height:25}}/>

						<Button 
							disabled = {this.state.ifUploading}
							icon={this.state.ifUploading ?(<LoadingOutlined/>):(<PlusCircleOutlined />)} type="primary" style={{}}
							onClick = {()=>{
								this.myRef_input_uploadImage.current.click()
							}}
							>コンテンツ追加
						</Button>

						<div style={{height:0}}/>

						
					{/* <div style={{height:15}}/> */}
					</div>

					<div style={{width:width-30,
						// marginTop:-20,
						height:5,
						}}>
						{/* <Divider/> */}
					</div>
					
					

					<div 
						style={{
						height:this.props.height-240,
						width:this.props.width,
						//height:"300",
						//backgroundColor:"#ffff00",
						// display: "inline-block",
						overflowX:"hidden",
						overflowY:"scroll",
						}}>
						
						{/* pin1 */}


						{/* <div style={{height:0}}/> */}

						
						
						{contentList_here.map(item =>{


							var index_here = item.index
							var ifSelected = false

							var item_selected_list_check = this.state.item_selected_list_check
							for (var i=0;i<item_selected_list_check.length;i++){
								var item_2 = item_selected_list_check[i]
								if(item_2.index == index_here){ifSelected = true}
							}

							var size = item.size
							try{
								var filename = item.filename.split(".")[0].split("/")[1]
							}
							catch(error){}


							return(
								<div 
									style={{
									height:120,
									width:width-40,
									backgroundColor: ifSelected?("#f1f1f1"):("#ffffff") ,
									boxShadow: "0 8px 10px -6px #d1d1d1",
									marginTop:20,
									// marginBottom:10,
									borderRadius:15,
									paddingLeft:10,
									
									}}
									
									>
									
									<div style={{height:10}}/>
									<Space style={{height:100}}>


										<div 
											style={{float:"left",
											width:50,height:100,cursor:"pointer"
											
											// backgroundColor:"#00ff00"
											}}
											onClick={()=>{this.onChange_checkBox(item)}}
											>
												{/* <Checkbox 
													onChange={()=>{this.onChange_checkBox(item)}}
													> */}
													{item.index}
												{/* </Checkbox> */}

											
										</div>


										<div 
											style={{float:"left",
											width:120,height:100,
											
											// backgroundColor:"#ff00f0"
											}}>
											<ImageComponent src={item.sourceFile=="0" ?(backgourndPaper):(item.sourceFile)} width={100} height={100}
												
												/>
										</div>
										

										<Space 
											style={{float:"left",
											height:100,
											// backgroundColor:"#F1F1F1"
											}}>
												
											
											<div 
												style={{float:"left",
												width:(width-175-280)/2,height:100,
												// backgroundColor:"#ff1111",
												overflowX:"hidden",
												position:"relative",
												cursor:"pointer"
												}}
												onClick={()=>{this.onChange_checkBox(item)}}
												>
												

												<div 
													style={{
													marginLeft:0,
													fontSize:"20px",
													fontWeight:"bold",
													height:60,
													overflowY:"scroll"
													
													}}>
													{item.name}
												</div>

												<p	
													style={{
													position:"absolute",
													bottom:-10

													}}>
													{item.update_time}
												</p>
												

																								
													{/* <div style={{position:"absolute",right:20,top:10,fontSize:40}}>
														{item.ifAnalysizing ?
														
														(
															<LoadingOutlined/>
														
														):(
														
															<CheckCircleOutlined style={{fontSize:60,color:"#d8d8d8"}}/>
														)}
													</div> */}
												


													
											</div>




										

											<div 
												style={{float:"left",
												width:(width-175-280)/2,height:100,
												// backgroundColor:"#F1F1F1",
												borderRadius:15,
												paddingLeft:20,
												// paddingTop:10,
												color:"#b8b8b8",
												position:"relative",
												cursor:"pointer"
												}}
												onClick={()=>{this.onChange_checkBox(item)}}
												>
												

												<div 
													style={{
													position:"absolute",
													left:-10,
													top:0,
													}}>
													<Divider type="vertical" style={{height:100}}/>
												</div>
												

												<div style={{marginLeft:0,fontSize:"20px",fontWeight:"bold"}}>
													
												</div>
												{item.ifAnalysizing ?
														
													(
														<LoadingOutlined style={{fontSize:20,color:"#d8d8d8"}}/>
													
													):(
													
														<CheckCircleOutlined style={{fontSize:20,color:"#d8d8d8"}}/>
													)
												}

												<p style={{marginTop:2}}>size: {size} KB</p>
												<p style={{marginTop:-10}}>description: これは見本文章になります</p>
												<p style={{marginTop:-15}}>Version: m1.9</p>
												

													
											</div>





											{/* pinEdit */}
											<div 
												style={{float:"left",
												width:175,height:100,
												position:"relative",
												// backgroundColor:"#F1F100"
												}}>


												<div 
													style={{
													position:"absolute",
													left:-20,
													top:0,
													}}>
													<Divider type="vertical" style={{height:100}}/>
												</div>


												{item.ifAnalysizing ?(
													<div 
														style={{
														marginLeft:10,
														color:"#c8c8c8",
														fontWeight:"bold",
														textAlign:"center",
														// display: "flex",
														alignItems: "center", /* 垂直居中 */
														justifyContent: "center", /* 垂直居中 */
														}}>
															
														
														{/* <LoadingOutlined/> */}
														{/* <div style={{height:40}}/> */}
														<LoadingOutlined style={{fontSize:60}}/><br/>
														<div style={{height:10}}/>
														{/* 分析中<br/> */}
														
														<div style={{fontSize:20,fontWeight:"normal"}}>
														分析中（1）
														</div>
														
														<br/>
															
													</div>
												):(
													<div 
														style={{
														marginLeft:10
														}}>

														<Space>	

															{/* <Button type="default" icon={<RedoOutlined />}> 再解析</Button>
															<Button type="default" icon={<EditOutlined />}> コンテンツ名変更</Button>
															<Button type="default" icon={<CloudDownloadOutlined />}> ダウンロード</Button>
															<Button type="text" danger icon={<DeleteOutlined />}> 削除</Button> */}
															
															<Button disabled type="default" icon={<RedoOutlined />}/>
															<Button disabled type="default" icon={<EditOutlined />}/>
															<Button type="default" icon={<CloudDownloadOutlined />}
																
																onClick = {()=>{
																	this.downLoadImage(item)
																}}
																/>


															<Popconfirm
																title="Delete the task"
																description="Are you sure to delete this task?"
																onConfirm={()=>{this.deleteImage(item)}}
																// onCancel={cancel}
																okText="Yes"
																cancelText="No"
																>
																<Button type="text" danger icon={<DeleteOutlined />}/>
															</Popconfirm>

															




														</Space>
														<div style={{height:10}}/>
														<Button style={{height:50,width:72}} type="primary" 
															disabled={this.state.ifDownloading}
															// icon={this.state.ifDownloading && (this.state.filename == filename) ? (<LoadingOutlined/>):(<FormatPainterOutlined />)}
															onClick = {()=>{
																console.log("ifShowAIAnalysize")
																this.setState({item_selected:item,filename:filename})
																this.setState({ifShowAIAnalysize:true})
																// if(item.type === "picture"){
																// 	this.setState({item_selected:item,filename:filename})
																// 	this.AILab_fetchChatHistory(filename)
																// 	this.setState({AILab_img_history : "LOADING"})
																// }
																// if(item.type === "video"){
																// 	console.log("ifShowAIAnalysize")
																// 	this.setState({item_selected:item,filename:filename})
																// 	this.setState({ifShowAIAnalysize:true})
																// }
																
														}}
															>AI分析
														</Button>
														<Button style={{height:50,width:71,marginLeft:10}} type="primary" 
															disabled={this.state.ifDownloading || !item.ifAllowAIEditing}
															// icon={this.state.ifDownloading && (this.state.filename == filename) ? (<LoadingOutlined/>):(<FormatPainterOutlined />)}
															onClick = {()=>{
																
																this.setState({item_selected:item,filename:filename})
																
																// console.log(item.filename.split(".")[0].split("/")[1])
																this.AILab_fetchChatHistory(filename)
																// this.AILab_fetchChatHistory_img(filename)
																this.setState({AILab_img_history : "LOADING"})
																
														}}
															>AI編集
														</Button>
														
													
														{/* <CloudUploadOutlined style={{fontSize:20,marginTop:5}}/> */}
														

															
													</div>
												)}
												



												
												

													
											</div>


										</Space>



									</Space>
									<div style={{height:12}}/>
									{/* <div style={{width:width-50,height:2,marginTop:-25}}><Divider/></div> */}
									
								</div>
								
								
							)
							
						})}
						




						
						
						<div style={{height:50}}/>
						

						
						
					</div>
					
				</div>
		
				{/* pinAIAnalysize */}
				<Popup
                    position='bottom'
                    onMaskClick={() => {
                        this.setState({ifShowAIAnalysize:false})
                      }}
                    onClose={() => {
                        this.setState({ifShowAIAnalysize:false})
                    }}
                    showCloseButton
                    visible={this.state.ifShowAIAnalysize}
                    bodyStyle={{
                    height: height,
                    }}
                    >
						{/* item.type === "video" */}
					{this.state.item_selected.type === "video" && this.state.ifShowAIAnalysize && (
						 <Page_AIAnalysize
					
						 item_selected = {this.state.item_selected}
						 password = {this.props.password}
						 />
					)}
					{this.state.item_selected.type === "picture" && this.state.ifShowAIAnalysize && (
						 <Page_AIAnalysize_img
					
						 item_selected = {this.state.item_selected}
						 password = {this.props.password}
						 />
					)}
					{/* {this.state.item_selected.name == "harusugi" &&(
						 <Page_AIAnalysize_harusugi
					
						 item_selected = {this.state.item_selected}
						 password = {this.props.password}
						 />
					)} */}
                   


                </Popup>

				<Modal
                    title="AIGC"
                    centered
                    open={this.state.ifShowAIGC}
                    // onOk={this.open_ifShowFileExplorer}
                    onCancel={()=>{this.setState({ifShowAIGC:false})}}
                    width={600}
					// height={height*0.8}
                    okText = {"done"}
                    footer = {null}
                    >
                    <Page_AIGC_Image2image
						width={600}
						height={height-50}
						password={this.props.password}
						dataset={this.props.dataset}
						ifGpuIsWorking={this.props.ifGpuIsWorking}
						ifChecking_GpuIsWorking={this.props.ifChecking_GpuIsWorking}
						item_selected = {this.state.item_selected}
						temp_Page_AIGC_image2image_ifGenerated = {this.state.temp_Page_AIGC_image2image_ifGenerated}

						callback_IfGenerated = {()=>{this.setState({temp_Page_AIGC_image2image_ifGenerated:true})}}

						/>




                </Modal>
				
				<p>
					{/* pin1 */}
					<input 
						id = "uploadimg" type="file" accept="image/png, image/jpeg, image/jpg"
						onChange={this.handleUploadImage_contentList}
						ref={this.myRef_input_uploadImage}
						style={{display:"none",}}
						/>
						
				</p>



				<Popup
                    position='bottom'
                    onMaskClick={() => {
                        this.setState({ifShowPredicateWindow:false})
                      }}
                    onClose={() => {
                        this.setState({ifShowPredicateWindow:false})
                    }}
                    showCloseButton
                    visible={this.state.ifShowPredicateWindow}
                    bodyStyle={{
                    height: height,
                    }}
                    >
                    <Page_predicateWindow
						item_selected_list_check = {this.state.item_selected_list_check}
						predicateWindowTitle = {this.state.predicateWindowTitle}
						password = {this.props.password}

						status_API_openai = {this.props.status_API_openai}
					/>


                </Popup>


				{/* pinAiLab */}
				<Popup
					showCloseButton
					position='bottom'
					onClose={() => {
						this.setState({ifShowAILab:false})
					}}
					//showCloseButton
					visible={this.state.ifShowAILab}
					bodyStyle={{
					height: height,
					width:width_full,
					}}
					>
					
					<Page_AILab

						
						// img_history = {[
						// 	{index:0,sourceFile:""},
						// 	{index:1,sourceFile:""},
						// ]}
						img_history = {this.state.AILab_img_history}
						img_history_ifGenerating = {this.state.AILab_img_ifGenerating}
						// img_history_ifGenerating = {true}
						item_selected = {this.state.item_selected}
						chatGPT_history = {this.state.AILab_chatGPT_history}
						AILab_talk2AI = {this.AILab_talk2AI}
						ifUploading = {this.state.ifUploading}

						AILab_ifClearingChatHistory = {this.state.AILab_ifClearingChatHistory}
						AILab_ifClearingImgHistory = {this.state.AILab_ifClearingImgHistory}
						AILab_ifClearingImgHistory_byIndex = {this.state.AILab_ifClearingImgHistory_byIndex}
						
						AILab_clearChatHistory = {this.AILab_clearChatHistory}
						AILab_clearImgHistory = {this.AILab_clearImgHistory}
						AILab_clearImgHistory_byIndex = {this.AILab_clearImgHistory_byIndex}
						
						handleUploadImage_contentList_byUrl = {this.handleUploadImage_contentList_byUrl}

						password = {this.props.password}

					/>
				</Popup>






			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Image2image);