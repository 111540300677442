
import React, { Component} from "react";
import {Image} from 'antd';


class ImageComponent extends Component {

    

    render() {
      const { src, width, height } = this.props;
      var imgStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }

      if(this.props.ifRadius){
        imgStyle = {
          width: '110%',
          height: '110%',
          objectFit: 'cover',
          borderRadius:15,
        };
      }

      const divStyle = {
        width: width,
        height: height,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }

      var ifShowPreview = true
      if(ifShowPreview != undefined){
        ifShowPreview = this.props.ifShowPreview
      }

      return (
        <div style={divStyle}>
          {/* {src == undefined ?(""):(
            <img src={src} alt="img" style={imgStyle}/>

            

          )} */}
          <Image
            width={this.props.width}
            height={this.props.height}
            style={imgStyle}
            src={src}
            preview ={ifShowPreview}

          />
          
        </div>
      );
    }
  }
  
  export default ImageComponent;