import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import { Space} from 'antd';



//-------------------------------
import { 
	CaretRightOutlined,
	LoadingOutlined,
	AppstoreOutlined,
    InfoCircleOutlined,
    CheckCircleFilled,
    CloseCircleFilled
} from '@ant-design/icons';



class Compo_footer extends Component {

    //pinState
	state={
        ifHasGPU:false, 
        // GPUInfo:{
        //     ram:"13.2/24GB"
        // }
	
	}

    render(){

        var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight

        var ifHasGPU = false
        if(this.props.ifHasGPU != undefined){
            ifHasGPU = this.props.ifHasGPU
        }

        var status_API_openai = this.props.status_API_openai
        // console.log(status_API_openai)
        
        return(
            <div 
                style={{
                position:"fixed",
                width:width,
                bottom:0,left:0,
                height:20,
                backgroundColor:"#a1a1a1",
                
                }}>
                <Space 
                    style={{fontSize:12,width:width*0.75-30,
                    // backgroundColor:"#ffff00",
                    overflowY:"hidden",
                    }}>
                    
                    <div 
                        style={{
                        backgroundColor:ifHasGPU ?("#3875F6"):("#ff0000"),
                        height:20,
                        width:100,
                        paddingLeft:10,
                        paddingTop:3,
                        color:"#ffffff"
                        }}>
                        <InfoCircleOutlined style={{marginRight:5}}/>
                        {ifHasGPU?(
                            "GPU Online"
                        ):(
                            "GPU Offline"
                        )}
                        
                    </div>
                    <div 
                        style={{
                        // backgroundColor:this.state.ifHasGPU ?("#3875F6"):("#ff0000"),
                        height:20,
                        width:150,
                        paddingLeft:5,
                        paddingTop:2,
                        color:"#ffffff",
                        }}>
                        RAM: 0 / 0GB remain
                    </div>
                    {/* <div 
                        style={{
                        // backgroundColor:this.state.ifHasGPU ?("#3875F6"):("#ff0000"),
                        height:20,
                        width:70,
                        paddingLeft:5,
                        paddingTop:2,
                        color:"#ffffff",
                        }}>
                        CPU: 0%
                    </div> */}
                    {/* <div 
                        style={{
                        // backgroundColor:this.state.ifHasGPU ?("#3875F6"):("#ff0000"),
                        height:20,
                        width:150,
                        paddingLeft:5,
                        paddingTop:2,
                        color:"#ffffff",
                        }}>
                        DISK: 0 / 0GB remain
                    </div> */}

                    <div 
                        style={{
                        // backgroundColor:this.state.ifHasGPU ?("#3875F6"):("#ff0000"),
                        height:20,
                        width:300,
                        paddingLeft:5,
                        paddingTop:2,
                        color:"#ffffff",
                        }}>
                        <Space style={{fontSize:12}}>
                            API : ReplicateAI ●
                            &nbsp; StablityAI ○
                            &nbsp; OpenAI {status_API_openai==1 ?("●"):(<LoadingOutlined style={{fontSize:12,marginLeft:-2,marginTop:-1}}/>)}  
                        </Space>
                        {/* API : Replicate <div style={{marginLeft:2,color:"#05FF00"}}>●</div>
                        &nbsp; StablityAI<CloseCircleFilled style={{marginLeft:2,color:"#ff0000"}}/>
                        &nbsp; OpenAI<CheckCircleFilled style={{marginLeft:2,color:"#05FF00"}}/> */}
                    </div>

                    

                </Space>
                    <div 
                        style={{
                        position:"fixed",
                        right:0,
                        bottom:0,
                        fontSize:12,
                        // backgroundColor:"#dd00dd",
                        height:17,
                        width:width/2,
                        paddingLeft:5,
                        paddingTop:2,
                        color:"#ffffff",
                        textAlign:"right",
                        marginRight:10
                        }}>
                        {/* REPLICATE: API cold rebooting,takes 3~5m */}
                        No infomation
                    </div>
                

            </div>
        )
    }

}


                

export default withRouter(Compo_footer)