
// import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
// import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Space,Button ,message,Slider,Tag,Input} from 'antd';
// import { TextArea,Popup} from 'antd-mobile'
// import {env_serverAddress} from './lib_env'
import { 
	SendOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import video_kyoto from "./static/kyoto.mp4"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import kyotoData_raw from './static/kyoto.json';
import {anaWave,movingAverageFilter,generateRandomWave,findWaveTrough,convertTroughToArray} from './lib_anaWave';


function transformData(data) {
    // 初始化一个空对象用于存储转换后的数据
    let transformedData = {};

    // 遍历输入的数据对象
    for (let key in data) {
        let currentData = data[key];
        for (let tag in currentData) {
            if (!transformedData[tag]) {
                transformedData[tag] = [];
            }
            transformedData[tag].push(currentData[tag]);
        }
    }

    return transformedData;
}

function restoreData(data) {
    let transformedData = [];
    let tags = Object.keys(data);
    
    for (let i = 0; i < data[tags[0]].length; i++) {
        let obj = {};
        tags.forEach(tag => {
			if(data[tag][i] != 0){
				obj[tag] = data[tag][i];
			}
            
        });
        transformedData.push(obj);
    }

    return transformedData;
}





const colorList = [
	"#FF7700",
	"#05C3FD",
	"#22CC14",
	"#FF0099",
	"#8A8A8A",
]


const list_chikaku = [
	"記憶定着度", 
	"試用意向", 
	"愛用意向", 
	"購買意向", 
	"好み／嗜好",
	"美しい",
	"都会的", 
	"可愛い",
	"高級", 
	"男性的", 
	"陽気",
	"現代的", 
	"シンプル", 
	"暖かい",
	"繊細",
	"知的", 
	"穏やか", 
	"人間的", 
	"清潔", 
	"静か",
	"醜い", 
	"田舎っぽい", 
	"気持ち悪い", 
	"安っぽい", 
	"女性的", 
	"陰気", 
	"伝統的",
	"複雑", 
	"涼しい", 
	"大胆", 
	"滑稽",
	"躍動的",
	"機械的", 
	"不潔", 
	"騒がしい", 
	"広告効果", 
	"好感度", 
]



var currentSec = 0
var thumbnailURL = 0


// 创建自定义的Tooltip组件
const CustomTooltip = ({ active, payload, label,tagsInUse,setSec,captureThumbnail}) => {
	if (active && payload && payload.length) {
	  // 在这里处理payload，例如保存到state或者执行其他逻辑
	  console.log(tagsInUse)
	//   console.log(payload[0].payload); // 这里是当前点的详细信息
		
	  let currentTime = label
	  let currentData = payload[0].payload
	  
	  setSec(currentTime)
	  thumbnailURL = captureThumbnail()
	//   console.log(thumbnailURL)

	  return (
		<div style={{
			backgroundColor:"#ffffff",
			padding:10,
			borderRadius:15
			}}>
			<img src={thumbnailURL} alt="Thumbnail" />
			<p className="label">{`时间: ${currentTime}`}</p>
			{tagsInUse.map(item=>{
				return(
					<p style={{color:colorList[item.id]}} >{`${item.tag}: ${currentData[item.tag]?.toFixed(5)}`}</p>
				)
			})}
		</div>
	  );
	}
	return null;
};



class Kyoto extends Component {

	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
	}
	
	//pinState
	state={
		userName:false,
		ifConnected:false,
		sec:0,
		tagsInUse:[
			"好感度"
			// "伝統的な",
			// "静かな・落ち着きのある",
		],
		ifAnaWave:true,
		windowSize:3,
	}

	handle_login = () => {
		if(this.state.password == "Neuroai123"){
			this.setState({ifShowWelcome:false})
		}else{
			message.error("Wrong")
		}

	}

	createPassword_1 =(value)=>{
		// console.log(value.target.value)
		this.setState({password:value.target.value})
	}

	onChange =(e)=>{
		// console.log(e)
		this.setState({sec:e})
	}

	captureThumbnail = () => {
		const canvas = document.createElement('canvas');
		canvas.width = 320; // 设置画布宽度为 160
		canvas.height = 180; // 设置画布高度为 90
		canvas.getContext('2d').drawImage(this.videoRef.current, 0, 0, 320, 180); // 绘制视频画面到画布
		
		thumbnailURL = canvas.toDataURL(); // 获取画面的 base64 编码
		// this.setState({ thumbnailURL }); // 更新截图的 base64 编码到状态中
		// console.log(thumbnailURL)
		return thumbnailURL
	};

	handleTag = (tag) =>{
		console.log(tag)
		var tagsInUse = this.state.tagsInUse
		
		if(tagsInUse.includes(tag)){
			// console.log("yes")
			var tagsInUse_ = tagsInUse.filter(item => item !== tag);
			// console.log(tagsInUse_)
			this.setState({tagsInUse:tagsInUse_})
		}else{
			if(tagsInUse.length <= 4){
				tagsInUse.push(tag)
				this.setState({tagsInUse:tagsInUse})
			}else{
				message.error("折れ線は５本まで")
			}
			
		}
		
	}
	handleTooltip = (data) => {
        console.log('Tooltip data:', data);
        // 在这里编写处理 Tooltip 数据的代码
    }
	


	componentDidMount() {
		this.videoRef.current.currentTime = this.state.sec; // 设置视频到指定秒数
		this.videoRef.current.pause(); // 暂停视频自动播放
	  }

	handleMove = () =>{
		this.videoRef.current.currentTime = currentSec; // 根据 sec 变量更新视频当前秒数
		this.videoRef.current.pause(); // 暂停视频自动播放
	}
	
	// componentDidUpdate(prevProps, prevState) {
	// 	this.videoRef.current.currentTime = currentSec; // 根据 sec 变量更新视频当前秒数
	// 	this.videoRef.current.pause(); // 暂停视频自动播放
		
	// }
	setSec = (sec) =>{
		// this.setState({sec:sec})
		currentSec = sec
		// console.log(currentSec)
	}

	render() {


		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var kyotoData = 0

		//pin1
		if(this.state.ifAnaWave){
			var waveData_t1 = transformData(kyotoData_raw) //{tag1:[1,2,3,4],tag2:[....]....}

			var waveData_t2 = {}
			for (var tag in waveData_t1){
				console.log(waveData_t1[tag])

				
				var temp = movingAverageFilter(waveData_t1[tag], this.state.windowSize)
				
				const windowLength = this.state.windowSize
				var temp2 = findWaveTrough(temp, windowLength)
				console.log(temp)
				console.log(temp2)
				const waveLength = temp.length + 1
				var temp3 = convertTroughToArray(waveLength,temp2)
				
				
				waveData_t2[tag] = temp
				waveData_t2[tag+"_low"] = temp3
				// console.log(waveData_t2[tag+"_low"])

				
			}
			kyotoData = restoreData(waveData_t2)
		}else{
			kyotoData = this.state.waveData
		}
		// console.log(waveData) //[{tag1:n,tag2:n,...}]
		




		// if(this.state.ifAnaWave){
		// 	var kyotoData_t1 = transformData(kyotoData_raw) //{tag1:[1,2,3,4],tag2:[....]....}

		// 	var kyotoData_t2 = {}
		// 	for (var tag in kyotoData_t1){
		// 		var temp = movingAverageFilter(kyotoData_t1[tag], this.state.windowSize)
		// 		kyotoData_t2[tag] = temp
		// 		console.log("temp")
		// 		console.log(temp)
		// 		kyotoData_t2[tag+"_low"] = anaWave(temp)
		// 		console.log(tag)
		// 		console.log(kyotoData_t2[tag+"_low"])
		// 	}
		// 	kyotoData = restoreData(kyotoData_t2)
		// }else{
		// 	kyotoData = kyotoData_raw
		// }
		// console.log(kyotoData) //[{tag1:n,tag2:n,...}]
		




		var kyotoData_current = []
		for (var i in kyotoData){
			// 每一秒的数据
			var item = kyotoData[i]
			var newItem = {}

			// 每一秒中有哪些tag
			for (let k in item){
				newItem[k] = item[k]
				// newItem["test1"] = 5
			}

			newItem["name"] = i // 在图表显示中需要
			kyotoData_current.push(newItem)
		}

		console.log(kyotoData_current)




		var tagsInUse = []
		for (let i in this.state.tagsInUse){
			var tag = this.state.tagsInUse[i]
			tagsInUse.push({
				id:i,
				tag:tag
			})
			tagsInUse.push({
				id:i+10,
				tag:tag+"_low"
			})
		}

		// tagsInUse.push({id:999,tag:"test1"})
		// console.log(thumbnailURL)

		return(
		
			<div 
				style={{
				height:height,
				position:"absolute",left:width-this.props.width,
				width:this.props.width-0,
				backgroundColor:"#ffffff",
				overflowY:"scroll",
				overflowX:"hidden",
				// marginTop:20,
				// marginLeft:20,
				}}>	
				
				
				<div style={{height:20}}/>
				<div style={{
					float:"left",
					width:width-310,
					height:30,
					backgroundColor:"#f1f1f1",
					borderRadius:15,
					marginLeft:20,
					padding:10,
					overflow:"hidden",
					}}>
					
					
					<div style={{height:5}}/>

					<div style={{overflowX:"scroll",height:40}}>
						<Space size={[0, 8]} >
							{list_chikaku.map((tag)=>{
								var color=false
								if(this.state.tagsInUse.includes(tag)){
									color="#000000"
								}
								return(
									<Tag
										onClick={()=>{this.handleTag(tag)}} 
										color={color &&(color)}>{tag}</Tag>
								)
							})}	
						</Space>
					</div>
					
					

				</div>
				<Space style={{marginLeft:25,marginTop:10}}>
					{/* <Input value={this.state.windowSize}
						onChange={(e)=>{
							console.log(e.target.value)
							this.setState({windowSize:e.target.value})
						}}
						/> */}
					
					<Button
						onClick = {()=>{
							this.setState({ifAnaWave:!this.state.ifAnaWave})
						}}
						style={{color:!this.state.ifAnaWave && "#d8d8d8"}}
						type={this.state.ifAnaWave ? "primary":"default"}
					>
						{this.state.ifAnaWave ? "波形分析":"波形分析"}
					</Button>

					<Slider
						style={{width:100}}
						min={0}
						max={20}
						onChange={(e)=>{
							this.setState({windowSize:e})
						}}
						value={typeof this.state.windowSize === 'number' ? this.state.windowSize : 0}
					/>


				</Space>
				
				<div style={{height:10}}/>
				<LineChart
					style={{marginLeft:-50}}
					onMouseMove = {this.handleMove}
					width={width-200}
					height={height-200}
					data={kyotoData_current}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
					>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis 
					// domain={[0, 40]} 
					/>
					{/* <Tooltip /> */}
					{/* <Tooltip label={this.handleTooltip} /> */}
					<Tooltip content={
						<CustomTooltip 
							tagsInUse = {tagsInUse}
							setSec = {this.setSec}
							captureThumbnail = {this.captureThumbnail}
							/>
						} 
						/>
					<Legend />
					{tagsInUse.map((item)=>{
						return(
							<Line type="monotone" dataKey={item.tag} stroke={colorList[item.id]} strokeWidth={2} activeDot={{ r: 8 }} />
						)
					})}
					{/* <Line type="monotone" dataKey="伝統的な" stroke={colorList[0]} strokeWidth={2} activeDot={{ r: 8 }} />
					<Line type="monotone" dataKey="静かな・落ち着きのある" stroke={colorList[1]} strokeWidth={2} activeDot={{ r: 8 }} /> */}
				</LineChart>


				<div style={{marginLeft:70}}>
					<video 
						style={{display:"none"}}
					ref={this.videoRef} controls={false} onSeeked={this.captureThumbnail}>
					<source src={video_kyoto} type="video/mp4" />
					Your browser does not support the video tag.
					</video>
					{/* {thumbnailURL && <img src={thumbnailURL} alt="Thumbnail" />} */}
				</div>
				
				{/* <div style={{height:200}}/> */}
				





			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Kyoto);