import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
import { Divider,Popconfirm,Typography,Space,Button ,message,Tooltip as Tooltip_antd,Tag,Input,Modal,Image,Dropdown,Skeleton,Slider,Segmented} from 'antd';
import { Popup, Loading} from 'antd-mobile'
import {env_serverAddress} from './lib_env'
import {fetch_by_json} from './lib_fetch'
import ReactMarkdown from 'react-markdown';

import { 
	InfoCircleOutlined,
	SendOutlined,
	LoadingOutlined,
	SettingOutlined,
	BulbOutlined,
	AppstoreOutlined,
	CloseOutlined,
	CloseCircleOutlined,
	CloudUploadOutlined,
	CheckCircleOutlined,
	ExperimentOutlined,
	DeleteOutlined,
	RedoOutlined,
	FileTextOutlined,
	DownOutlined
} from '@ant-design/icons';

// import emoji_graph_a from "./static/emoji_graph_a.png";
// import emoji_graph_b from "./static/emoji_graph_b.png";
import emoji_zoom from "./static/emoji_zoom.png";
import emoji_stethoscope from "./static/emoji_stethoscope.png";

// import bg_neuroai from "./static/bg_neuroai.png"
// import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'
// import video_csv from "./static/kyoto.mp4"
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import csvData from './static/kyoto.json';
// import Compo_Tag from "./Compo_Tag.tsx"
//-------------------------------
// import logo_dplanner from "./static/dplanner.png"
// const { Search } = Input;	
// const { Title, Paragraph, Text, Link } = Typography;
const { TextArea } = Input;

class Page_AIAnalysize extends Component {

	constructor(props){
        super(props);
        this.myRef_input_uploadCSV=React.createRef();
		this.topRef = React.createRef();
		this.reportRef = React.createRef();
		this.generateButtonRef = React.createRef();
		this.consoleEndRef = React.createRef();
		this.myRef_input_uploadCSV=React.createRef();
	}


	//pinState
	state={
		usecase:[],
		csvData:false,
		ifFetchingCSV:false,
	}




	// pinFetch
	fetchCSV = async()=> {
		// 清空旧数据
		this.setState({csvData:false,ifFetchingCSV:true})
		try{	
			var url = env_serverAddress+"neuroai/tellcsv"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					filename:this.props.item_selected.name,
					password:this.props.password,
					type:"AIAnalysize"
					
				}), 
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			console.log(result)
			if(result == 0){
				this.setState({csvData:false})
			}
			else{
				this.setState({csvData:result.returnData})
			}
		}
		catch(error){
		}
		this.setState({ifFetchingCSV:false})
	}


	uploadCSV = async(e)=> {
		try{
			e.preventDefault();	
			var url = env_serverAddress+"neuroai/uploadcsv"
			let file = e.target.files[0];
			const formdata = new FormData();

			var info = JSON.stringify({
				filename:this.props.item_selected.name,
				password:this.props.password,
				type:"AIAnalysize"

			})
			formdata.append('file', file);
			formdata.append('info', info);


			let response= await fetch(url,{
				method: 'post',
				body: formdata,
			})
			let result = await response.json()
			
			if(result.statusCode == 11){
				message.config({top: 3,duration: 2,});message.success('image uploaded!');
				
			}

			//pin1

			
		}
		catch(error){
		
			message.config({top: 3,duration: 2,});message.error('image too large');
		}
		// 重置input元素的value属性
		e.target.value = '';
		this.setState({ifUploading:false})
		
	}
	
	deleteUse = async(item) =>{
		console.log(item.id)
		this.setState({ifDownloading:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/deleteuse",{
			id :item.id,
			filename:this.props.item_selected.name,
			password:this.props.password
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifDownloading:false})
		this.fetchData()
	}


	//pinAddAuto
	addUsecaseAuto = async() =>{
		console.log(this.props.item_selected)
		var title = "自動分析"
		const source = {
			csv:this.state.csvData,
			// average:{
			// 	"好感度":5.6,
			// 	"好み／嗜好":0.7,
			// 	"記憶定着度":0.845
			// }
		}
		const newUse = {
			time:0,
			title:title,
			type:"auto",
			content:"testing",
			url:this.props.item_selected.sourceFile,
			source:source
			
		}
		console.log(newUse)
		await this.addNewUse(newUse,"auto")
		await this.fetchData()
		const timer = setTimeout(() => {
			this.scrollToBottom()
		}, 100);
		
	}


	//pinAddUse
	addNewUse = async(newUse) =>{
		console.log(newUse)
		this.setState({ifDownloading:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/addnewuse_img",{
			newUse :newUse,
			filename:this.props.item_selected.name,
			password:this.props.password
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifDownloading:false})
	}

	// pinF
	fetchData = async() =>{
		// console.log(this.props.password)
		// console.log(this.props.item_selected.name)
		this.setState({ifDownloading:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/fetchdata",{
			filename:this.props.item_selected.name,
			password:this.props.password
		})
		console.log(result)
		if(result && result.returnData){
			this.setState({usecase:result.returnData.history.reverse()})
		}
		this.setState({ifDownloading:false})
	}

	componentDidMount() {
		console.clear()
		console.log(this.props.item_selected)

		this.fetchData()
		this.fetchCSV()

		setInterval(()=>{
			this.fetchData()
		}, 7000)
		

	  }

	scrollToBottom = () => {
		this.consoleEndRef.current.scrollIntoView({ behavior: "smooth" });
	}


	updateReport = async() =>{
		console.log(this.state.currentReport)

		this.setState({ifDownloading:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/updatereport",{
			id :this.state.currentId,
			filename:this.props.item_selected.name,
			password:this.props.password,
			content:this.state.currentReport,
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifDownloading:false})
		this.fetchData()

	}

	checkReport = async(item) =>{
		console.log(item.id)
		this.setState({ifChecking:true})
		var result = await fetch_by_json(env_serverAddress+"neuroai/checkreport",{
			id :item.id,
			filename:this.props.item_selected.name,
			password:this.props.password,
			content:item.content
			
		})
		console.log(result)
		if(result && result.returnData){
			// this.setState({usecase:result.returnData.history})
		}
		this.setState({ifChecking:false})
		this.fetchData()
	}

	render() {

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var width_leftBar = 350
		var width_middle = width - width_leftBar 



		// pinReturn
		return(

			<div style={{width:width,height:height,position:"fixed",left:0,top:0}}
				>
				{/* ---------------------------------------------------- */}

				
				<div style={{width:width,height:height,position:"absolute",
					left:0,top:0}}
					>
					{/* pinLeft */}
					<div 
						style={{
						width:width_leftBar,			
						backgroundColor:"#f1f1f1",
						height:height,
						float:"left",
						//padding:20,
						

						}}>
						
						<div style={{
							width:width_leftBar-40,
							height:200,
							backgroundColor:"#d8d8d8",
							marginLeft:20,marginTop:30,
							borderRadius:15,
							textAlign:"center",
							display: "grid",
							placeItems: "center",
							fontSize:30,fontWeight:"bold",
							color:"#f1f1f1",
							overflow:"hidden"
							}}>
							<div style={{
								// marginLeft:5,marginTop:5,
								width:width_leftBar-50,height:190,
								overflow:"hidden",borderRadius:10,}}>
								<ImageComponent 
									width={width_leftBar-50} height={190}
									src={this.props.item_selected.sourceFile}
									/>
							</div>
							{/* コンテンツ閲覧 */}
						</div>
						{/* pin1 */}

						
					</div>
					
					{/* ---------------------------------------------------- */}

					{/* pinRight */}
					<div 
						style={{
						width:width_middle,	
						// borderRadius:15,		
						// backgroundColor:"#ffffff",
						height:height,
						float:"left",
						//padding:20,
						overflowY:"scroll",
						overflowX:"hidden"
						}}>
						<div 
							style={{
							width:width_middle,	
							// borderRadius:15,		
							// backgroundColor:"#ffffff",
							height:"auto",
							// float:"left",
							//padding:20,
							}}>
							<div style={{height:10}}/>
							<div style={{height:2,width:width_middle-40,marginLeft:20}}><Divider/></div>
							<div style={{height:40,width:width_middle-40,marginLeft:20,position:"relative"}}>
								<h2 style={{marginTop:5,position:"absolute",left:0,top:0}}>新規分析($dev:img)</h2>
								<Button 
									style={{
										position:"absolute",right:0,top:5,
										color:!this.state.csvData ? "#ff0000":""
									}} 
									size="small" type="link"
									onClick = {()=>{
										this.myRef_input_uploadCSV.current.click()
									}}
									icon={this.state.ifFetchingCSV && (<LoadingOutlined/>)}
								>{this.state.ifFetchingCSV ? "loading...":"$dev:uploadCSV"}</Button>
								{this.state.csvData && (
									<Button 
										style={{position:"absolute",right:120,top:5}} 
										size="small" type="link"
										onClick = {()=>{
											// this.copyCSV()
											console.log(this.state.csvData)
											// alert(list_csv)
											var jsonString = JSON.stringify(this.state.csvData);
											navigator.clipboard.writeText(jsonString);
											message.success("copied to clipboard✋")
										}}
									>$dev:copyJson</Button>
								)}
								
								
							</div>
							
							<div style={{
								overflowX:"scroll",
								height:150,
								padding:10,
								marginLeft:10,
								width:width_middle-50,
								// marginTop:10
								}}>
								<Space size={[0, 8]} >

								
									<Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowCreateNewUsecase_Auto:true})}}
										>
											<Space>
												<ImageComponent src={emoji_stethoscope} width={72} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>自動分析 A</div>
													<div style={{
														// backgroundColor:"#ff0000",
														height:40,fontSize:13,width:170,
														fontWeight:"normal",textAlign:"left",
														wordWrap:"break-word",
														whiteSpace:"pre-wrap"
													}}>コンテンツの課題を抽出し、改善策を提供する.</div>
												</div>
											</Space>
									</Button>
									{/* <div style={{width:10}}></div>
									<Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowCreateNewUsecase_Auto:true})}}
										>
											<Space>
												<ImageComponent src={emoji_stethoscope} width={72} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>自動分析 B</div>
													<div style={{
														// backgroundColor:"#ff0000",
														height:40,fontSize:13,width:170,
														fontWeight:"normal",textAlign:"left",
														wordWrap:"break-word",
														whiteSpace:"pre-wrap"
													}}>コンテンツの課題を抽出し、改善策を提供する.</div>
												</div>
											</Space>
									</Button>
									<div style={{width:10}}></div>
									<Button
										type="ghost"
										style={{
											width:350,
											height:150,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowCreateNewUsecase_Auto:true})}}
										>
											<Space>
												<ImageComponent src={emoji_stethoscope} width={72} height={70}/>
												<div style={{width:2}}/>
												<div>
													<div style={{fontSize:20,width:170,fontWeight:"bold",textAlign:"left"}}>自動分析 C</div>
													<div style={{
														// backgroundColor:"#ff0000",
														height:40,fontSize:13,width:170,
														fontWeight:"normal",textAlign:"left",
														wordWrap:"break-word",
														whiteSpace:"pre-wrap"
													}}>コンテンツの課題を抽出し、改善策を提供する.</div>
												</div>
											</Space>
									</Button> */}
									
								</Space>
							</div>
								
							{this.state.usecase.length != 0 ? (
								<div style={{marginLeft:20}}>
									<div style={{height:10}}/>
									<div style={{height:2,width:width_middle-40,}}><Divider/></div>
									<div style={{height:40,width:width_middle-40,position:"relative"}}>
										<h2 style={{marginTop:5,position:"absolute",left:0,top:0}}>分析履歴</h2>
										<Button style={{position:"absolute",right:0,top:5}} size="small" type="link">すべての機能</Button>
									</div>
									{/* ------------------------------------ */}
									{/* 结尾定位用标签 */}
									<div
										ref={this.consoleEndRef}
										className="console-end"
										style={{ display: "block" }}
									></div>
									{/* ------------------------------------ */}
									{this.state.usecase.map((item)=>{

										var score = false
											if(item.score){
												try{
													score = JSON.parse(item.score)["score"]
												}catch(error){
													// console.log(item.score)
												}
												try{
													score = JSON.parse(item.score.split("```")[1].split("json")[1])["score"]
												}catch(error){
													// console.log(item.score)
												}
												
											}

										if(item.type==="auto"){

											var score = false
											if(item.score){
												try{
													score = JSON.parse(item.score)["score"]
												}catch(error){
													// console.log(item.score)
												}
												try{
													score = JSON.parse(item.score.split("```")[1].split("json")[1])["score"]
												}catch(error){
													// console.log(item.score)
												}
												
											}

											return(
												<div style={{marginBottom:30,}}>
													<div style={{
														backgroundColor:"#ffffff",//"#f1f1f1",//"#f5f5f5",
														// marginLeft:20,
														width:width_middle-40,	
														height:"auto",
														borderRadius:15,
														position:"relative",
														boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
														}}>
														{/* <div style={{height:20}}/> */}
														
														<Space style={{marginTop:0,marginLeft:20}}>
															<ImageComponent src={emoji_zoom} width={20} height={20}/>
															<h3 >自動分析</h3>
															<div style={{width:2}}/>
															{item.status === "loading" && (
																<Button 
																	type="link"
																	icon={<LoadingOutlined/>}
																	// style={{fontSize:15,color:"#d8d8d8"}}
																	>
																	分析中
																</Button>
													
															)}
															

															
															{(item.status === "done" )&&(
																<div>
																	<Button size="small" type="link"
																		onClick = {()=>{
																			
																		}}
																		icon={<FileTextOutlined/>}
																		>
																		PDFに出力
																	</Button>
																</div>
															)}
															
														
														</Space>
														<Popconfirm
															title="Delete the task"
															description="Are you sure to delete this task?"
															onConfirm={()=>{this.deleteUse(item)}}
															// onCancel={cancel}
															okText="Yes"
															cancelText="No"
															>
															<Button
																style={{position:"absolute",right:10,top:15}} 
																size="small" type="link" danger
																onClick = {()=>{
																	
																}}
																icon={<DeleteOutlined />}
																>
																削除
															</Button>
																
														</Popconfirm>
														<Button
															style={{position:"absolute",right:160,top:15}} 
															size="small" type="link" 
															// danger
															onClick = {()=>{this.checkReport(item)}}
															icon={this.state.ifChecking && <LoadingOutlined/>}
															>
															{score ? ("$devScore:" + score):("$devチェック")}
														</Button>
														<Button
															style={{position:"absolute",right:80,top:15}} 
															size="small" type="link" 
															// danger
															onClick = {()=>{
																this.setState({
																	ifShowEidtReport:true,
																	currentReport:item.content,
																	currentId:item.id
																})

															}}
															// icon={<DeleteOutlined />}
															>
															$dev編集
														</Button>
														
														{(item.status !== "loading" )&&(
															<div>
																<div style={{marginLeft:65,marginRight:65,position:"relative"}}>
																	<div style={{
																		// marginRight:300
																		}}>
																		{/* <div style={{position:"absolute",right:0,top:60,
																			width:290,
																			// height:200,
																			// padding:10,
																			backgroundColor:"#f1f1f1",
																			borderRadius:15
																			}}>
																			
																			<Space 
																				direction="vertical" 
																				style={{
																				marginLeft:20,
																				marginTop:10
																				}}>
																				<h3 style={{marginLeft:5}}>アドバイス</h3>
																				<Space direction="vertical" >
																					
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OOOOOOO
																					</Button>
																					<Button size="small" type="link"
																						onClick = {()=>{
																							
																						}}
																						icon={<BulbOutlined/>}
																						>
																						OOOOOOO
																					</Button>
																				</Space>

																				
																			</Space>
																			<div style={{height:30}}/>
																		</div> */}
																		<h1 style={{fontSize:30}}>{item.title}</h1>
																		<img src={item.img} style={{width:width-300}}/>
																		{item.status !== "loading" && (
																			<pre style={{fontSize:18,whiteSpace: 'pre-wrap'}}>
																				{item.content}
																			</pre>
																		)}
																		
																	</div>
																	
																</div>
																<div style={{marginLeft:60,marginRight:60}}><Divider/></div>
																<div style={{marginLeft:60,marginRight:60,marginTop:-20,color:"#d8d8d8"}}>version:xxxx</div>
																
																<div style={{height:80}}/>
															</div>
														)}
													</div>
													
												</div>
											)	
										}
										
										
									})}
									
								</div>
								
							):("")}

							<div style={{height:10}}/>
							<div style={{height:2,width:width_middle-40,marginLeft:20}}><Divider/></div>
							<div style={{height:10}}/>
							<Space 
								direction="vertical" 
								style={{
								marginLeft:20,
								marginTop:10
								}}>
								<Button size="small" type="link"
									onClick = {()=>{
										
									}}
									icon={<AppstoreOutlined/>}
									>
									生成AIポリシーについて
								</Button>
								<Button size="small" type="link"
									onClick = {()=>{
										
									}}
									icon={<InfoCircleOutlined/>}
									>
									OOOOOOOについて
								</Button>
							</Space>

							<div style={{height:100}}/>

							
							
							





								
							{/* ---------------------------------------------------- */}
						</div>

						
					</div>




				</div>
				<p>
					{/* pin1 */}
					<input 
						id = "uploadimg" type="file" accept=".csv"
						onChange={this.uploadCSV}
						ref={this.myRef_input_uploadCSV}
						style={{display:"none",}}
						/>
						
				</p>


				<Modal
					title=""
					centered
					open={this.state.ifShowCreateNewUsecase_Auto}
					// onOk={this.open_ifShowFileExplorer}
					onCancel={()=>{this.setState({ifShowCreateNewUsecase_Auto:false})}}
					width={600}
					height={height*0.9}
					// height={height*0.8}
					okText = {"done"}
					footer = {null}
					style={{overflowY:"scroll"}}
					>	
					
					<div>
						<div style={{height:2}}><Divider/></div>
						
						<div style={{height:10}}/>
						


						<div style={{height:10}}/>
						
						<Button 
							type="primary"
							onClick={()=>{
								this.addUsecaseAuto()
								this.setState({ifShowCreateNewUsecase_Auto:false})
							}}>
							分析を実行する
						</Button>
					</div>
				</Modal>

				{/* pin4 pinEditReport */}
				<Modal
						title="＄Dev - Report編集"
						centered
						open={this.state.ifShowEidtReport}
						// onOk={this.open_ifShowFileExplorer}
						onCancel={()=>{this.setState({ifShowEidtReport:false})}}
						width={800}
						height={height*0.9}
						// height={height*0.8}
						okText = {"done"}
						footer = {null}
						style={{overflowY:"auto"}}
						>	
						
						<div style={{height:"auto"}}>
							<div style={{height:2}}><Divider/></div>

							<TextArea
								value={this.state.currentReport}
								onChange={(e) => {
									this.setState({currentReport:e.target.value})
								}}
								placeholder="Controlled autosize"
								autoSize={{
								minRows: 20,
								maxRows: 20,
								}}
							/>
							<div style={{height:10}}/>
							<Button 
								type="primary"
								onClick={()=>{
									this.updateReport()
									this.setState({ifShowEidtReport:false})
								}}>
								更新
							</Button>
						</div>
					</Modal>

			
			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Page_AIAnalysize);