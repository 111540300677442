
import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Space,Button ,message,Slider,Tag,Input} from 'antd';
import { TextArea} from 'antd-mobile'
import {env_serverAddress} from './lib_env'

import { 
	SendOutlined,
	LoadingOutlined,
	SettingOutlined,
	UserOutlined,
	UserAddOutlined,
	CloseOutlined,
	CloseCircleOutlined,
	CloudUploadOutlined,
	CheckCircleOutlined,
	ExperimentOutlined
} from '@ant-design/icons';

import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'
const { Search } = Input;	





class DAssistant extends Component {

	constructor(props){
        super(props);
        this.myRef_input_uploadImage=React.createRef();
	}
	
	//pinState
	state={
		userName:false,
		ifConnected:false,
	}


	onChange_prompt = (e) =>{

		//console.log(e)
		this.setState({prompt:e})
	}

	
	componentDidMount(){
		console.clear()

		var userName_here = false
		var userName = this.getUserName()
		userName.then(function(value) {
			if (typeof value != "undefined") {
				console.log("cookie存在");
				console.log(value);
				userName_here = value
			}
		})
		setTimeout(()=>{this.setState({userName:userName_here})},10); 
		setTimeout(()=>{this.handle_login_gpt()},30); 
		

	}
	componentDidUpdate(){

	}

	storeUsername = (token) => {
		console.log(token)
		cookie.save("neuroai_demo_username", token, { path: '/' })
	}
	getUserName = async() => {
		var token_here = cookie.load("neuroai_demo_username")	
		console.log(token_here)	
		return(token_here)
	}

	// pinLogin
	handle_login_gpt = async()=> {

		console.log(this.state.userName)
		if(this.state.userName){
			this.setState({ifFetching_Login:true})
			try{
				var url = "http://18.140.156.221:5050/neuroai_login_dassistant"
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						username:this.state.userName	
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let result = await response.json()
				console.log(result)
				
				if(result == 0){
					message.error("No Such User")
				}else{
					if(result.statusCode != 11){
						message.error("No Such User")
					}
					else{
						this.storeUsername(this.state.userName)
						// this.setState({ifShowWelcome:false})
						this.setState({ifConnected:true})
						// this.setState({dataset:result.returnData})
						// this.setState({dataset_contentList:result.returnData_contentList})
						
					}
					
				}
		
	
				// message.config({top: 3,duration: 2,});message.success('image uploaded!');
			}
			catch(error){
				
			}
			this.setState({ifFetching_Login:false})

		}

		



	}
	setUsername=(value)=>{
		console.log(value.target.value)
		this.setState({userName:value.target.value})
	}
	
	
	render() {


		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		
		var width_cell = (this.props.width)/2 - 50

		// pinReturn
		return(
		
			<div 
				style={{
				height:height,
				position:"absolute",left:width-this.props.width,
				width:this.props.width-0,
				// backgroundColor:"#ffff00",
				overflowY:"scroll",
				overflowX:"hidden",
				marginTop:100,
				}}>	
				<div 
					style={{
					height:"auto",
					// marginLeft:20,
					// marginTop:20,
					width:this.props.width-20,
					height:height-100,
					// backgroundColor:"#00ff00",
					// overflowY:"scroll",
					// padding:50,
					overflowX:"hidden",
					columnCount: 2,
    				columnGap: 0,
					}}>
					{/* -------------------------------------------------- */}
					

					{!this.state.ifConnected?(
						<div style={{
							// float:"",
							width:(this.props.width)-70,
							height:height-130,
							// backgroundColor:"#ffffff",
							// borderRadius:15,
							marginLeft:20,marginBottom:20,
							padding:10,
							overflow:"hidden",
							// boxShadow: "0 8px 10px -6px #d1d1d1",
							textAlign:"center",
							display: "flex",
							alignItems: "center", /* 垂直居中 */
							justifyContent: "center", /* 水平居中 */
							}}>
	
							<div style={{color:"#b1b1b1",width:500}}>
								<div style={{fontSize:220,}}>
									<ExperimentOutlined />
									
								</div>
								{/* <div style={{fontSize:45,
									fontWeight:"bold",
									}}>
									Connect to GPT
								</div> */}
								<div style={{fontSize:20,
									fontWeight:"normal",
									}}>
									Enter your Username and Connect to GPTs
								</div>
								<Search 
									style={{maxWidth:600,marginTop:20}}
									placeholder="username" 
									onChange={this.setUsername}
									onPressEnter={()=>{
										console.log("haha")
										this.handle_login_gpt()
									}}
									onSearch={()=>{
										console.log("haha")
										this.handle_login_gpt()
									}}
									size='large'
									loading={this.state.ifFetching_Login}
									disabled={this.state.ifFetching_Login}
									name="gptname"
									type="text"
									/>
								

							</div>
	
							
							{/* <div style={{
									width:"100%",
									float:"left",
								}}>
								
								<div style={{height:10}}/>
								
								<Search 
									style={{maxWidth:500}}
									placeholder="username" 
									onPressEnter={()=>{
										console.log("haha")
									}}
									onSearch={()=>{
										console.log("haha")
									}}
									// loading 
									/>
							</div> */}
						</div>
					):("")}

					{this.state.ifConnected?(
						<div style={{
							float:"left",
							width:width_cell,
							height:300,
							backgroundColor:"#ffffff",
							borderRadius:15,
							marginLeft:20,marginTop:20,
							padding:10,
							overflow:"hidden",
							boxShadow: "0 8px 20px -6px #d1d1d1",
							breakInside: "avoid",
							// minWidth:500,
							}}>
	
							<div style={{
								textAlign:"left",color:"#000000",
								overflow:"hidden",height:20,marginLeft:5,fontSize:"15px",fontWeight:"bold"
								}}>
								GPT Connected
							</div>
							<div style={{height:20}}/>
							<div>
								
							</div>
						</div>
					):("")}


					{this.state.ifConnected?(
						<div style={{
							float:"left",
							width:width_cell,
							height:100,
							backgroundColor:"#ffffff",
							borderRadius:15,
							marginLeft:20,marginTop:20,
							padding:10,
							overflow:"hidden",
							boxShadow: "0 8px 20px -6px #d1d1d1",
							breakInside: "avoid",
							}}>
	
							<div style={{
								textAlign:"left",color:"#000000",
								overflow:"hidden",height:20,marginLeft:5,fontSize:"15px",fontWeight:"bold"
								}}>
								SQL検索
							</div>
							<div style={{height:20}}/>
							<div>
								{/* <Button>haha</Button> */}
								<div style={{height:10}}/>
								
								<Search 
									style={{maxWidth:500}}
									placeholder="username" 
									onPressEnter={()=>{
										console.log("haha")
									}}
									onSearch={()=>{
										console.log("haha")
									}}
									// loading 
									/>
							</div>
						</div>
					):("")}

					{this.state.ifConnected?(
						<div style={{
							float:"left",
							width:width_cell,
							height:100,
							backgroundColor:"#ffffff",
							borderRadius:15,
							marginLeft:20,marginTop:20,
							padding:10,
							overflow:"hidden",
							boxShadow: "0 8px 20px -6px #d1d1d1",
							breakInside: "avoid",
							}}>
	
							<div style={{
								textAlign:"left",color:"#000000",
								overflow:"hidden",height:20,marginLeft:5,fontSize:"15px",fontWeight:"bold"
								}}>
								SQL検索
							</div>
							<div style={{height:20}}/>
							<div>
								{/* <Button>haha</Button> */}
								<div style={{height:10}}/>
								
								<Search 
									style={{maxWidth:500}}
									placeholder="username" 
									onPressEnter={()=>{
										console.log("haha")
									}}
									onSearch={()=>{
										console.log("haha")
									}}
									// loading 
									/>
							</div>
						</div>
					):("")}
				</div>



			</div>






			
			
			
			
		)

  	}
}

export default withRouter(DAssistant);