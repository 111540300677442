

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Tooltip} from 'antd';

import { Space, Upload ,Popover,Menu,Avatar} from 'antd';
// import { Button} from 'antd';
// import { message,Drawer} from 'antd';
// import * as CryptoJS from 'crypto-js'
// import cookie from 'react-cookies'
// import { MailOutlined, SettingOutlined } from '@ant-design/icons';
// import banner from "../static/bg.jpg"

import { 
	RightOutlined,
	LoadingOutlined,
	UserOutlined,
	UserAddOutlined,
	CloseOutlined,
	AppstoreOutlined,
	PlusCircleOutlined,
	CloudUploadOutlined,
	PlusOutlined,
} from '@ant-design/icons';


const { Title, Paragraph, Text, Link } = Typography;




class Compo_card_small_user extends Component {


	
	//pinState
	state={
		//switch_ifSureLeaveroom:false,
	}
	
	
	render() {

		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		
		var title = this.props.title
		var description = this.props.description
		var icon = this.props.icon

		if(title === undefined){title = "undefined"}
		if(description === undefined){description = "undefined"}
		if(icon === undefined || icon == 0){icon = false}

		var iconType = "img"
		if(icon == "newRobot"){
			iconType = "newRobot"
		}
		if(icon == "importRobot"){
			iconType = "importRobot"
		}


		// pinReturn
		return(
		
			

			<div 
				style={{
					
					height:80,
					width:250,
					//backgroundColor:"#ffff00"
					}}>
					<div 
						onClick = {this.props.callBack}
						style={{
							
							margin:10,
							backgroundColor:"#f1f1f1",
							height:80,
							borderRadius:15,
							cursor:"pointer"
							
						}}>
							<div style={{height:10}} />
						<Space style={{paddingLeft:10}}>
							{icon ?(

								<div>
									{iconType=="newRobot" ?(
										<Avatar
											style={{
											backgroundColor:"#000000",
											width:60,height:60,
											}}
											icon={<PlusOutlined style={{fontSize:30,marginTop:15}}/>}
											/>
									):(
										""
									)}

									{iconType=="importRobot" ?(
										<Avatar
											style={{
											backgroundColor:"#000000",
											width:60,height:60,
											}}
											icon={<CloudUploadOutlined style={{fontSize:30,marginTop:15}}/>}
											/>
									):(
										""
									)}
									
									{iconType=="img" ?(
										<img src={icon} style={{width:60}} />
									):(
										""
									)}


								</div>
								
								
							):(
								<Avatar
									style={{
									backgroundColor:"#000000",
									width:60,height:60,
									}}
									icon={<UserOutlined style={{fontSize:30,marginTop:15}}/>}
									/>
							)}
							
							<div style={{height:60,width:130,marginLeft:7}}>

								<div style={{height:7}} />
								<div style={{fontSize:18,fontWeight:"bold",height:23,overflow:"hidden",whiteSpace:"nowrap"}}>
								
								{title}
								</div>
								<div style={{fontSize:12,paddingTop:3,height:20,overflow:"hidden",whiteSpace:"nowrap"}}>
								{description}
								</div>
							</div>
						</Space>

						
					</div>
				
				




				

				


				
				

			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Compo_card_small_user);