import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import { Divider,Typography,Space,Button ,message,Slider,Tag,Input,Segmented} from 'antd';
import { 
	SendOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import video_kyoto from "./static/kyoto.mp4"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import waveData_raw from './static/kyoto.json';
import {anaWave,movingAverageFilter,tellWindowSize,findMinNonZero,findWaveTrough,addWeightToTagsOfGaussian,addWeightToTagsOfLine,addWeightToTagsOfCurved,addWeightToTagsOfGaussian2} from './lib_anaWave';


class App extends Component {

	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
	}
	

	
	
	//pinState
	state = {
		data: Array.from({ length: 200 }, (_, i) => ({ tag: `tag${i + 1}`, avg: i })),
		typeOfWeight:"線型分布"
	  };

	
	render() {


		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var waveData = 0

		var newData = this.state.data
		if(this.state.typeOfWeight == "線型分布"){
			newData = addWeightToTagsOfLine(newData);
		}
		if(this.state.typeOfWeight == "正規分布"){
			newData = addWeightToTagsOfGaussian(newData);
		}
		if(this.state.typeOfWeight == "ロジスティック回帰"){
			newData = addWeightToTagsOfCurved(newData);
		}
		if(this.state.typeOfWeight == "正規分布2"){
			newData = addWeightToTagsOfGaussian2(newData);
		}
	
		
		console.log(newData)
		return(
		
			<div 
				style={{
				height:height,
				position:"absolute",left:width-this.props.width,
				width:this.props.width-0,
				backgroundColor:"#ffffff",
				overflowY:"scroll",
				overflowX:"hidden",
				// marginTop:20,
				// marginLeft:20,
				}}>	
				
				
				<Segmented
					style={{marginLeft:20,marginTop:20}}
					options={['線型分布', 'ロジスティック回帰','正規分布','正規分布2']}
					onChange={(value) => {
					console.log(value); // string
					this.setState({typeOfWeight:value})
					}}
				/>
				<div style={{height:10}}/>
				<LineChart
					style={{marginLeft:-30}}
					// onMouseMove = {this.handleMove}
					width={width-220}
					height={height-150}
					data={newData}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
					>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="avg" />
					<YAxis 
					// domain={[0, 40]} 
					/>
					<Tooltip />
					

					<Legend />
					<Line type="monotone" dataKey="weight" stroke="#8884d8" activeDot={{ r: 8 }} />
				</LineChart>


				
				{/* <div style={{height:200}}/> */}
				





			</div>






			
			
			
			
		)

  	}
}

export default withRouter(App);