
import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Space,Button ,message,Slider,Tag,Input} from 'antd';
import { TextArea,Popup} from 'antd-mobile'
import {env_serverAddress} from './lib_env'

import { 
	SendOutlined,
	LoadingOutlined,
	SettingOutlined,
	UserOutlined,
	UserAddOutlined,
	CloseOutlined,
	CloseCircleOutlined,
	CloudUploadOutlined,
	CheckCircleOutlined,
	ExperimentOutlined
} from '@ant-design/icons';

import bg_neuroai from "./static/bg_neuroai.png"
import backgourndPaper from "./static/bg.jpg"
import ImageComponent from './Compo_ImageComponent'
import video_kyoto from "./static/kyoto.mp4"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import kyotoData from './static/kyoto.json';
// import Compo_Tag from "./Compo_Tag.tsx"




const { Search } = Input;	





const colorList = [
	"#FF7700",
	"#05C3FD",
	"#22CC14",
	"#FF0099",
	"#8A8A8A",
]


const list_chikaku = [
    "美しい",
    "醜い",	
    "都会的な",	
    "田舎っぽい",	
    "高級な",	
    "安っぽい",	
    "明るい",	
    "暗い",	
    "現代的な",	
    "伝統的な",	
    "シンプル・わかりやすい",	
    "複雑・わかりにくい	",
    "清潔な",	
    "不潔な",	
    "静かな・落ち着きのある",	
    "騒がしい・活気のある",	
    "暖かい",	
    "涼しい",	
    "居心地の良い"	,
    "居心地の悪い"	,
    "自然な",	
    "人工的な",	
    "個性的な",	
    "一般的な",	
    "親しみやすい"	,
    "親しみにくい"	,
    "変化のある",	
    "単調な",	
    "上品な",	
    "下品な",	
    "可愛い",	
    "興味深い",	
    "退屈な",	
    "楽しい",	
    "つまらない",	
    "惹かれる",	
    "嫌な",	
    "快い",	
    "不快な",	
    "安心な",	
    "不安な",	
    "信頼できる",	
    "信頼できない",	
    "悲しい",	
    "驚き",	
    "怖い",	
    "腹立たしい"
]
var currentSec = 0
var thumbnailURL = 0


// 创建自定义的Tooltip组件
const CustomTooltip = ({ active, payload, label,tagsInUse,setSec,captureThumbnail}) => {
	if (active && payload && payload.length) {
	  // 在这里处理payload，例如保存到state或者执行其他逻辑
	  console.log(tagsInUse)
	  // console.log(payload[0].payload); // 这里是当前点的详细信息
		
	  let currentTime = label
	  let currentData = payload[0].payload
	  
	  setSec(currentTime)
	  thumbnailURL = captureThumbnail()
	  console.log(thumbnailURL)

	  return (
		<div style={{
			backgroundColor:"#ffffff",
			padding:10,
			borderRadius:15
			}}>
			<img src={thumbnailURL} alt="Thumbnail" />
			<p className="label">{`时间: ${currentTime}`}</p>
			{tagsInUse.map(item=>{
				return(
					<p style={{color:colorList[item.id]}} >{`${item.tag}: ${currentData[item.tag].toFixed(5)}`}</p>
				)
			})}
		</div>
	  )
	}
	return null;
};







class Kyoto extends Component {

	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
	}
	
	//pinState
	state={
		userName:false,
		ifConnected:false,
		sec:0,
		tagsInUse:[
			"伝統的な",
			"静かな・落ち着きのある",
		],
		ifShowBigGraph:true,
	}

	handle_login = () => {
		if(this.state.password == "Neuroai123"){
			this.setState({ifShowWelcome:false})
		}else{
			message.error("Wrong")
		}
	}

	createPassword_1 =(value)=>{
		// console.log(value.target.value)
		this.setState({password:value.target.value})
	}

	onChange =(e)=>{
		// console.log(e)
		this.setState({sec:e})
	}

	captureThumbnail = () => {
		const canvas = document.createElement('canvas');
		canvas.width = 320; // 设置画布宽度为 160
		canvas.height = 180; // 设置画布高度为 90
		canvas.getContext('2d').drawImage(this.videoRef.current, 0, 0, 320, 180); // 绘制视频画面到画布
		
		thumbnailURL = canvas.toDataURL(); // 获取画面的 base64 编码
		// this.setState({ thumbnailURL }); // 更新截图的 base64 编码到状态中
		// console.log(thumbnailURL)
		return thumbnailURL
	}

	handleTag = (tag) =>{
		console.log(tag)
		var tagsInUse = this.state.tagsInUse
		
		if(tagsInUse.includes(tag)){
			// console.log("yes")
			var tagsInUse_ = tagsInUse.filter(item => item !== tag);
			// console.log(tagsInUse_)
			this.setState({tagsInUse:tagsInUse_})
		}else{
			if(tagsInUse.length <= 4){
				tagsInUse.push(tag)
				this.setState({tagsInUse:tagsInUse})
			}else{
				message.error("折れ線は５本まで")
			}
			
		}
		
	}
	handleTooltip = (data) => {
        console.log('Tooltip data:', data);
        // 在这里编写处理 Tooltip 数据的代码
    }
	


	componentDidMount() {
		this.videoRef.current.currentTime = this.state.sec; // 设置视频到指定秒数
		this.videoRef.current.pause(); // 暂停视频自动播放
	  }

	handleMove = () =>{
		this.videoRef.current.currentTime = currentSec; // 根据 sec 变量更新视频当前秒数
		this.videoRef.current.pause(); // 暂停视频自动播放
	}
	
	// componentDidUpdate(prevProps, prevState) {
	// 	this.videoRef.current.currentTime = currentSec; // 根据 sec 变量更新视频当前秒数
	// 	this.videoRef.current.pause(); // 暂停视频自动播放
		
	// }
	setSec = (sec) =>{
		// this.setState({sec:sec})
		currentSec = sec
		console.log(currentSec)
	}

	render() {


		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		
		// console.log(kyotoData)

		const sec = this.state.sec 


		// var kyotoData_current = []
		// for (let key in kyotoData[sec]){
		// 	kyotoData_current.push({
		// 		name:key,
		// 		uv:kyotoData[sec][key]
		// 	})
		// }
		var kyotoData_current = []
		for (var i in kyotoData){
			var data_here = kyotoData[i]
			var item = kyotoData[i]

			var newItem = {}
			for (let k in item){
				if(item[k] <= 0){newItem[k] = 0}
				else if(item[k] > 10){newItem[k] = 10}
				else{newItem[k] = item[k]}
				
			}
			var sec_here = 1+parseInt(i)
			newItem["name"] = ""+sec_here
			kyotoData_current.push(newItem)
		}



		// kyotoData[0].forEach(()=>{
		// 	kyotoData_current.
		// })
		var tagsInUse = []
		for (let i in this.state.tagsInUse){
			var tag = this.state.tagsInUse[i]
			tagsInUse.push({
				id:i,
				tag:tag
			})
		}
		console.log(thumbnailURL)

		return(
		
			<div 
				style={{
				height:height,
				position:"absolute",left:width-this.props.width,
				width:this.props.width-0,
				backgroundColor:"#ffffff",
				overflowY:"scroll",
				overflowX:"hidden",
				// marginTop:20,
				// marginLeft:20,
				}}>	
				
				
				<div style={{height:20}}/>
				<div style={{
					float:"left",
					width:width-310,
					height:30,
					backgroundColor:"#f1f1f1",
					borderRadius:15,
					marginLeft:20,
					padding:10,
					overflow:"hidden",
					}}>
					
					
					<div style={{height:5}}/>

					<div style={{overflowX:"scroll",height:40}}>
						<Space size={[0, 8]} >
							{list_chikaku.map((tag)=>{
								var color=false
								if(this.state.tagsInUse.includes(tag)){
									color="#000000"
								}
								return(
									<Tag
										onClick={()=>{this.handleTag(tag)}} 
										color={color &&(color)}>{tag}</Tag>
								)
							})}	
						</Space>
					</div>
					
					

				</div>
				<div style={{height:70}}/>
				<LineChart
					style={{
						marginLeft:-50,
						width:width-200,
						height:this.state.ifShowBigGraph?(height-150):((height-150)/2),
						transition: 'width 1s ease, height 1s ease',
					}}
					onMouseMove = {this.handleMove}
					onClick={()=>{this.setState({ifShowBigGraph:!this.state.ifShowBigGraph})}}
					width={width-200}
					height={this.state.ifShowBigGraph?(height-150):((height-150)/2)}
					data={kyotoData_current}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
					>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis domain={[0, 10]} />
					{/* <Tooltip /> */}
					{/* <Tooltip label={this.handleTooltip} /> */}
					<Tooltip content={
						<CustomTooltip 
							tagsInUse = {tagsInUse}
							setSec = {this.setSec}
							captureThumbnail = {this.captureThumbnail}
							/>
						} 
						/>
					<Legend />
					{tagsInUse.map((item)=>{
						return(
							<Line type="monotone" dataKey={item.tag} stroke={colorList[item.id]} strokeWidth={2} activeDot={{ r: 8 }} />
						)
					})}
					{/* <Line type="monotone" dataKey="伝統的な" stroke={colorList[0]} strokeWidth={2} activeDot={{ r: 8 }} />
					<Line type="monotone" dataKey="静かな・落ち着きのある" stroke={colorList[1]} strokeWidth={2} activeDot={{ r: 8 }} /> */}
				</LineChart>


				<div style={{marginLeft:70}}>
					<video 
						style={{display:"none"}}
					ref={this.videoRef} controls={false} onSeeked={this.captureThumbnail}>
					<source src={video_kyoto} type="video/mp4" />
					Your browser does not support the video tag.
					</video>
					{/* {thumbnailURL && <img src={thumbnailURL} alt="Thumbnail" />} */}
				</div>

				<div style={{height:500}}/>
				





			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Kyoto);