



//"http://13.113.35.236/"
// export var env_serverAddress = "http://54.238.8.142:3002/"
// export var env_serverAddress = "http://54.238.8.142:5053/"
export var env_serverAddress = "https://dp-demo.nttdata-neuroai.com/"





