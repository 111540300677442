

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Input,Modal} from 'antd';
import { TextArea,Popup} from 'antd-mobile'
import ReactMarkdown from 'react-markdown';
import { 
	Space, Tour,Upload ,
	Popover,Menu,Collapse, 
	Dropdown,Tag,Switch,FloatButton
} from 'antd';
import { Button} from 'antd'; 
import { message,Drawer} from 'antd';
import { Tooltip as Tooltip_antd } from 'antd';
import * as CryptoJS from 'crypto-js'
import cookie from 'react-cookies'
import { MailOutlined, SettingOutlined,MenuOutlined } from '@ant-design/icons';
import {env_serverAddress} from './lib_env'

//-------------------------------
import logo_dplanner from "./static/dplanner.png"
import logo_dplanner_small from "./static/neuroai_logo.png"
import bg_neuroai from "./static/bg_neuroai.png"

import backgourndPaper from "./static/bg.jpg"
import { SiOnlyoffice } from "react-icons/si";




import { 
	CaretRightOutlined,
	LoadingOutlined,
	AppstoreOutlined,
	SmileOutlined,
	DownOutlined,
	CloseCircleOutlined,
	DownloadOutlined,
	CheckCircleOutlined,
	EditOutlined,
	CopyOutlined,
	InfoCircleOutlined,
	

} from '@ant-design/icons';
import Page_Image2image from "./Page_Image2image";
import Page_prompt2image from "./Page_prompt2image";
import Page_contentList from "./Page_contentList";
import Compo_card_small from "./Compo_card_small"
import Compo_footer from './Compo_footer'
import ImageComponent from './Compo_ImageComponent'
// import Compo_Tag from "./Compo_Tag";
import Compo_Tag from "./Compo_Tag.tsx"
import Page_talk2AI from "./Page_talk2AI"


import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
  } from 'recharts';
  

// pinImport
//---------------------------------------



const { Title, Paragraph, Text, Link } = Typography;
const { Panel } = Collapse;
const { Search } = Input;	


const preventDefault = (e) => {
	e.preventDefault();
	// console.log('Clicked! But prevent default.');
  };









const items = [
	{
		key: '1',
		// danger: true,
		label: '01_電子・精密類',
	},
	{
		key: '2',
		// danger: true,
		label: '02_AV機器類',
	},
	{
		key: '3',
		// danger: true,
		label: '03_家庭電器類',
	},
	{
		key: '4',
		// danger: true,
		label: '....',
	},
];


const items_saimoku = [
	
	{
		key: '1',
		// danger: true,
		label: '....',
	},
	{
		key: '2',
		// danger: true,
		label: '....',
	},
	{
		key: '3',
		// danger: true,
		label: '....',
	},
];



class Page_predicateWindow extends Component {


	constructor(props){
        super(props);
        this.myRef_input_uploadCSV=React.createRef();
		this.topRef = React.createRef();
		this.reportRef = React.createRef();

		this.generateButtonRef = React.createRef();

	}

	
	//pinState
	state={
		
		pageTitle:"コンテンツ一覧 - 見本",
		item_selected :false,
		report :false,
		report_miniGPT:false,
		ifUploading :false,
		ifUploading_miniGPT:false,
		ifFetchingCSV:false,
		current_csv:false,
		current_item:false,

		ifShowTour_generateButton:false,
		ifShowModal_talk2AI:false,
		refreshKey_talk2AI:1,    //用于刷新组件
	
	}

	// uploadCSV = async(e)=> {

	// 	e.preventDefault();	
	// 	let file = e.target.files[0];


	// }
	scrollToTop = () => {
		this.topRef.current.scrollIntoView({ behavior: "smooth" });
	};
	scrollToReport = () => {
		this.reportRef.current.scrollIntoView({ behavior: "smooth" });
	};


	// pinFetch
	fetchCSV = async(item)=> {
		// 清空旧数据
		this.setState({
			current_csv:false,
			current_item:item,
			ifFetchingCSV:true,
			report:false,
			report_miniGPT:false,
		})

		var item_selected = item
		// console.log(item_selected)
		var filename = item_selected.name.split(".")[0]


		try{	
			// this.setState({ifUploading:true})
			var url = env_serverAddress+"neuroai/tellcsv"
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					password:this.props.password,
					filename:filename,
					type:this.props.predicateWindowTitle
					
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let result = await response.json()
			// this.setState({ifUploading:false})
			// console.log(result.returnData)
			// console.log(result.returnData_report)
			
			if(result == 0){
				// message.info("No Data")
				this.setState({current_csv:false})
				this.setState({
					report:false,
					ifFetchingCSV:false,
				})
		
			}
			else{
				this.setState({
					current_csv:result.returnData,
					ifFetchingCSV:false,
					
				})
				if(result.returnData_report != 0){
					this.setState({
						report:result.returnData_report,
					})
					
				}
				else{
					this.setState({report:false})
				}

				if(result.returnData_report_miniGPT != 0){

					// console.log(result.returnData_report_miniGPT)
					this.setState({report_miniGPT:result.returnData_report_miniGPT.content})
				}
				// setTimeout(()=>{this.scrollToReport()},1)
				// setTimeout(()=>{this.scrollToTop()},1000)
			}
		}
		catch(error){
		}
	}

	uploadCSV = async(e)=> {

		// console.log("uploading..")
		var item_selected = this.state.item_selected
		// console.log(item_selected)
		var filename = item_selected.name.split(".")[0]

		try{
			//console.log(command)
			//console.log(this.state.gptid_current)
			e.preventDefault();	
			var url = env_serverAddress+"neuroai/uploadcsv"
			let file = e.target.files[0];
			const formdata = new FormData();


			// this.setState({ifUploading:true,currentImg:"deleted"})

			// 一般上传--------------------------------------------------------
			
			var info = JSON.stringify({
				password:this.props.password,
				filename:filename,
				type:this.props.predicateWindowTitle,

			})
			formdata.append('file', file);
			formdata.append('info', info);


			let response= await fetch(url,{
				method: 'post',
				body: formdata,
			})
			let result = await response.json()
			

			// console.log(result)


			if(result.statusCode == 11){
				message.config({top: 3,duration: 2,});message.success('image uploaded!');
				
			}

			//pin1

			
		}
		catch(error){
		
			message.config({top: 3,duration: 2,});message.error('image too large');
		}
		// 重置input元素的value属性
		e.target.value = '';
		this.setState({ifUploading:false})
		
	}

	tellDReport = async()=> {
		var item_selected = this.state.item_selected
		console.log(item_selected)
		var filename = item_selected.name.split(".")[0]
		if(item_selected){
			// console.log(filename)
			// console.log(this.props.predicateWindowTitle)
			try{	
				this.setState({ifUploading:true})
				var url = env_serverAddress+"neuroai/telldreport"
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						password:this.props.password,
						filename:filename,
						type:this.props.predicateWindowTitle,
						// url_image:url_image,
						// model:model
						sourceFile:item_selected.sourceFile
						
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let result = await response.json()
				this.setState({ifUploading:false})
				// console.log(result)
				
				if(result == 0){
					message.error("Error!!")
					// this.setState({ifUploading_useStableDiffusion:false})
				}else{
					this.setState({report:result.returnData})
					// console.log(result)	
					// this.setState({currentImg_after:result.returnData})
					// this.setState({ifUploading_useStableDiffusion:false})
				}
			}
			catch(error){	
			}
		}
	}

	requireMiniGPT = async()=> {
		var item_selected = this.state.item_selected
		// console.log(item_selected)

		message.warning("DEV - While cold booting, It may takes 3~5min to call miniGPT-4 model through API useing...")

		var filename = item_selected.name.split(".")[0]
		var sourceFile = item_selected.sourceFile

		if(item_selected){
			// console.log(filename)
			try{	
				this.setState({ifUploading_miniGPT:true})
				var url = env_serverAddress+"neuroai/requireminigpt"
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						password:this.props.password,
						sourceFile:sourceFile,
						filename:filename,
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let result = await response.json()
				this.setState({ifUploading_miniGPT:false})
				// console.log(result)
				
				if(result == 0){
					message.error("Error!!")
					// this.setState({ifUploading_useStableDiffusion:false})
				}else{
					// this.setState({report:result.returnData})
					// console.log(result)	
					// this.setState({currentImg_after:result.returnData})
					// this.setState({ifUploading_useStableDiffusion:false})
				}
			}
			catch(error){	
			}
		}
	}




	componentDidMount(){
		
		setTimeout(()=>{this.scrollToTop()},1)

		// item_selected_list_check 如果不为0的话,默认加载第一项
		if(this.props.item_selected_list_check.length != 0){
			// console.log("haha---")
			var firstItem = this.props.item_selected_list_check[0]
			// this.fetchCSV(firstItem)

			// this.setState({item_selected:firstItem})
			// this.fetchCSV(firstItem)
		}

		
	}

	
	
	render() {

		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
	
		
		var width_leftBar = 350
		var width_middle = width - width_leftBar 


		var dict_csv = this.state.current_csv

		// console.log(dict_csv)

		var list_csv = []
		if(dict_csv){
			// 使用 for...in 循环遍历字典
			for (var key in dict_csv) {
				if (dict_csv.hasOwnProperty(key)) {

					var value = dict_csv[key];
					list_csv.push({
						name:key,
						uv:value
					})
				}
			}	

		}

		var report_miniGPT = this.state.report_miniGPT
		

		// pinReturn
		return(
		
			<div style={{width:width,height:height,position:"fixed",left:0,top:0}}
				
				>
				<div 
					style={{
					width:width_leftBar,			
					backgroundColor:"#f1f1f1",
					height:height,
					float:"left",
					//padding:20,
					}}>
					<div style={{height:0}}/>

					<div style={{paddingLeft:0,fontSize:"20px",fontWeight:"bold",height:50,backgroundColor:"#ffffff"}}>
						<img src = {logo_dplanner} style={{width:210,marginLeft:-0,paddingTop:5}}/>
					</div>

					<div style={{height:20}}/>

					{/* <div style={{fontSize:"17px",fontWeight:"normal",marginLeft:15,marginTop:5}}>
						オリジナル画像
					</div>
					<div style={{height:18}}/> */}

					<div 
						style={{
						marginLeft:10,
						overflowY:"scroll",
						height:height-100,
						width:360,
						// backgroundColor:"#ff00f0"
						}}>



						{this.props.item_selected_list_check.map(item =>{

							var index_here = item.index
							var index_selected = this.state.item_selected.index
							return(	
								<div style={{height:260}}>



								
									<div 
										style={{float:"left",
										marginBottom:10,
										borderRadius:15,
										overflow:"hidden",
										width:330,
										height:200,
										cursor:"pointer",
										backgroundColor: index_here == index_selected ? ("#1677FF"):("#d8d8d8") //"#1677FF" //1677FF
										}}
										onClick = {()=>{
											this.setState({item_selected:item})
											this.fetchCSV(item)
											
											// this.scrollToTop()
											
											
											// console.log(item.index)
										
											}}
										>
										<div 
											style={{float:"left",
											
											borderRadius:10,
											overflow:"hidden",
											width:320,
											height:190,
											marginLeft:5,
											marginTop:5,
											
											// backgroundColor:"#ff00f0"
											}}>
											<ImageComponent 
												src={item.sourceFile} width={320} height={190} 
												ifShowPreview = {index_here == index_selected ?(true):(false)}
												/>
										</div>
										
									</div>


									<div 
										style={{float:"left",
										marginBottom:10,
										marginLeft:10,
										width:330,
										height:40,
										
										
										}}
										onClick = {()=>{
											this.setState({item_selected:item})
											// console.log(item.index)
										
											}}
										>
										<div style={{fontWeight:"bold",fontSize:15}}>{item.index}<br/></div>
										
										{item.name}<br/>
									</div>


								</div>
								
							)
						})}
						

						


						<div style={{height:50}}/>
					</div>

					


					<div style={{height:5}}/>

				</div>




				<div 
					style={{
					width:width_middle,			
					backgroundColor:"#ffffff",
					height:height,
					float:"left",
					//padding:20,
					}}>
					<div style={{paddingLeft:0,fontSize:"20px",fontWeight:"bold",height:50,backgroundColor:"#ffffff"}}>
						{/* <img src={logo_uol} style={{width:180,paddingLeft:30,paddingTop:12}}/> */}
						<Space style={{marginLeft:12,marginTop:10}}>
							<Button type="text" icon={<MenuOutlined />}/>
							<div style={{marginLeft:0,fontSize:"20px",fontWeight:"bold"}}>
								{/* {this.state.pageTitle} */}
								{this.props.predicateWindowTitle}
							</div>
						</Space>
					</div>


					<div style={{width:this.props.width}}><Divider style={{marginTop:0,marginBottom:0}}/></div>





					{/* content here: */}
					{/* pin1 */}
					{/* ---------------------------------------------------- */}


					{/* <div
						ref={this.consoleEndRef}
						className="console-end"
						style={{ display: "block" }}
						>
					</div> */}
					
					{/* pinResult */}
					<div style={{
						// float:"left",
						width:width-350,
						height:height-50,
						// backgroundColor:"#ff0000",
						overflowY:"scroll",
						}}>

						<div ref={this.topRef}></div>

						<div 
							
							style={{
							width:width-410,
							height:200,
							marginLeft:30,
							marginTop:20,
							marginRight:30,
							// backgroundColor:"#ff00ff"
							}}>
							

							<div style={{
								float:"left",
								width:200,
								height:160,
								backgroundColor:"#f1f1f1",
								borderRadius:15,
								padding:10,
								overflow:"hidden",
								// cursor:"progress"
								}}>
								<div 
									
									style={{marginLeft:5,fontSize:"15px",fontWeight:"bold",overflow:"hidden",height:20,}}>
									カテゴリ
								</div>
								<div style={{height:10}}/>
								

								
								<div style={{marginLeft:5}}>

									{/* <div style={{fontSize:"13px",fontWeight:"bold"}}>
										カテゴリから選ぶ
									</div> */}

									<div style={{height:20}}/>
									<div style={{overflow:"hidden",height:20,width:150}}>
										<Dropdown
											menu={{
											items,
											}}
											>
											<a onClick={(e) => e.preventDefault()}>
											<Space>
												01_電子・精密類
												<DownOutlined />
											</Space>
											</a>
										</Dropdown>


									</div>

									<div style={{height:10}}><Divider /></div>
									

									<div style={{height:10}}/>
									<div style={{overflow:"hidden",height:30,width:150}}>
										<Dropdown
											menu={{
												items,
											}}
											>
											<a onClick={(e) => e.preventDefault()}>
											<Space>
												未選択
												<DownOutlined />
											</Space>
											</a>
										</Dropdown>
									</div>
								</div>
								


								


							</div>

							<div style={{
								float:"left",
								width:width-910,
								height:160,
								backgroundColor:"#f1f1f1",
								borderRadius:15,
								marginLeft:20,
								padding:10,
								overflow:"hidden",
								}}>
								<div style={{overflow:"hidden",height:20,marginLeft:5,fontSize:"15px",fontWeight:"bold"}}>
									キーワード
								</div>
								<div style={{height:10}}/>

								<Search
									placeholder="例：美味しい"
									
									allowClear
									enterButton="Search"
									size="middle"
									// style={{width:(width-350)/2}}
									/>


								{/* <Compo_Tag 
									callBack_reportTags = {this.callBack_reportTags}
									tags = {this.state.currentPrompt}
									startEditPrompt = {this.startEditPrompt}
									endEditPrompt = {this.endEditPrompt}
									/> */}


								<div style={{height:15}}/>

								<div style={{overflowX:"scroll",height:40}}>
									{/* <Space size={[0, 8]} >
										<Tag color="magenta" closeIcon={<CloseCircleOutlined />} >magenta</Tag>
										<Tag color="red">red</Tag>
										<Tag color="volcano">volcano</Tag>
										<Tag color="orange">orange</Tag>
										<Tag color="gold">gold</Tag>
										
										
										
									</Space> */}
									<div style={{height:30,width:600}}>
										<Compo_Tag/>
									</div>
									
								</div>

								<div style={{height:10,marginTop:-25}}><Divider /></div>
								<div style={{height:5}}/>

								<div style={{overflowX:"scroll",height:40}}>
									<Space size={[0, 8]} >
										
										<Tag color="green">green</Tag>
										<Tag color="cyan">cyan</Tag>
										<Tag >blue</Tag>
										<Tag color="geekblue">geekblue</Tag>
										<Tag color="purple">purple</Tag>
										<Tag color="magenta" closeIcon={<CloseCircleOutlined />} >magenta</Tag>
										<Tag color="red">red</Tag>
										<Tag color="volcano">volcano</Tag>
										<Tag color="orange">orange</Tag>
										<Tag color="gold">gold</Tag>
										<Tag color="lime">lime</Tag>
										<Tag color="green">green</Tag>
										<Tag color="cyan">cyan</Tag>
										<Tag color="blue">blue</Tag>
										<Tag color="geekblue">geekblue</Tag>
										<Tag color="purple">purple</Tag>
									</Space>
								</div>
								
								

							</div>
							
							<div style={{
								float:"left",
								width:200,
								height:160,
								backgroundColor:"#f1f1f1",
								borderRadius:15,
								marginLeft:20,
								padding:10
								}}>
								<div style={{overflow:"hidden",width:100,marginLeft:5,fontSize:"15px",fontWeight:"bold"}}>
									表示設定
								</div>
								<div style={{height:10}}/>

								<Space>

									<div style={{
										marginLeft:5,
										fontSize:"15px",
										fontWeight:"normal",
										marginRight:40,
										width:100,
										overflow:"hidden",
										height:20
										}}>
										基準値表示
									</div>
									
									<Switch 
									defaultChecked 
									// onChange={onChange} 
									/>
								</Space>
								

								<div style={{height:10}}/>
								<div style={{marginLeft:5}}>
									<div style={{height:10,marginTop:-15,marginBottom:0}}><Divider /></div>
									<Dropdown
										menu={{
											items,
										}}
										>
										<a onClick={(e) => e.preventDefault()}>
										<Space>
											表示方式：グラフ
											<DownOutlined />
										</Space>
										</a>
									</Dropdown>

									<div style={{height:5}}/>
									<Dropdown
										menu={{
											items,
										}}
										>
										<a onClick={(e) => e.preventDefault()}>
										<Space>
											セグメント：M2層
											<DownOutlined />
										</Space>
										</a>
									</Dropdown>

									<div style={{height:5}}/>
									<Dropdown
										menu={{
											items,
										}}
										>
										<a onClick={(e) => e.preventDefault()}>
										<Space>
											秒数：30秒
											<DownOutlined />
										</Space>
										</a>
									</Dropdown>

								</div>
							</div>
						</div>










									
						<div style={{
							// float:"left",
							width:width-430,
							height:height-330,
							backgroundColor:"#f1f1f1",
							borderRadius:15,
							padding:10,
							marginLeft:30,
							position:"relative",
							// overflowY:"scroll",
							}}>
								
							{/* {list_csv.length == 0 ?("0"):("1")} */}
							{this.state.item_selected?(


								this.state.ifFetchingCSV?(
									<div style={{
										overflow:"hidden",
										width : this.props.width-90,
										height :(this.props.width-150)*0.618,
										backgroundColor:"#f1f1f1",
										borderRadius:15,
										fontSize:30,
										color:"#d8d8d8",
										fontWeight:"bold",
										textAlign:"center",
										display: "flex",
										alignItems: "center", /* 垂直居中 */
										justifyContent: "center", /* 垂直居中 */
										// cursor:"pointer",
										}}>
										<div>
											<div style={{height:(height-320)/2-100}}/>
											<LoadingOutlined style={{fontSize:120}}/><br/>
											<div style={{height:10}}/>
											Downloading data<br/>
											<div style={{fontSize:20,fontWeight:"normal"}}>please wait</div>
											
											<br/>
										</div>
									</div>
								):(
									this.state.current_csv!=[] ?(
										<BarChart
											width={width-420}
											height={height-320}
											data={list_csv}
											stackOffset="sign"
											margin={{
												top: 20,
												right: 30,
												left: 0,
												bottom: 5,
											}}
											>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<ReferenceLine y={0} stroke="#000" />
											<Bar dataKey="pv" fill="#9BAFD8" stackId="stack" />
											<Bar dataKey="uv" fill="#3875F6" stackId="stack" />
										</BarChart>
									):(
										<div style={{
											overflow:"hidden",
											width : this.props.width-90,
											height :(this.props.width-150)*0.618,
											backgroundColor:"#f1f1f1",
											borderRadius:15,
											fontSize:30,
											color:"#d8d8d8",
											fontWeight:"bold",
											textAlign:"center",
											display: "flex",
											alignItems: "center", /* 垂直居中 */
											justifyContent: "center", /* 垂直居中 */
											// cursor:"pointer",
											}}>
											<div>
												<div style={{height:(height-320)/2-100}}/>
												<CloseCircleOutlined style={{fontSize:120}}/><br/>
												<div style={{height:10}}/>
												Content has no data<br/>
												<div style={{fontSize:20,fontWeight:"normal"}}>Please upload .CSV first</div>
												
												<br/>
											</div>
										</div>
									)

								)


								
								
							):(
								<div style={{
									overflow:"hidden",
									width : this.props.width-90,
									height :(this.props.width-150)*0.618,
									backgroundColor:"#f1f1f1",
									borderRadius:15,
									fontSize:30,
									color:"#d8d8d8",
									fontWeight:"bold",
									textAlign:"center",
									display: "flex",
									alignItems: "center", /* 垂直居中 */
									justifyContent: "center", /* 垂直居中 */
									// cursor:"pointer",
									}}>
									<div>
										<div style={{height:(height-320)/2-100}}/>
										<InfoCircleOutlined style={{fontSize:120}}/><br/>
										<div style={{height:10}}/>
										Please select a content<br/>
										<div style={{fontSize:20,fontWeight:"normal"}}>by clicking the left list</div>
										
										<br/>
									</div>
								</div>
							)}
							


							<Button 
								type="primary" 
								shape="circle" 
								icon = {<EditOutlined />} 
								disabled={!this.state.item_selected}
								style={{position:"absolute",right:10,top:10}}
								onClick = {()=>{
									this.myRef_input_uploadCSV.current.click()
								}}
								/>

							<Button 
								type="primary" 
								shape="circle" 
								icon = {<CopyOutlined />} 
								disabled={!this.state.item_selected}
								style={{position:"absolute",right:50,top:10}}
								onClick = {()=>{
									// this.copyCSV()
									console.log(dict_csv)
									// alert(list_csv)
									var jsonString = JSON.stringify(dict_csv);
									navigator.clipboard.writeText(jsonString);
									message.success("copied to clipboard✋")
								}}
								/>


						</div>
						
						
						<div style={{height:0}}/>


						<div style={{marginLeft:50,marginRight:40,position:'relative'}}>



							<div 
								style={{
								position:"absolute",right:10,top:150,
								height:30,
								// width:300,
								borderRadius:15,
								// backgroundColor:"#f1f1f1",
								padding:0,
								
								}}>

								<Space>
									
									

									<Tooltip_antd title={"$dev mode: \n\n"+this.state.report_miniGPT}>
										<Button 
											onClick = {this.requireMiniGPT}
											type="text" 
											disabled={this.state.ifUploading_miniGPT}
											icon={report_miniGPT ?(<CheckCircleOutlined style={{color:"#6194FF"}}/>):(<CloseCircleOutlined  style={{color:"#ff0000"}}/>)}
											>
											
											{!report_miniGPT ?("$Image Input"):("$Image Input")} 
										</Button>
									</Tooltip_antd>
									
									<Button type="link" icon={<DownloadOutlined />}>PDF</Button>
									<Button 
										type="primary"
										ref={this.generateButtonRef}
										onClick = {this.tellDReport}
										disabled = {!this.state.item_selected || this.state.ifUploading || list_csv.length==0}
										icon = {this.state.ifUploading ?(<LoadingOutlined/>):(<AppstoreOutlined />)}
										>
										{!this.state.item_selected ?("Select One Content First"):("Generate Report")}
									</Button>
									
								</Space>
								
								
							</div>



							
							<Typography>

								<img src = {logo_dplanner_small} style={{width:100,marginLeft:-10,marginTop:40,marginBottom:-30}}/>
								<Title ref={this.reportRef}>D-Report</Title>
								
								
							</Typography>
							

							{/* <div style={{height:20}}/> */}
							
							
							<div style={{height:10,marginTop:-10,width:width-450}}><Divider /></div>
							<div style={{height:50}}/>
							{/* No current Report */}

							{this.state.report?(
								// <Typography>
								// 	<Paragraph style={{fontSize:20,padding:0}}>
								// 		{/* Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. D */}
								// 		{this.state.report.content}
								// 	</Paragraph>
								// </Typography>

								<div style={{
									fontSize:20,

									}}>
									<ReactMarkdown>
										{this.state.report.analyzer}
									</ReactMarkdown>

									<ReactMarkdown>
										{/* {this.state.report.analyzer} */}
										{this.state.report.content}
									</ReactMarkdown>

								</div>
							):(
								<Typography>
									<Paragraph 
										onClick = {()=>{this.setState({ifShowTour_generateButton:true})}}
										style={{fontSize:20,padding:0}}>
										Click to generate your first D-Report
									</Paragraph>
								</Typography>
							)}
								
							<div style={{height:10}}/>
							
							{this.state.report?(
								<div>
									{/* <Button 
										onClick = {()=>{this.setState({ifShowModal_talk2AI:true})}}
										size="large" type="link" 
										style={{marginLeft:-15}}>
										さらにAIと話そう
									</Button>	 */}

									<div style={{height:30}}/>
									
									<div style={{
										backgroundColor:"#f1f1f1",
										padding:20,
										borderRadius:15,
										marginLeft:-20,
										width:width - 450
										
										}}>
										<Page_talk2AI
											report = {this.state.report}
											password = {this.props.password}
											width = {width}
											type = {this.props.predicateWindowTitle}
											item = {this.state.current_item}

											// refreshKey = {this.state.refreshKey_talk2AI}
											/>
									</div>
									<div style={{marginTop:5,color:"#d8d8d8"}}>powered by openAI</div>
								</div>
							):(
								""
							)}
							

							<div style={{height:20}}/>
							<div style={{height:10,marginTop:-10,width:width-450}}><Divider /></div>

						</div>

						
						{/* <div style={{height:100}}/> */}



					</div>
					
					





						
					{/* ---------------------------------------------------- */}
				</div>




				

				
			<p>
				{/* pin1 */}
				<input 
					id = "uploadimg" type="file" accept=".csv"
					onChange={this.uploadCSV}
					ref={this.myRef_input_uploadCSV}
					style={{display:"none",}}
					/>
					
			</p>
			{/* <FloatButton 
				icon={<SiOnlyoffice/>} type="primary" 
				onClick={this.scrollToReport} 
				/> */}
			
			
			{/* <Tour
				open={this.state.ifShowTour_generateButton}
				onClose={() => this.setState({ifShowTour_generateButton:false})}
				steps={steps}
				mask = {true}
				type="primary"
				placement="left"
				// style={{width:100}}
				indicatorsRender={(current, total) => (
				<span>
					{current + 1} / {total}
				</span>
				)}
			/> */}

			<Modal
				title="D-Assistant"
				centered
				open={this.state.ifShowModal_talk2AI}
				// onOk={this.open_ifShowFileExplorer}
				onCancel={()=>{
					this.setState({
						ifShowModal_talk2AI:false,
						refreshKey_talk2AI: this.state.refreshKey_talk2AI + 1
					})}}
				width={1065}
				// height={height*0.8}
				// okText = {"done"}
				footer = {null}
				>
					
				<Page_talk2AI
					report = {this.state.report}
					password = {this.props.password}
					// refreshKey = {this.state.refreshKey_talk2AI}
					/>

			</Modal>



			<Compo_footer
				ifHasGPU={this.state.ifGpuIsWorking}

				status_API_openai = {this.props.status_API_openai}
				/>


			
		</div>






			
			
			
			
		)

  	}
}

export default withRouter(Page_predicateWindow);