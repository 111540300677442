

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import { Divider,Typography,Input,Modal} from 'antd';
import { 
	Space, Tour,Upload ,
	Popover,Menu,Collapse, 
	Dropdown,Tag,Switch,FloatButton
} from 'antd';
import { Button} from 'antd';
import { message,Drawer,Popconfirm,Image} from 'antd';
import { MailOutlined, SettingOutlined,MenuOutlined, UpCircleFilled } from '@ant-design/icons';
import {env_serverAddress} from './lib_env'

//-------------------------------
import logo_dplanner from "./static/dplanner.png"
import { 
	CaretRightOutlined,
	LoadingOutlined,
	AppstoreOutlined,
	SmileOutlined,
	RollbackOutlined,
	CloseCircleOutlined,
	DownloadOutlined,
	MinusCircleFilled,
	EditOutlined,
	ReloadOutlined,
	InfoCircleOutlined,
	BarsOutlined,
	RedoOutlined,
	FileAddFilled
	

} from '@ant-design/icons';
import ImageComponent from './Compo_ImageComponent'
import Page_AILab_talk2AI from "./Page_AILab_talk2AI";
  

// pinImport
//---------------------------------------

const preventDefault = (e) => {
	e.preventDefault();
	// console.log('Clicked! But prevent default.');
  };








class Page_AILab extends Component {


	constructor(props){
        super(props);
        this.myRef_input_uploadCSV=React.createRef();
		this.topRef = React.createRef();
		this.reportRef = React.createRef();

		this.generateButtonRef = React.createRef();

	}

	
	//pinState
	state={

		img_history :[],


		
		item_selected :false,
		report :false,
		report_miniGPT:false,
		ifUploading :false,
		ifUploading_miniGPT:false,
		ifFetchingCSV:false,
		current_csv:false,
		current_item:false,

		ifShowTour_generateButton:false,
		ifShowModal_talk2AI:false,
		refreshKey_talk2AI:1,    //用于刷新组件
		ifShowImgDetail:false,
		ifShowEditMotodata:false,
	
	}


	componentDidMount(){
	}

	
	
	render() {

	
		var img_history = this.props.img_history
		// "LOADING" : ~
		// "NONE" : ~
		// list : has item
		var item_selected = this.props.item_selected

		



		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var width_leftBar = 350
		var width_middle = width - width_leftBar 

		console.log("-----------------------------------")
		console.log(this.props.img_history_ifGenerating)

		var ifShowImgDetail = this.state.ifShowImgDetail
		var scaleStep = 0.5

		console.log(ifShowImgDetail)

		// pinReturn
		return(

			
		
			<div style={{width:width,height:height,position:"fixed",left:0,top:0}}
				
				>
				<div 
					style={{
					width:width_leftBar,			
					backgroundColor:"#f1f1f1",
					height:height,
					float:"left",
					//padding:20,
					}}>
					<div style={{height:0}}/>

					<div style={{paddingLeft:0,fontSize:"20px",fontWeight:"bold",height:50,backgroundColor:"#ffffff"}}>
						<img src = {logo_dplanner} style={{width:210,marginLeft:-0,paddingTop:5}}/>
					</div>

					{/* <div style={{height:10}}/> */}


					{/* <div style={{fontSize:"17px",fontWeight:"normal",marginLeft:15,marginTop:5}}>
						<FileOutlined />&nbsp;編集履歴
					</div> */}
					<div style={{height:260}}>
						<div 
							style={{float:"left",
							marginBottom:10,
							marginLeft:20,
							width:330,
							height:40,
							
							
							}}
							>
							<div style={{height:10}}/>
							<Space>
								<div style={{fontSize:"17px",fontWeight:"normal"}}>
									<AppstoreOutlined />&nbsp;元データ
								</div>
								<Popconfirm
									title="生成歴史をクリアしますか？"
									description="Are you sure to delete this task?"
									onConfirm={()=>{this.props.AILab_clearImgHistory()}}
									// onCancel={cancel}
									okText="Yes"
									cancelText="No"
									>
									<Button size="large" type="text"
										icon = {this.props.AILab_ifClearingImgHistory?(<LoadingOutlined/>):(<RedoOutlined/>)   }
										// icon = {<SettingOutlined />}
										style={{
											// marginLeft:-1,
											// marginTop:-10
											// fontSize:12

										}}
										disabled={this.props.AILab_ifClearingImgHistory}
										danger
										/>
								</Popconfirm>
							</Space>
							
						</div>
						<div 
							style={{
							float:"left",
							marginBottom:10,
							marginLeft:15,
							borderRadius:15,
							overflow:"hidden",
							width:320,
							height:195,
							position:"relative"
							}}
							onMouseEnter={()=>{
								this.setState({ifShowEditMotodata:true})
							}}
							onMouseLeave={()=>{
								this.setState({ifShowEditMotodata:false})
							}}
							>
							<div 
								style={{float:"left",
								
								borderRadius:10,
								overflow:"hidden",
								width:320,
								height:195,
								marginLeft:0,
								marginTop:5
							}}
								
								>

								<ImageComponent src={item_selected.sourceFile} width={320} height={190}/>
								
							</div>

							{this.state.ifShowEditMotodata?(
								<div style={{
									height:40,
									width:"100%",
									// backgroundColor:"#f1f1f1",
									position:"absolute",left:0,bottom:0,

									backgroundColor: 'rgba(0, 0, 0, 0.2)',
									WebkitBackdropFilter: 'blur(10px)',
									backdropFilter: 'blur(10px)',
									// marginLeft:10,
									
									}}>
									{/* pin1 */}
									<Space style={{marginLeft:95,marginTop:5}}>
								
										<Button  type="text" shape="round"
											style={{
												color:"#ffffff",
												// fontWeight:"bold"
											}}
											
											disabled = {this.props.AILab_ifClearingImgHistory_byIndex ?(true):(false)}
											icon = {this.props.AILab_ifClearingImgHistory_byIndex ?(<LoadingOutlined />):(<FileAddFilled />)}
											// onClick = {()=>{
											// 	console.log(item.sourceFile)
											// 	this.props.handleUploadImage_contentList_byUrl(item.sourceFile)
											// }}
											>
											範囲を描く
											
										</Button>
										{/* <Button  type="text" shape="round" 
											style={{
												color:"#ff0000",
												fontWeight:"bold"
											}}
											// onClick = {()=>{
											// 	console.log(item.index)
											// 	this.props.AILab_clearImgHistory_byIndex(item.index)
											// }}
											disabled = {this.props.AILab_ifClearingImgHistory_byIndex ?(true):(false)}
											icon = {this.props.AILab_ifClearingImgHistory_byIndex ?(<LoadingOutlined />):(<MinusCircleFilled />)}
											>
											削除する
										</Button> */}
									</Space>

								</div>
							):("")}
							
						</div>


						{/* <div 
							style={{float:"left",
							marginBottom:10,
							marginLeft:10,
							width:330,
							height:40,
							
							
							}}
							>
							<Space>
								<div style={{fontWeight:"bold",fontSize:15}}>{item_selected.index}<br/></div>
								
								{item_selected.name}<br/>
								<Button danger>ここに戻る</Button>

							</Space>
							
						</div> */}


					</div>

					<div style={{height:18}}/>

					<div 
						style={{
						marginLeft:10,
						overflowY:"scroll",
						height:height-330,
						width:340,
						// backgroundColor:"#ff00f0"
						}}>

						{img_history=="LOADING"?(
							<div style={{
								height:height-360,
								color:"#d8d8d8",
								textAlign:"center",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop:-20
								}}>
									<div>
										<div style={{fontSize:80,}}>
											<LoadingOutlined/>
										</div>
										<div style={{fontSize:30,fontWeight:"bold",}}>
											ローディング
										</div>
										
	
									</div>
	
	
							</div>
						):(
							img_history=="NONE" ? (
								<div style={{
									height:height-360,
									color:"#d8d8d8",
									textAlign:"center",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop:-20
									}}>
										<div>
											<div style={{fontSize:80,}}>
												<CloseCircleOutlined/>
											</div>
											<div style={{fontSize:30,fontWeight:"bold",}}>
												編集歴ありません
											</div>
											
		
										</div>
		
		
								</div>
							):(

								<div>
									{this.props.img_history_ifGenerating ?(
										<div style={{}}>

											<div 
												style={{float:"left",
												marginBottom:10,
												borderRadius:15,
												overflow:"hidden",
												width:330,
												height:200,
												
												backgroundColor: "#d8d8d8" //"#1677FF" //1677FF
												}}
												>
												<div 
													style={{float:"left",
													
													borderRadius:10,
													overflow:"hidden",
													width:320,
													height:190,
													marginLeft:5,
													marginTop:5,
													// opacity:1-(index_here-1)*0.2,
													
													// backgroundColor:"#ff00f0"
													}}>
														

													<div style={{
														height:190,
														color:"#f1f1f1",
														textAlign:"center",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														// marginTop:-20
														}}>
															<div>
																<div style={{fontSize:80,}}>
																	<LoadingOutlined/>
																</div>
																<div style={{fontSize:30,fontWeight:"bold",}}>
																	生成中
																</div>
															</div>
							
							
													</div>


													
												</div>
												
											</div>

											
											


										</div>
									):("")}
									{
										img_history.map(item =>{

											var index_here = item.index
											var index_selected = this.state.item_selected.index
											return(	
												<div 
													onMouseEnter = {()=>{
														this.setState({item_selected:item})
														// if(this.state.item_selected == item){
														// 	this.setState({item_selected:false})
														// }
														// else{
														// 	this.setState({item_selected:item})
														// }	
													}}
													onMouseLeave = {()=>{
														
														this.setState({item_selected:false})	
													}}
													>

													<div 
														style={{
														float:"left",
														marginBottom:10,
														borderRadius:15,
														overflow:"hidden",
														position:"relative",
														width:330,
														height:200,
														
														backgroundColor: index_here == index_selected ? ("#1677FF"):("#d8d8d8") //"#1677FF" //1677FF
														}}
														>
														

														<div 
															style={{
															float:"left",
															position:"relative",
															borderRadius:10,
															overflow:"hidden",
															width:320,
															height:190,
															marginLeft:5,
															marginTop:5}}
															
															
															>
															
															<ImageComponent src={item.sourceFile} width={320} height={190}/>

															{this.state.item_selected == item?(
																<div style={{
																	height:40,
																	width:"100%",
																	// backgroundColor:"#f1f1f1",
																	position:"absolute",left:0,bottom:0,

																	backgroundColor: 'rgba(0, 0, 0, 0.2)',
																	WebkitBackdropFilter: 'blur(10px)',
           			 												backdropFilter: 'blur(10px)',
																	// marginLeft:10,
																	
																	}}>
																	{/* pin1 */}
																	<Space style={{marginLeft:25,marginTop:5}}>
																
																		<Button  type="text" shape="round"
																			style={{
																				color:"#ffffff",
																				// fontWeight:"bold"
																			}}
																			
																			disabled = {this.props.AILab_ifClearingImgHistory_byIndex ?(true):(false)}
																			icon = {this.props.AILab_ifClearingImgHistory_byIndex ?(<LoadingOutlined />):(<FileAddFilled />)}
																			onClick = {()=>{
																				console.log(item.sourceFile)
																				this.props.handleUploadImage_contentList_byUrl(item.sourceFile)
																			}}
																			>
																			一覧画面に導入
																			
																		</Button>





																		<Button  type="text" shape="round" 
																			style={{
																				color:"#ff0000",
																				fontWeight:"bold"
																			}}
																			onClick = {()=>{
																				console.log(item.index)
																				this.props.AILab_clearImgHistory_byIndex(item.index)
																			}}
																			disabled = {this.props.AILab_ifClearingImgHistory_byIndex ?(true):(false)}
																			icon = {this.props.AILab_ifClearingImgHistory_byIndex ?(<LoadingOutlined />):(<MinusCircleFilled />)}
																			>
																			削除する
																		</Button>
																	</Space>
	
																</div>
															):("")}
															









															{/* <div style={{
																position:"absolute",
																right:10,bottom:10,
																height:30,width:30,
																marginTop:-3,
																// backgroundColor:"#ffffff",
																opacity:0.5,
																fontSize:30,
																color:"#ffffff"

																}}>
																<ZoomInOutlined />
															</div> */}
														</div>
														
													</div>

													
													


												</div>
												
											)
										})
									}
									<div style={{height:50,width:260,float:"left"}}/>


									



									

								</div>
								
							)
							
						)}

						
						
						





					</div>

					


					<div style={{height:5}}/>

				</div>




				<div 
					style={{
					width:width_middle-40,			
					// backgroundColor:"#ffffff",
					height:height,
					float:"left",
					//padding:20,
					}}>
					<div style={{paddingLeft:0,fontSize:"20px",fontWeight:"bold",height:50,backgroundColor:"#ffffff"}}>
						{/* <img src={logo_uol} style={{width:180,paddingLeft:30,paddingTop:12}}/> */}
						{/* <Space style={{marginLeft:12,marginTop:10}}>
							<Button type="text" icon={<MenuOutlined />}/>
							<div style={{marginLeft:0,fontSize:"20px",fontWeight:"bold"}}>
								AI Lab
							</div>
						</Space> */}
					</div>


					<div style={{width:this.props.width}}><Divider style={{marginTop:0,marginBottom:0}}/></div>


					<div style={{width:width_middle+20,overflow:"hidden"}}>
						<Page_AILab_talk2AI
							chatGPT_history = {this.props.chatGPT_history}
							password={this.state.password}
							width={width_middle}

							img_history = "NONE"
							AILab_talk2AI = {this.props.AILab_talk2AI}
							ifUploading = {this.props.ifUploading}
							item_selected = {this.props.item_selected}

							AILab_ifClearingChatHistory = {this.props.AILab_ifClearingChatHistory}
							AILab_clearChatHistory = {this.props.AILab_clearChatHistory}

								
							/>

					</div>


					{/* content here: */}
					{/* pin1 */}
					{/* ---------------------------------------------------- */}
					

					
					
					





						
					{/* ---------------------------------------------------- */}
				</div>




				

				
			{/* <p>
			
				<input 
					id = "uploadimg" type="file" accept=".csv"
					onChange={this.uploadCSV}
					ref={this.myRef_input_uploadCSV}
					style={{display:"none",}}
					/>
					
			</p>

			 */}
			{/* <FloatButton 
				icon={<SiOnlyoffice/>} type="primary" 
				onClick={this.scrollToReport} 
				/> */}
			
			
			{/* <Tour
				open={this.state.ifShowTour_generateButton}
				onClose={() => this.setState({ifShowTour_generateButton:false})}
				steps={steps}
				mask = {true}
				type="primary"
				placement="left"
				// style={{width:100}}
				indicatorsRender={(current, total) => (
				<span>
					{current + 1} / {total}
				</span>
				)}
			/> */}
{/* 
			<Modal
				title="D-Assistant"
				centered
				open={this.state.ifShowModal_talk2AI}
				// onOk={this.open_ifShowFileExplorer}
				onCancel={()=>{
					this.setState({
						ifShowModal_talk2AI:false,
						refreshKey_talk2AI: this.state.refreshKey_talk2AI + 1
					})}}
				width={1065}
				// height={height*0.8}
				// okText = {"done"}
				footer = {null}
				>
					
				<Page_talk2AI
					report = {this.state.report}
					password = {this.props.password}
					// refreshKey = {this.state.refreshKey_talk2AI}
					/>

			</Modal> */}



			{/* <Compo_footer
				ifHasGPU={this.state.ifGpuIsWorking}

				status_API_openai = {this.props.status_API_openai}
				/> */}


				{/* <Image
					width={200}
					style={{
						position:'fixed',
						left:0,top:0,
						display: 'none',
					}}
					src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
					preview={
						{
							ifShowImgDetail,
							scaleStep,
							src: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
							onVisibleChange: () => {
								this.setState({ifShowImgDetail:false});
							},
						}
					}
				/> */}


				<Modal
                    title="AIGC"
                    centered
                    open={this.state.ifShowImgDetail}
                    // onOk={this.open_ifShowFileExplorer}
                    onCancel={()=>{this.setState({ifShowImgDetail:false})}}
                    width={600}
					// height={height*0.8}
                    okText = {"done"}
                    footer = {null}
                    >

					<Image
						width={260}
						src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
					/>
                    




                </Modal>


			
		</div>






			
			
			
			
		)

  	}
}

export default withRouter(Page_AILab);